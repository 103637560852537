import { Component, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductionOrderMainProvider } from '../../../providers/client/production_order_main.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { YesNoConfirmationModal } from '../yes-no-confirmation/yes-no-confirmation.modal';
import { ProductionOrderMain } from '../../../models/client/ProductionOrderMain';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { EgressPaymentProofMainProvider } from '../../../providers/client/egress_payment_proof_main.provider';
import { ReplaySubject } from 'rxjs';
import { AccountAssigmentDetail } from '../../../models/client/AccountAssigmentDetail';
import { AccountAssigmentDetailProvider } from '../../../providers/client/account_assigment_detail.provider';
import { EgressPaymentProofMain } from '../../../models/client/EgressPaymentProofMain';



@Component({
  selector: 'app-egress-payment-proof-list-modal',
  templateUrl: './egress-payment-proof-list.modal.html',
  styleUrls: ['./egress-payment-proof-list.modal.scss'],
})

export class EgressPaymentProofListModal {
  public searchFilterText: string = '';
  public searchText: string = '';
  public filter = {
    document_number: '',
    date_production_order: '',
    name_client: '',
    status_intern: '',
    status_production: '',
    status_purchase: ''
  }
  filters: string;
  production_orders_view_paginator: ViewPaginator;
  productionOrders_view: ProductionOrderMain[];
  docFilter = new FormControl('');
  dateFilter = new FormControl('');
  totalFilter= new FormControl('');
  statusFilter= new FormControl('');
  identificationFilter = new FormControl('');
  nameFilter = new FormControl('');
  egress_payments_view_paginator: ViewPaginator;
  egress_payments: EgressPaymentProofMain[] = [];
  dataSource_egress_payments: any ;
  public header_columns: string[] = ['document_number','egress_payment_proof_date', 'identification_client','name_client', 'identification_cash','name_cash', 'total','id_code_accountant_bco','select'];
  _onDestroy: any;
  asignament_account_details: any;
  bank_id: string;

  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<EgressPaymentProofListModal>,
    private productionOrderMainService: ProductionOrderMainProvider,
    private egressPaymentProofMainService: EgressPaymentProofMainProvider,
    private assignamentAccountDetailService: AccountAssigmentDetailProvider,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getAssignamentAccountDetails()
    // this.getProductionOrders();
    this.getEgressPaymentProofMainView();
    if (this.data.items_selected) {
      this.egress_selected = this.data.items_selected
    }
  }

  setFilters() {
    let document = this.docFilter.value?this.docFilter.value: ''

    this.filters = 'filter=true&status=EMITIDO' + '&document_number=' + document + '&name_client=' + this.nameFilter.value
    + '&identification_client=' + this.identificationFilter.value
     + '&egress_payment_proof_date=' +this.dateFilter.value  + '&id_code_accountant_bco=' +this.bank_id   + '&total=' + this.totalFilter.value +
     '&cash_management=true'
  }



  getEgressPaymentProofMainView(){
    this.setFilters()
    this.egressPaymentProofMainService.allEgressPaymentProofView(this.data.enterprise, this.filters).subscribe(
      (data: ViewPaginator)=> {
        this.egress_payments = data.data;
        // this.egress_payments = this.egress_payments.filter(v=>v.clientprovider.client_provider_02financial.length > 0);
        this.dataSource_egress_payments = new MatTableDataSource(this.egress_payments);
        this.egress_payments_view_paginator = data;
      },
      error => {

      }
    )
  }


  getNextPreviousPage(url) {
    this.setFilters()
    this.egressPaymentProofMainService.getNextPreviousPage(url, this.filters).subscribe(
      (data: ViewPaginator)=> {
        this.egress_payments = data.data;
        // this.egress_payments = this.egress_payments.filter(v=>v.clientprovider.client_provider_02financial.length > 0);
        this.dataSource_egress_payments = new MatTableDataSource(this.egress_payments);
        this.egress_payments_view_paginator = data;

      },
      error => {

      }
    )
  }

  public filteredBank : ReplaySubject<AccountAssigmentDetail[]> = new ReplaySubject<AccountAssigmentDetail[]>(1);

  filterMyOptionsBank(e: string) {
    this._onDestroy;
    this.filterAccBanks(e);

  }


 protected filterAccBanks(client: any) {
    if (!this.asignament_account_details) {
      return;
    }
    let search = client;
    if (!search) {
      this.filteredBank.next(this.asignament_account_details.slice());
    }

    this.filteredBank.next(
      this.asignament_account_details.filter(
        bank =>
        bank.alias.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        bank.id_code_accountant.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        bank.reference0.toLowerCase().indexOf(search.toLowerCase()) > -1
      )

    );

  }

  getAssignamentAccountDetails() {
    this.assignamentAccountDetailService.allAccountAssigmentDetails(this.data.enterprise, 'acc_id', '1').subscribe(
      (data: AccountAssigmentDetail[]) => {
        this.asignament_account_details = data;
        this.filteredBank.next(
          this.asignament_account_details.filter(
            bank =>
              bank.alias.toLowerCase().indexOf('') > -1
          )

        );
      }
    )
  }

  public egress_selected:EgressPaymentProofMain[] = []

  selectEgress(check, egress: EgressPaymentProofMain) {
    let index = this.egress_selected.findIndex(v=> v.id == egress.id);
    debugger
    if (check) {
      if (index < 0) {
        this.egress_selected.push(egress);
      }
    } else {
      if (index >= 0 ) {
        this.egress_selected.splice(index, 1);
      }
    }

    console.log(this.egress_selected);

  }

  selectedEgress(egress: EgressPaymentProofMain) {
    let index = this.egress_selected.findIndex(v=> v.id == egress.id);
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  disabledEgress(egress: EgressPaymentProofMain) {
    if (egress.clientprovider_cash && egress.clientprovider.client_provider_02financial.length > 0) {
      return false;
    } else {
      if (egress.clientprovider.client_provider_02financial.length == 0) {
        return true;
      } else {
        return false
      }
    }

  }

  yesNoConfirmation() {
    const dialogRef = this.dialog.open(YesNoConfirmationModal, {
      width: '400px',
      height: '260px',
      data: { title: '¿Esta Seguro de realizar esta operación?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.dialogRef.close(this.egress_selected);
      }
    }
    )
  }

  closeFormatID(index_order, index_item) {
    this.dialogRef.close(this.productionOrders_view[index_order].items[index_item]);

  }

  selectAll(){
    let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
    for (let i = 0; i < checkbox.length; i++) {
      checkbox[i].checked = true

    }
  }

  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 10000
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ElectronicalVoucherInvoice } from '../../models/electronical-voucher/Invoice';
import { URLProvider } from '../url.providers';

@Injectable()
export class ElectronicalVoucherInvoiceProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public getElectronicalsVouchersInvoiceObjects(enterprise: string, invoices_id: any[]) {
    return this.http.post<ElectronicalVoucherInvoice[]>(this.urlProvider.electronical_invoice(enterprise) + '/lot', invoices_id, this.urlProvider.httpOptions);
  }

  public resendEmail(enterprise: string, invoices_id: any[]) {
    return this.http.post<any>(this.urlProvider.electronical_invoice(enterprise) + '/resendemail', invoices_id, this.urlProvider.httpOptions);
  }

  public sriAuth(enterprise: string, invoices_id: string[]) {
    return this.http.post<any>(this.urlProvider.electronical_invoice(enterprise) + '/sriauth', invoices_id, this.urlProvider.httpOptions);
  }

  public createNoSing(enterprise: string, id:string)  {
    //return this.http.post<any>(this.urlProvider.electronical_invoice(enterprise) + '/xmlnosing', invoices_id, this.urlProvider.httpOptions);
    return this.urlProvider.electronical_invoice(enterprise) + '/xmlnosing/'+id;
  }

  public downloadATS(enterprise: string, month: string, gnd: string, year: string, validate: string): string {
    return this.urlProvider.electronical_invoice(enterprise) + '?year=' + year + '&month=' + month + '&gnd=' + gnd + '&validate=' + validate;
  }

  public xmlToJson(enterprise: string, access_key: string) {
    return this.http.get<any>(this.urlProvider.electronical_invoice(enterprise) + '/xmltojason/'+ access_key, this.urlProvider.httpOptions);
  }

}

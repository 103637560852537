import { Action } from '@ngrx/store';

import { UserWebsocket } from '../states/UserWebsocket';

export enum UserWebsocketActionTypes {
  LOAD = '[USERWEBSOCKET] LOAD',
  UPDATE = '[USERWEBSOCKET] UPDATE',
  DELETE = '[USERWEBSOCKET] DELETE'
}

export class Load implements Action {
  readonly type = UserWebsocketActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = UserWebsocketActionTypes.UPDATE;
  constructor (public payload: UserWebsocket) {}
}

export class Delete implements Action {
  readonly type = UserWebsocketActionTypes.DELETE;
}

export type UserWebsocketActionsUnion = Load | Update | Delete;

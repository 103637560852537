import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../states/menu';

// import { Menu } from '../models/client/Menu';

@Pipe({
  name: 'menuFilter'
})

export class MenuListFilter implements PipeTransform {

  transform (list: Menu[], filterText: string): Menu[] {
    if (!filterText) return list;
    return list.filter(
      (v) =>
        v.children.filter(v=> v.label.toLowerCase().includes(filterText.toLowerCase())).length > 0 ||
        v.children.filter(v=> v.children.filter(x=> x.label.toLowerCase().includes(filterText.toLowerCase())).length > 0).length > 0
    );
  }

}

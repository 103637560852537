import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EgressPaymentProofMain, EgressPaymentProofComplete, PendingVouchers, ClientsSelected } from '../../models/client/EgressPaymentProofMain';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';

@Injectable()
export class EgressPaymentProofMainProvider {

  constructor(
    private urlProvider: URLProvider,
    private http: HttpClient
  ) {}

  public allIncomePaymentProof(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<EgressPaymentProofMain[]>(this.urlProvider.egresspaymentproof(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<EgressPaymentProofMain[]>(this.urlProvider.egresspaymentproof(enterprise), this.urlProvider.httpOptions);
    }
  }

  public listIncomePaymentProof(enterprise: string, start_date: string = '', end_date: string = '') {
    if (start_date !== '' && end_date !== '') {
      return this.http.get<EgressPaymentProofMain[]>(this.urlProvider.egresspaymentproof(enterprise) + '/list?start_date=' + start_date + '&end_date=' + end_date, this.urlProvider.httpOptions);
    } else {
      return this.http.get<EgressPaymentProofMain[]>(this.urlProvider.egresspaymentproof(enterprise) + '/list', this.urlProvider.httpOptions);
    }
  }

  public allEgressPaymentProofView(enterprise: string, token: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.egresspaymentproof(enterprise)+'/view?' +  token , this.urlProvider.httpOptions);
  }

  public getNextPreviousPage(url, token: string = '') {
    return this.http.get<ViewPaginator>(url + '&'+ token, this.urlProvider.httpOptions);
  }

  public getIncomePaymentProof(enterprise: string, id: number) {
    return this.http.get<EgressPaymentProofComplete>(this.urlProvider.egresspaymentproof(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public pdfPaymentProof(enterprise: string, type: string,id: string) {
    return this.urlProvider.pdfpaymentproof(enterprise)+ '/' + type+ '/' + id;
  }

  public getSequential(enterprise: string, branch_office_code: string, stablishment_code: string, project_name: string) {
    return this.http.get<string>(this.urlProvider.egresspaymentproofaction(enterprise) + '/sequential?branch_office_code=' + branch_office_code + '&stablishment_code=' + stablishment_code + '&project_name=' + project_name, this.urlProvider.httpOptions);
  }

  public getPurchaseByProvider(enterprise: string, identification: ClientsSelected[], exchange='') {

    return this.http.post<PendingVouchers[]>(this.urlProvider.egresspaymentproofaction(enterprise) + '/purchases'+ exchange, identification, this.urlProvider.httpOptions);
  }

  public getDocByEgressID(enterprise: string, id: string) {

    return this.http.get<PendingVouchers[]>(this.urlProvider.egresspaymentproofaction(enterprise) + '/docs/'+id, this.urlProvider.httpOptions);
  }

  public getExistenCheck(enterprise: string, cuenta: string,check:string) {

    return this.http.get<any[]>(this.urlProvider.egresspaymentproofaction(enterprise) + '/check/'+cuenta+'/'+check, this.urlProvider.httpOptions);
  }

  public getRedimirByProvider(enterprise: string, identification: ClientsSelected[]) {

    return this.http.post<PendingVouchers[]>(this.urlProvider.egresspaymentproofaction(enterprise) + '/redimir', identification, this.urlProvider.httpOptions);
  }

  public insertEgressPaymentProof(enterprise: string, egress_payment_proof: EgressPaymentProofComplete) {
    return this.http.post<any>(this.urlProvider.egresspaymentproof(enterprise), egress_payment_proof, this.urlProvider.httpOptions);
  }

  public updateEgressPaymentProof(enterprise: string, egress_payment_proof: EgressPaymentProofComplete) {
    return this.http.put<any>(this.urlProvider.egresspaymentproofaction(enterprise) + '/update', egress_payment_proof, this.urlProvider.httpOptions);
  }

  public cancelEgressPaymentProof(enterprise: string, egress_payment_proof: EgressPaymentProofComplete) {
    return this.http.put<any>(this.urlProvider.egresspaymentproofaction(enterprise) + '/cancel', egress_payment_proof, this.urlProvider.httpOptions);
  }

  public repaimentEgressPaymentProof(enterprise: string, parameters_repayment: parameters_repayment) {
    return this.http.put<any>(this.urlProvider.egresspaymentproof(enterprise) + '/repayment', parameters_repayment, this.urlProvider.httpOptions);
  }

  public getRedimirByAnticipo(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.egresspaymentproofaction(enterprise) + '/redbyid/'+id,  this.urlProvider.httpOptions);
  }

  public getEgressPaymentProofReport(enterprise: string, filters = "") {
    return this.http.get<any>(this.urlProvider.egresspaymentproofreport(enterprise) + '?' + filters, this.urlProvider.httpOptions);
  }

  public getEgressPaymentProofReportPdf(enterprise: string, filters = "") {
    return this.urlProvider.egresspaymentproofreport(enterprise)+ '/pdf?' + filters;
  }

  public updateValidatedtEgressPaymentProof(enterprise: string, id, validate_ceg) {
    return this.http.put<any>(this.urlProvider.egresspaymentproof(enterprise) + '/validated/' + id , validate_ceg, this.urlProvider.httpOptions);
  }

}

export interface parameters_repayment {
  id:number;
  income_date: string;
  user: string;
  generate_new: boolean;
  commentary: string;
};

import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpEventType } from "@angular/common/http";

import {MatDialog} from '@angular/material/dialog';
import { EncryptProvider } from '../../providers/utils/encrypt.provider';
import { SpeedTestProvider } from '../../providers/utils/speed_test.provider';

/* Redux */
import { Store, select } from '@ngrx/store';
import { Menu } from '../../states/menu';
import { BreadcrumbState } from '../../states/breadcrumb';
import { Body } from '../../states/body';
import { Update } from '../../actions/menu.action';
import { Update as UpdateUser } from '../../actions/user.action';
import { Update as UpdateMenu} from '../../actions/menu.action';
import { Update as UpdateEnterprise } from '../../actions/enterprise.action';
import { UserState } from '../../states/user';
import { Update as UpdateBody } from '../../actions/body.action';
import { Update as UpdateBreadcrumb } from '../../actions/breadcrumb.action';
import { Observable, interval, Subject, ReplaySubject } from 'rxjs';
import { EnterpriseState } from '../../states/enterprise';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Update as UpdateUserWebsocket } from '../../actions/user_websocket.action';
import { take, takeUntil } from 'rxjs/operators';

// Providers
import { UserMenuProvider } from "../../providers/client/user_menu.provider";
import { UserAccountProvider } from "../../providers/client/user_account.provider";
import { UserAccountRoleProvider } from "../../providers/client/user_account_role.provider";
import { UserRolePermisionProvider } from "../../providers/client/user_role_permisions.provider";
import { RoleProvider } from '../../providers/client/role.provider';
import { CreateMenuProvider } from '../../providers/utils/create_menu.provider';
import { NotificationProvider } from '../../providers/client/notifications.provider';
import { LoginProvider } from "../../providers/admin/login.provider";

// Toastr
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserAccount } from '../../models/client/UserAccount';
import { UserAccountRole } from '../../models/client/UserAccountRole';
import { Role } from '../../models/client/Role';
import { UserRolePermision } from '../../models/client/UserRolePermision';
import { UserMenu } from '../../models/client/UserMenu';
import { Notification } from '../../models/client/Notification';
import { User } from 'firebase';
/* END Redux */
import * as moment from 'moment';
import * as moment_timezone from "moment-timezone";
// Modal
import { YesNoConfirmationModal } from "../modals/yes-no-confirmation/yes-no-confirmation.modal";
import { UserWebsocket } from '../../states/UserWebsocket';
import { WebsocketProvider } from "../../providers/utils/websocket.provider";
import { Socket } from 'ngx-socket-io';
import { format } from 'url';
//import { SoundsService } from 'ngx-sounds';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})

export class TopBarComponent implements OnInit {
  private socket$: WebSocketSubject<any>;
  private className: string = 'nav-md';
  public user$: Observable<UserState>;
  public breadcrumb$: Observable<BreadcrumbState[]>;
  public body$: Observable<Body>;
  public enterprise$: Observable<EnterpriseState>;
  public enterprise_state: EnterpriseState;
  public stablisment_selected: any;
  public enterprise_selected: any;

  private _onDestroy = new Subject<void>();
  user_account: UserAccount;
  private user_state: UserState = {
    user_account: null,
    user_account_roles: null,
    user_role_permissions: null,
    user_role_selected: null,
    user_role_name_selected: ''
  };
  current_user_state: UserState;
  user_account_roles: UserAccountRole[];
  user_role_permisions: any[];
  menu: Menu[];

  public filteredEnterprise: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredStablisment: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  /* Test Speed */
  private percentDone: number;
  private startTime: any;
  private endTime: any;
  private currTime: any;
  private prevTime: any;
  public speed: number = 0;
  private bytesReceied: number = 0;
  private oldbytes: number = 0;
  public unit: string = "Mbps";
  private interval = interval(60000);
  /* END Test Speed */

  constructor(
    // // private socket: Socket,
    private renderer: Renderer2,
    private storeMenu: Store<{ menu: Menu[] }>,
    private storeUser: Store<{ user: UserState[] }>,
    private store: Store<{menu: Menu[], user: UserState}>,
    private breadcrumbStore: Store<{breadcrumb: BreadcrumbState[]}>,
    private storeEnterprise: Store<{enterprise: EnterpriseState}>,
    public dialog: MatDialog,
    private encryptService: EncryptProvider,
    private toastrService: ToastrService,
    private router: Router,
    private UserAccountService: UserAccountProvider,
    private roleService: RoleProvider,
    private UserMenuService: UserMenuProvider,
    private UserAccountRoleService: UserAccountRoleProvider,
    private UserRolePermisionService: UserRolePermisionProvider,
    private createMenuService: CreateMenuProvider,
    private storeBody: Store<{ body: Body }>,
    private storeUserWebsocket: Store<{ user_websocket: UserWebsocket[] }>,
    private websocketService: WebsocketProvider,
    private speedTestService: SpeedTestProvider,
    private notificationService: NotificationProvider,
    //private sounds: SoundsService,
    private loginService: LoginProvider,
  ) {
    this.user$ = store.pipe(select('user'));
    this.enterprise$ = this.storeEnterprise.pipe(select('enterprise'));
    this.breadcrumb$ = this.breadcrumbStore.pipe(select('breadcrumb'));
    this.body$ = storeBody.pipe(select('body'));
    // this.socket$ = webSocket('ws://localhost:8050');

    // // Escuchar mensajes del servidor
    // this.socket$.subscribe(
    //   (message:any) => {
    //     console.log(message);
    //     console.log("Mensaje del servidor:", message);
    //     if (message.message === "logout") {
    //       this.logout();
    //     }
    //   },
    //   (err) => console.error("Error en WebSocket:", err)
    // );

    this.getEnterpriseRedux()
  }

  ngOnInit() {

    this.testSpeed();
    this.interval.pipe(takeUntil(this._onDestroy)).subscribe(
      n => {
        this.testSpeed();
      }
    );
    this.user$.subscribe(
      (data: UserState) => {
        this.current_user_state = data;

      }
    );
      // setTimeout(() => {
      //   this.getEnterpriseRedux()
      // }, 3000);
      this.onNotifications()
      this.reloadNotifications()
      this.getNotifications();
  }

  close() {
    this.socket$.complete();
  }

  public notifications = [];
  onNotifications() {
    // this.socket.on('send-notification', (payload: any) => {

    //   if (payload.receiver == this.current_user_state.user_account[0].id
    //     && this.enterprise_state.entity.db_name == payload.db_name) {
    //     payload.user = this.current_user_state.user_account[0].name + ' ' + this.current_user_state.user_account[0].surname
    //     this.notifications.unshift(payload)
    //     if (this.notifications.length > 5) {
    //       this.notifications.pop()

    //     }

    //     console.log(this.notifications);
    //     this.notifications = this.notifications.sort((a,b)=>a.date-b.date);
    //     this.playSound();
    //     console.log('send-notification recibido');

    //   }
    // });
  }

  reloadNotifications() {
    // this.socket.on('reload-notification-client', (payload: any) => {
    //  let index = this.notifications.findIndex(v=> v._id == payload);
    //  if (index >= 0) {
    //    this.notifications.splice(index, 1);
    //  }
    //  if (this.notifications.length == 0) {
    //   this.getNotifications();
    //   this.playSound();
    //  }
    // });
  }

  getNotifications() {
    let filters = 'notification_bar=true&receiver='+this.current_user_state.user_account[0].id+'&viewed=false&limit=5';
    this.notificationService.allNotifications(this.enterprise_state.entity.db_name, filters).subscribe(
      (data: Notification[]) => {
      data.forEach(element => {
          element.user = this.current_user_state.user_account[0].name + ' ' + this.current_user_state.user_account[0].surname
        });
        this.notifications = data;
      }
    )
  }

  updateNotification(item) {
    item.viewed = true;
    this.notificationService.updateNotification(this.enterprise_state.entity.db_name,item,item._id ).subscribe(
      (data: any) => {
        if (data) {
          let index = this.notifications.findIndex(v=> v._id == item._id);
          if (index >= 0) {
            this.notifications.splice(index, 1);
          }

        }
      }
    )
  }

  notificationTimeAgo(notification) {
    let current_time_zone = moment_timezone.tz.guess(true);
    let now = moment();
    let date_notification = moment_timezone(notification.date).tz(current_time_zone).format('YYYY-MM-DD HH:mm');
    let diff = moment.duration(now.diff(date_notification));
    if (diff.asDays() >= 1) {
      notification.ago=  `hace ${(diff.asDays()).toFixed(0)} d`
    } else if(diff.asHours() >= 1) {
      notification.ago=  `hace ${(diff.asHours()).toFixed(0)} h`
    } else if(Number((diff.asMinutes()).toFixed(0)) == 0) {
      notification.ago=  `hace ${(diff.asSeconds()).toFixed(0)} s`
    } else {
      notification.ago=  `hace ${(diff.asMinutes()).toFixed(0)} m`
    }
    return notification.ago
  }

  playSound(){
    // this.sounds.play('assets/got-it-done.mp3')
    // .subscribe(
    //   () => console.log('sound played!')
    // );
  }


  public diff_days_expiration = null;
  getEnterpriseRedux(){
    this.enterprise$.pipe().subscribe(
      (data: EnterpriseState) => {
        this.enterprise_state = data;
        this.enterprise_selected = this.enterprise_state.enterprises.find(v=> v.identity == this.enterprise_state.identity);
        let stablishment = this.enterprise_state.stablishment_selected? this.enterprise_state.stablishment_selected.id : null
        let date_expiration = moment(data.invoice.signature_expiration_date)
        let current_date:any = moment()
        this.diff_days_expiration = date_expiration.diff(current_date, 'days')
        if (data.stablishment_selected) {
          this.stablisment_selected =this.enterprise_state.entity.branch_offices.find(v=> v.id == stablishment)
        } else {
          if (this.enterprise_state.entity.branch_offices.length > 0) {
            this.stablisment_selected = this.enterprise_state.entity.branch_offices[0];
            let enterprise_state: EnterpriseState = {
              identity: this.enterprise_state.identity,
              acc: this.enterprise_state.acc,
              configuration: this.enterprise_state.configuration,
              entity: this.enterprise_state.entity,
              establishment: this.enterprise_state.establishment,
              inventory: this.enterprise_state.inventory,
              integration: this.enterprise_state.integration,
              invoice: this.enterprise_state.invoice,
              representatives: this.enterprise_state.representatives,
              taxes: this.enterprise_state.taxes,
              branchofficesenterprise: this.enterprise_state.branchofficesenterprise,
              stablishment_selected: this.stablisment_selected,
              enterprises: this.enterprise_state.enterprises,
              company_groups: this.enterprise_state.company_groups,
              aloha_enterprise: this.enterprise_state.aloha_enterprise,
            }
            this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise_state));
          }
        }
        //console.log(stablishment, 'this.stablisment_selected', this.enterprise_state.entity.branch_offices);
        this.filterEnterprise('')
        this.filterStablishment('')
      },
      error => {
        console.log('error session_state', error);
      }
    )

    /*if (this.enterprise_state.acc.status == false) {
      this.router.navigate(['/admin/parameters/account-assignment']);
      for (let i = 0; i < this.enterprise_state.acc.response.length; i++) {
        this.showError(`Falta configurar ${this.enterprise_state.acc.response[i]}`)
      }
    }*/
  }

  filterMyOptions(e: string) {
    this._onDestroy;
    this.filterEnterprise(e);
  }

  protected filterEnterprise(enterprise: any) {
    if (!this.enterprise_state.enterprises) {
      return;
    }
    let search = enterprise;
    if (!search) {
      this.filteredEnterprise.next(this.enterprise_state.enterprises.slice());
    }
    this.filteredEnterprise.next(
      this.enterprise_state.enterprises.filter(
        enterprise =>
          enterprise.entity.businnes_name.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  }

  filterMyOptionsStablishment(e: string) {
    this._onDestroy;
    this.filterStablishment(e);
  }

  protected filterStablishment(enterprise: any) {
    if (!this.enterprise_state.entity.branch_offices) {
      return;
    }
    let search = enterprise;
    if (!search) {
      this.filteredStablisment.next(this.enterprise_state.entity.branch_offices.slice());
    }

    if(this.enterprise_state.entity.branch_offices && this.enterprise_state.entity.branch_offices.length > 0) {
      this.filteredStablisment.next(
        this.enterprise_state.entity.branch_offices.filter(
          branch_offices =>
            branch_offices.sri_establishment.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            branch_offices.local.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
      );
    }
  }
  showError(message) {
    this.toastrService.error(message, `Error`, {
      timeOut: 3000,
      positionClass: 'toast-bottom-center'
    });
  }

  toggle() {
    const key = window.btoa('gfsys_menu');
    if (this.className === 'nav-md') {
      this.renderer.removeClass(document.body, 'nav-md');
      this.renderer.addClass(document.body, 'nav-sm');
      this.storeBody.dispatch(new UpdateBody({class: 'nav-sm'}));
      this.className = 'nav-sm';
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        let actualMenu: Menu[] =  JSON.parse(this.encryptService.get(localStorage.getItem(key)));
        for (let i = 0; i < actualMenu.length; i++) {
          actualMenu[i].active = false;
        }
        this.store.dispatch(new Update(actualMenu));
      }
    } else {
      this.renderer.removeClass(document.body, 'nav-sm');
      this.renderer.addClass(document.body, 'nav-md');
      this.storeBody.dispatch(new UpdateBody({class: 'nav-md'}));
      this.className = 'nav-md';
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        let actualMenu: Menu[] =  JSON.parse(this.encryptService.get(localStorage.getItem(key)));
        for (let i = 0; i < actualMenu.length; i++) {
          if (actualMenu[i].children.length > 0) {
            for (let j = 0; j < actualMenu[i].children.length; j++) {
              if (actualMenu[i].children[j].active) {
                actualMenu[i].active = true;
              }
            }
          }
        }
        this.store.dispatch(new Update(actualMenu));
      }
    }
  }

  changeStablishment(){
    debugger
    let enterprise_state: EnterpriseState = {
      identity: this.enterprise_state.identity,
      acc: this.enterprise_state.acc,
      configuration: this.enterprise_state.configuration,
      entity: this.enterprise_state.entity,
      establishment: this.enterprise_state.establishment,
      inventory: this.enterprise_state.inventory,
      integration: this.enterprise_state.integration,
      invoice: this.enterprise_state.invoice,
      representatives: this.enterprise_state.representatives,
      taxes: this.enterprise_state.taxes,
      branchofficesenterprise: this.enterprise_state.branchofficesenterprise,
      stablishment_selected: this.stablisment_selected,
      company_groups: this.enterprise_state.company_groups,
      aloha_enterprise: this.enterprise_state.aloha_enterprise,
      enterprises: this.enterprise_state.enterprises
    }
    this.openYesNoConfirmationEstablisment(enterprise_state);
    //this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise_state));
  }

  changeEnterprise(){
    let enterprise_state: EnterpriseState | any = {
      identity: this.enterprise_selected.identity,
      entity: this.enterprise_selected.entity,
      // acc: this.enterprise_selected.acc,
      // configuration: this.enterprise_selected.configuration,
      // establishment: this.enterprise_selected.establishment,
      // integration: this.enterprise_selected.integration,
      // inventory: this.enterprise_selected.inventory,
      // invoice: this.enterprise_selected.invoice,
      // representatives: this.enterprise_selected.representatives,
      // taxes: this.enterprise_selected.taxes,
      // branchofficesenterprise: this.enterprise_selected.branchofficesenterprise,
      // stablishment_selected: this.enterprise_selected.stablishment_selected,
      // aloha_enterprise: this.enterprise_selected.aloha_enterprise,
      // company_groups: this.enterprise_selected.company_groups,
      enterprises: this.enterprise_state.enterprises,
    }
    this.openYesNoConfirmation(enterprise_state);
  }

  openYesNoConfirmation(enterprise : EnterpriseState){
    const dialogRef = this.dialog.open(YesNoConfirmationModal, {
      width: '600px',
      data: { title: '¿Quiere Cambiar de Empresa?', enterprise_state: enterprise }
    });

    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(result => {
      if (result) {
        // let user_websocket:UserWebsocket = {
        //   user_account: this.current_user_state.user_account[0],
        //   enterprise: result.enterprise_state.entity
        // }
        // this.storeUserWebsocket.dispatch(new UpdateUserWebsocket(user_websocket));
        // this.websocketService.userConnect();
        // this.storeEnterprise.dispatch(new UpdateEnterprise(result.enterprise_state));
        // this.enterprise_state = result.enterprise_state;
        // this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.current_user_state.user_account[0].email);
        this.getEnterprise(result.enterprise_state.entity.id)
      } else {
        this.enterprise_selected = this.enterprise_state.enterprises.find(v=> v.identity == this.enterprise_state.identity);
        this.stablisment_selected = this.enterprise_state.stablishment_selected
      }
    });
  }

  public getEnterprise(id) {
    this.loginService.getEnterprise(this.current_user_state.user_account[0].email+'&product=ERP', id).subscribe(
      (data: any) => {
        if(data.enterprises.length > 0) {
          let user_websocket:UserWebsocket = {
            user_account: this.current_user_state.user_account[0],
            enterprise: data.enterprises[0].entity
          }
          this.storeUserWebsocket.dispatch(new UpdateUserWebsocket(user_websocket));

          let enterprise_state: EnterpriseState | any = {
            identity: data.enterprises[0].identity,
            entity: data.enterprises[0].entity,
            acc: data.enterprises[0].acc,
            configuration: data.enterprises[0].configuration,
            establishment: data.enterprises[0].establishment,
            integration: data.enterprises[0].integration,
            inventory: data.enterprises[0].inventory,
            invoice: data.enterprises[0].invoice,
            representatives: data.enterprises[0].representatives,
            taxes: data.enterprises[0].taxes,
            branchofficesenterprise: data.enterprises[0].branchofficesenterprise,
            stablishment_selected: data.enterprises[0].stablishment_selected,
            aloha_enterprise: data.enterprises[0].aloha_enterprise,
            company_groups: data.enterprises[0].company_groups,
            enterprises: this.enterprise_state.enterprises,
            feature_flags: data.enterprises[0].feature_flags
          }
          this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise_state));
          // enterprise_state.enterprises = this.enterprise_state.enterprises
          // this.enterprise_state = enterprise_state;
          this.stablisment_selected = enterprise_state.entity.branch_offices[0]
          this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.current_user_state.user_account[0].email);
          // let result = data.enterprises[0]
          // this.enterprise_state.acc = result.acc
          // this.enterprise_state.configuration = result.configuration
          // this.enterprise_state.entity = result.entity
          // this.enterprise_state.establishment = result.establishment
          // this.enterprise_state.inventory = result.inventory
          // this.enterprise_state.integration = result.integration;
          // console.log(result.integration);

          // this.enterprise_state.invoice = result.invoice
          // this.enterprise_state.representatives = result.representatives
          // this.enterprise_state.taxes = result.taxes;
          // this.enterprise_state.branchofficesenterprise = result.branchofficesenterprise;
          // this.enterprise_state.stablishment_selected = result.stablishment_selected;
          // this.enterprise_state.company_groups = result.company_groups;
          // this.enterprise_state.aloha_enterprise = result.aloha_enterprise;

          // debugger
          // this.enterprise_state.identity = result.identity
          // // this.enterprise_state.enterprises = this.user.enterprises
          // this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.current_user_state.user_account[0].email);
        }
      }
    )
  }


  openYesNoConfirmationEstablisment(enterprise : EnterpriseState){
    debugger
    const dialogRef = this.dialog.open(YesNoConfirmationModal, {
      width: '600px',
      data: { title: '¿Quiere Cambiar de Establecimiento?', enterprise_state: enterprise }
    });

    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy)).subscribe(result => {
      console.log("result establish", result)
      debugger
      if (result) {
        this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise));
        window.location.reload();
      }
    });
  }


  getUserAccount(enterprise: string, token: string, value: string ) {
    this.UserAccountService.allUserAccounts(enterprise, token, value).pipe(takeUntil(this._onDestroy)).subscribe(
      (data: UserAccount[]) => {
        this.user_account = data[0];
        this.user_state.user_account  = data;
        this.getUserAccountRoles(this.enterprise_state.entity.db_name, 'users_account_id', this.user_account.id);
      },
      error => {
        console.log('error getUserAccount', error);
      }
    )
  }
  getUserAccountRoles(enterprise: string , token: string, value: string ) {
    this.UserAccountRoleService.allUserAccountRole(enterprise, token, value).pipe(takeUntil(this._onDestroy)).subscribe(
      (data: UserAccountRole[]) => {

        if (data.length > 0) {

          this.user_account_roles = data;
          this.user_state.user_account_roles  = data;
          this.user_state.user_account_roles = this.user_account_roles;
          // if (!this.user_state.user_account_roles) {
            if (!this.change_rol) {
              this.user_state.user_role_selected = 0;
             // console.log ('USER ROLE PERMISOS',this.user_state.user_role_permissions);
              this.getUserRole();

            }
          // }

        } else {
          // this.showSpinner = false;
          // this.notification('Algo ocurrió mal, por favor, intente de nuevo');
        }
      },
      error => {
        // this.showSpinner = false;
        console.log('error getUserAccount', error);
      }
    )
  }
  getUserRole(rol_id = null) {
    rol_id = !rol_id? `${this.user_account_roles[0].users_roles_id}` : rol_id;
    this.roleService.getRole(this.enterprise_state.entity.db_name, rol_id).pipe(takeUntil(this._onDestroy)).subscribe(
      (data: Role) => {
        // if (!this.user_state.user_role_name_selected) {
        this.user_state.is_superadmin = data.super_user;
        this.user_state.user_role_name_selected = data.name;
        this.user_state.user_role_selected = data.id;
        this.getUserRolePermisions(this.enterprise_state.entity.db_name, 'users_roles_id', rol_id +'');
        // }

      },
      error => {

      }
    );
  }
  getUserRolePermisions(enterprise: string, token: string, value:string) {
    this.UserRolePermisionService.allUserRolePermisions(enterprise, token, value).pipe(takeUntil(this._onDestroy)).subscribe(
      (data: UserRolePermision[]) => {
        this.user_role_permisions = data;
        this.getUserMenu(this.enterprise_state.entity.db_name);
      },
      error => {
      }
    )
  }
  getUserMenu(enterprise: string) {
    this.UserMenuService.allUserMenus(enterprise).pipe(takeUntil(this._onDestroy)).subscribe(
      (data: UserMenu[]) => {
        this.cleanUserPermisionRole(data);
      },
      error => {
        // this.showSpinner = false;
        // console.log('error getUserMenus', error);
      }
    )
  }
  cleanUserPermisionRole(user_menu: UserMenu[]) {
    let cantidad: number = 0;
    for (let i = (this.user_role_permisions.length - 1); i >= 0; i--) {
      cantidad = 0;
      for (let j = 0; j < this.user_role_permisions.length; j++) {
        if (this.user_role_permisions[j].users_menu_id === this.user_role_permisions[i].users_menu_id) {
          cantidad = 1 + cantidad;
        }
      }
      // if (cantidad > 1) {
      //   this.user_role_permisions.splice(i, 1);
      // }
    }
    this.user_state.user_role_permissions = this.user_role_permisions;
    const menu: Menu[] = this.createMenuService.create(user_menu, this.user_role_permisions);
    this.menu = menu;
    this.storeMenu.dispatch(new UpdateMenu(menu));
    if (!this.change_rol) {

      this.storeEnterprise.dispatch(new UpdateEnterprise(this.enterprise_state));
    }
    if (this.change_rol) {
      this.change_rol =false;
      this.user_state = {
        user_role_permissions: this.user_role_permisions,
        user_account: this.current_user_state.user_account,
        user_account_roles: this.user_account_roles,
        user_role_selected:this.user_state.user_role_selected,
        user_role_name_selected: this.user_state.user_role_name_selected,
        is_superadmin: this.user_state.is_superadmin,
      }
    }
    this.user_state.user_account_roles= this.user_account_roles;

    this.storeUser.dispatch(new UpdateUser(this.user_state));

    this.router.navigate(['/admin/dashboard'])


      setTimeout(() => {
        location.reload();
      }, 500);

    // this.openDialog2()
  }
  public change_rol = false;
  changeRole(role_id: number, user_account_role_id: number, user_role_name: string) {
    this.change_rol = true;
   // console.log('role_id', role_id, 'user_account_role_id', user_account_role_id, 'user_role_name', user_role_name);
    this.getUserAccountRoles(this.enterprise_state.entity.db_name, 'users_account_id', this.current_user_state.user_account[0].id);
    this.getUserRole(role_id)

  }

  logout(){
    this.router.navigate(['/auth/login']);
  }

  testSpeed() {
    this.startTime = new Date().getTime();
    this.prevTime = this.startTime;
    this.speedTestService.download().subscribe(event => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.percentDone = Math.round((100 * event.loaded) / event.total);
        this.currTime = new Date().getTime();
        this.bytesReceied = event.loaded / 1000000;
        this.speed =
          (this.bytesReceied - this.oldbytes) /
          ((this.currTime - this.prevTime) / 1000);
        if (this.speed < 1) {
          this.unit = "Kbps";
          this.speed *= 1000;
        } else this.unit = "Mbps";
        this.prevTime = this.currTime;
        this.oldbytes = this.bytesReceied;
        if (this.percentDone === 100) {
          this.endTime = new Date().getTime();
          let duration = (this.endTime - this.startTime) / 1000;
          let mbps = event.total / duration / 1000000;
          if (mbps < 1) {
            this.speed = event.total / duration / 1000;
            this.unit = "Kbps";
          } else {
            this.speed = mbps;
            this.unit = "Mbps";
          }
          this.speed = this.speed;
        }
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { UserWebsocket } from '../../states/UserWebsocket';
// import { Usuario } from '../classes/usuario';
import * as moment from 'moment';
import * as moment_timezone from "moment-timezone";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { PieceReservationRuleProvider } from "../../providers/client/piece-reservation-rule.provider";
import { ProductLot } from '../../models/client/ProductLot';
@Injectable({
  providedIn: 'root'
})
export class GetStockPieceProvider {

  public socketStatus = false;
  public user_websocket$: Observable<UserWebsocket>;
  public user_websocket_state: UserWebsocket = null;

  constructor(
    // // private socket: Socket,
    private _snackBar: MatSnackBar,
    private pieceReservationRuleService:PieceReservationRuleProvider,
    private toastrService: ToastrService,
  ) {

    // this.cargarStorage();

  }



 getStock(supplies, data: ProductLot[], stock_base, current_custom_made, index_step, reservation_rules, warehouses_id:string) {
  reservation_rules = reservation_rules.sort((a,b)=>a.position-b.position);
  let warehouse_supplies = [];
  let warehouse_product = [];
  let warehouses = warehouses_id.split(',');
  let stocks = [];
  data = data.filter(v=> Number(Number(v.stock) - Number(v.stock_reserved)) > 0);
  debugger
  for (const rule of reservation_rules) {
    if (rule.warehouse != 'local') {
      stocks = data.filter(v=> v.id_warehouse == rule.warehouse);
        // &&Number(Number(v.stock) - Number(v.stock_reserved)) >= Number(stock_base));
      if (stocks.length > 0) {
        break
      }
    } else if (rule.warehouse == 'local') {
      stocks = data.filter(v=> warehouses.findIndex(x=> Number(x) == v.id_warehouse) >= 0)
      // && Number(Number(v.stock) - Number(v.stock_reserved)) >= Number(stock_base));
      if (stocks.length > 0) {
        break
      }
    }
  }
  if (stocks.length == 0) {
    stocks = data;
  }
  if (supplies) {
    for (let i = 0; i < warehouse_supplies.length; i++) {
      warehouse_supplies[i].stock = warehouse_supplies[i].stock - warehouse_supplies[i].stock_reserved
    }
     warehouse_supplies = stocks.sort((a,b)=>b.stock-a.stock);

    // warehouse_supplies = stocks.filter(v=> Number(Number(v.stock) - Number(v.stock_reserved)) >= Number(stock_base))
    if (warehouse_supplies.length == 0) {
      current_custom_made.piece.piece.steps[index_step].supplies = []
      this.notification('no hay stock')
    }
  } else {
    for (let i = 0; i < warehouse_product.length; i++) {
      warehouse_product[i].stock = warehouse_product[i].stock - warehouse_product[i].stock_reserved
    }
    warehouse_product = stocks.sort((a,b)=>b.stock-a.stock);

    // warehouse_product = stocks.filter(v=> Number(Number(v.stock) - Number(v.stock_reserved)) >= Number(stock_base))
    if (warehouse_product.length == 0) {
      this.notification('no hay stock')
    }

  }

  if (supplies) {
    return warehouse_supplies;
  } else {
    return warehouse_product;
  }
 }

  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 6000
    });
  }




}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UpdateToken } from '../../models/client/UpdateToken';
import { BuyRetention } from '../../models/client/BuyRetention';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class BuyRetentionMainProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allBuyRetention(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<BuyRetention[]>(this.urlProvider.buyretentionmain(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<BuyRetention[]>(this.urlProvider.buyretentionmain(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getListBuyRetention(enterprise: string, start_date, end_date: string = '') {
    return this.http.get<BuyRetention[]>(this.urlProvider.buyretentionmain(enterprise) + '/list' + '?' + 'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date, this.urlProvider.httpOptions);
  }

  public viewBuyRetention(enterprise: string, per_page_value: number,  token: string = "", value: string = "", token2: string = "", value2: string = "") {
    return this.http.get<ViewPaginator>(this.urlProvider.buyretentionmain(enterprise) + '/view?'+token+'='+value+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public viewBuyRetentionlist(enterprise: string, start_date, end_date: string = '', per_page_value: number, token: string = "",value: string = "", token2: string = "", value2: string = ""  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.buyretentionmain(enterprise) + '/view?list=true&'+'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date+'&row=' + per_page_value + '&' + token + '=' + value + '&' + token2 + '=' + value2, this.urlProvider.httpOptions);
  }

  public listFilterBuyRetention(enterprise: string, filter: string = '', per_page_value: number ) {
    return this.http.get<ViewPaginator>(this.urlProvider.buyretentionmain(enterprise) + '/view?'+filter+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public buyRetentionlist(enterprise: string, branch_offices_id:string, filters:string='') {
    return this.http.get<ViewPaginator>(this.urlProvider.buyretentionmain(enterprise) + '/view?list=true&branch_offices_id='+branch_offices_id+filters, this.urlProvider.httpOptions);
  }

  public viewBuyRetentionReport(enterprise: string, start_date, end_date: string = '', status_sri: string ,concept: string, branch_offices_id:string  ) {
    return this.http.get<any>(this.urlProvider.buyretentionmain(enterprise) + '/report?start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date + '&status_sri' + '=' + status_sri+ '&concept' + '=' + concept+'&branch_offices_id='+branch_offices_id, this.urlProvider.httpOptions);
  }

  public viewBuyRetentionlistSearch(enterprise: string, busqueda: string = ''  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.buyretentionmain(enterprise) + '/view?search='+busqueda, this.urlProvider.httpOptions);
  }

  public nextPreviousPageRetention(url: string, per_page_value: number , token: string = "", value: string = "", filters: string = "") {
    return this.http.get<ViewPaginator>(url + '&row=' + per_page_value + '&' + token + '=' + value +'&list=true'+ filters, this.urlProvider.httpOptions);
  }

  public getBuyRetention(enterprise: string, id: string) {
    return this.http.get<BuyRetention>(this.urlProvider.buyretentionmain(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertBuyRetention(enterprise: string, buy_retention: BuyRetention) {
    return this.http.post<number>(this.urlProvider.buyretentionmain(enterprise), buy_retention, this.urlProvider.httpOptions);
  }

  public updateBuyRetention(enterprise: string, buy_retention: BuyRetention, id: string) {
    return this.http.put<Boolean>(this.urlProvider.buyretentionmain(enterprise) + '/' + id, buy_retention, this.urlProvider.httpOptions);
  }

  public deleteBuyRetention(enterprise: string, id: string, user:UpdateToken) {
    return this.http.put<Boolean>(this.urlProvider.buyretentionmain(enterprise) + '/' + id, user, this.urlProvider.httpOptions);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Notification } from '../../models/client/Notification';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class NotificationProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allNotifications(enterprise: string, filters: string = '') {
    return this.http.get<Notification[]>(this.urlProvider.notifications(enterprise) + '?' + filters , this.urlProvider.httpOptions);

  }

  public allNotificationsView(enterprise: string, filters ='') {
    return this.http.get<ViewPaginator>(this.urlProvider.notifications(enterprise) + '/view?' + filters, this.urlProvider.httpOptions);
  }

  public getNextPreviousPage(url) {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }


  public getNotification(enterprise: string, id: string) {
    return this.http.get<Notification>(this.urlProvider.notifications(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public updateNotification(enterprise: string, notifications: Notification, id: string) {
    return this.http.put<Boolean>(this.urlProvider.notifications(enterprise) + '/' + id, notifications, this.urlProvider.httpOptions);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { LoginAuthenticate } from './models/admin/LoginAuthenticate';

import { UserState } from './states/user';
import { SessionState } from './states/session';
import { Menu } from './states/menu';

import { LoginAuthenticateProvider } from "./providers/admin/login-authenticate.provider";

import { Store, select } from '@ngrx/store';

import { MatSnackBar } from '@angular/material/snack-bar';

import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gfsys';
  public menu$: Observable<Menu[]>;
  public session_state$: Observable<SessionState>;
  public user_state$: Observable<UserState>;
  public user_id = null;
  public notificationIf: boolean;

  private sessions: LoginAuthenticate[];

  constructor(
    private storeMenu: Store<{ menu: Menu[] }>,
    private storeUser: Store<{ user: UserState}>,
    private storeSession: Store<{ session: SessionState}>,
    private loginSessionService: LoginAuthenticateProvider,
    private readonly _: LoadingService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public loadingService: LoadingBarService
  ) {
    this.menu$ = storeMenu.pipe(select('menu'));
    this.user_state$ = storeUser.pipe(select('user'));
    this.session_state$ = storeSession.pipe(select('session'));

  }

  get count() {
    // warning: do not use `_pendingRequests`, it's used here for demo purpose only
    return this.loadingService['_pendingRequests'];
  }

  ngOnInit() {
    this.user_state$.subscribe(
      (data: UserState) => {
        if (data.user_account) {
          this.user_id = data.user_account[0].email

        }
      },
      error => {
        console.log('error session_state', error);
      }
    )
    this.loginSessionService.getLoginSession(this.user_id).subscribe(actionArray => {
      this.sessions = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ... item.payload.doc.data()
        } as LoginAuthenticate
      })

      this.session_state$.subscribe(
        (data: SessionState) => {
          if(data.token){

            setTimeout(() => {


                if (this.sessions[0]) {
                  if (data.token != null) {
                    if (data.token != this.sessions[0].token) {
                      console.log('son diferentes', data.token + ' !=! ' + this.sessions[0].token)
                      let key = window.btoa('gfsys_menu');
                      localStorage.removeItem(key);
                      key = window.btoa('gfsys_enterprise');
                      localStorage.removeItem(key);
                      key = window.btoa('gfsys_session');
                      localStorage.removeItem(key);
                      this.router.navigate(['/auth/login']);
                      this.notification('Se ha iniciado sesión con su usuario en otro dispositivo')
                      setTimeout(() => {
                        location.reload()
                      }, 500);

                    }
                  }


              }
            }, 1000);
          }else {
            //console.log('no hay bada');
          }

          },
          error => {
            console.log('error session_state', error);
          }
        )
    })
  }

  notification(masage: string ) {
    this._snackBar.open(masage, null, {
      duration: 3000
    });
  }
}

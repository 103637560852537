import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';


import { ToastrService } from 'ngx-toastr';
import { RepairsForm, RepairsFormProvider } from '../../../providers/client/repairs_form.provider';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
import { MerchandiseReceptionItem } from 'src/app/models/client/MerchandiseReception';
/* Redux */

@Component({
  selector: 'app-repairs_items-modal',
  templateUrl: './repairs_items.modal.html',
  styleUrls: ['./repairs_items.modal.scss'],
})

export class RepairsItemsModal {
  filters: string;
  public search = ''
  showSpinner: boolean;
  repair_form_view_paginator: ViewPaginator;
  repair_forms: any[];
  public quantity = []
  delete_items: any= [] = [];
  constructor(
    private toastrService: ToastrService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: data,
    private dialogRef:MatDialogRef<RepairsItemsModal>,
    private repairsFormService: RepairsFormProvider,

  ) {
    this.getRepairsFormView()
    for (let i = 0; i < this.data.item.quantity; i++) {
      this.quantity.push(i);
    }

    debugger
  }

  getRepairsFormView() {
    this.filters = `&filter=true&name=${this.search}&active=true`
    this.showSpinner = true;
    this.repairsFormService.allRepairsFormView(this.data.enterprise, 'parent', '0', this.filters).subscribe(
      (data: ViewPaginator) => {
        this.showSpinner = false; 
        this.repair_form_view_paginator = data
        this.repair_forms = data.data
      }
    )
  }

  getNextPreviousPage(url) {
    this.filters = `&filter=true&name=${this.search}&active=true`
    url = url + '&parent=0' + this.filters
    this.showSpinner = true;
    this.repairsFormService.getNextPreviousPage(url).subscribe(
      (data: ViewPaginator) => {
        this.showSpinner = false;
        this.repair_form_view_paginator = data
        this.repair_forms = data.data
      }
    )
  }

  addItemForm(event, i, form: RepairsForm, item_form: RepairsForm) {
    if (event.checked) {
      if (item_form) {
        this.data.item.merchandise_reception_03repairs.push({
          id: 0,
          position: i,
          merchandise_reception_02item_id: null,
          repairs_form_id: form.id,
          repairs_form_item_id: item_form.id,
          name: item_form.name,
          duration: Number(item_form.duration),
        });
      } else {
        this.data.item.merchandise_reception_03repairs.push({
          id: 0,
          position: i,
          merchandise_reception_02item_id: null,
          repairs_form_id: form.id,
          repairs_form_item_id: null,
          name: null,
          duration: null,
        });
      }
     
    } else {
      if (item_form) {
        let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_item_id == item_form.id)
        if (index >= 0) {
          if (this.data.item.merchandise_reception_03repairs[index].id > 0) {
            this.delete_items.push({
              id: this.data.item.merchandise_reception_03repairs[index].id
            })
          }
          this.data.item.merchandise_reception_03repairs.splice(index, 1);
        }
      } else {
        let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_id == form.id && v.repairs_form_item_id == null)
        if (index >= 0) {
          if (this.data.item.merchandise_reception_03repairs[index].id > 0) {
            this.delete_items.push({
              id: this.data.item.merchandise_reception_03repairs[index].id
            })
          }
          this.data.item.merchandise_reception_03repairs.splice(index, 1);
        }
      }
    }

    debugger
  }

  copyItem(position){
    position--;
    this.data.item.merchandise_reception_03repairs.forEach( (v,i) => {
      if (v.position == position+1) {
        this.data.item.merchandise_reception_03repairs.splice(i, 1);
      }
    })
    let items = this.data.item.merchandise_reception_03repairs.filter(v=> v.position == position);
    
    for (const item of items) {
      this.data.item.merchandise_reception_03repairs.push({
        id: 0,
        position: position+1,
        merchandise_reception_02item_id: null,
        repairs_form_id: item.repairs_form_id,
        repairs_form_item_id: item.repairs_form_item_id,
        name: item.name,
        duration: Number(item.duration),
      })
    }
  }

  checkItemPosition(i) {
    let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i)
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
  }

  checkItem(i, form: RepairsForm, item_form: RepairsForm) {
    if (item_form) {
      let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_item_id == item_form.id)
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      // cuando el check de otro esta marcado
      let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_id == form.id && v.repairs_form_item_id == null);
      if (index >= 0) {
        return true 
      } else {
        return false;
      }
    }
    
  }

  changeOther(i, form: RepairsForm, type, value) {
    debugger
    let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_id == form.id && v.repairs_form_item_id == null)
    if (index >= 0) {
      if (type == 'name') {
        this.data.item.merchandise_reception_03repairs[index].name = value?value:null;
      } else {
        this.data.item.merchandise_reception_03repairs[index].duration = (value)?Number(value):null;
      }
    }
  }

  otherValue(i, form, type) {
    let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.position == i && v.repairs_form_id == form.id && v.repairs_form_item_id == null)
    if (index >= 0) {
      if (type == 'name') {
        return this.data.item.merchandise_reception_03repairs[index].name;
      } else {
        return this.data.item.merchandise_reception_03repairs[index].duration;
      }
    } else {
      return null
    }
  }

  checkOtherValues( view =null) {
    let index = this.data.item.merchandise_reception_03repairs.findIndex(v=> v.repairs_form_item_id == null && v.name == null ||
      v.repairs_form_item_id == null && v.duration == null)

    debugger
    if (index >= 0) {
      if (!view) {
        this.showError('Si tiene seleccionada la opcion "Otro" serciorarse de tener el nombre y la duración correcta', 'Error')
      }
      return this.data.item.merchandise_reception_03repairs[index].repairs_form_id
    } else {
      return false
    }
  }

  close(): void {
    if (this.checkOtherValues()) {
      return      
    }
    let save = {
      merchandise_reception_03repairs: this.data.item.merchandise_reception_03repairs,
      delete_repairs: this.delete_items
    }
    this.dialogRef.close(save);
  }

  showError(message, title) {
    this.toastrService.error(message, `${title}`, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center'
    });
  }

  showSuccess(success, factura) {
    this.toastrService.success(success, `Factura: ${factura}`, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center'
    });
  }
}

export interface data {
  enterprise: string,
  item: MerchandiseReceptionItem,

}

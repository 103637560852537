import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Role } from '../../models/client/Role';
import { URLProvider } from '../url.providers';

@Injectable()
export class RoleProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allRoles(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<Role[]>(this.urlProvider.role(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Role[]>(this.urlProvider.role(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getRole(enterprise: string, id: string) {
    return this.http.get<Role>(this.urlProvider.role(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertRole(enterprise: string, role: Role) {
    return this.http.post<number>(this.urlProvider.role(enterprise), role, this.urlProvider.httpOptions);
  }

  public updateRole(enterprise: string, role: Role, id: string) {
    return this.http.put<Boolean>(this.urlProvider.role(enterprise) + '/' + id, role, this.urlProvider.httpOptions);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLProvider } from '../url.providers';

@Injectable()
export class LoginProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}


  public getLoginUser(token: string, value: string) {
    return this.http.get<any>(this.urlProvider.login() + '/user?' + token + '=' + value, this.urlProvider.httpOptions);
  }
  public getLoginAdmin(token: string, value: string) {
    return this.http.get(this.urlProvider.login() + '/admin?' + token + '=' + value, this.urlProvider.httpOptions);
  }
  public getLoginUserNew(token: string, value: string) {
    return this.http.get<any>(this.urlProvider.login() + '/user-new?' + token + '=' + value, this.urlProvider.httpOptions);
  }

  public getEnterprise(value: string, enterprise_id: any) {
    return this.http.get<any>(this.urlProvider.login() + '/select-enterprise/'+ enterprise_id +'?email=' + value, this.urlProvider.httpOptions);
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ElectronicalVoucherInvoice }  from '../../models/electronical-voucher/Invoice';

import { URLProvider } from '../url.providers';

@Injectable()
export class ElectronicalVoucherProvider {

  constructor(
    private http: HttpClient,
    private urlprovider: URLProvider
  ) {}

  public insertElectronicalVoucherInvoice (electronical_voucher_invoice: ElectronicalVoucherInvoice[]) {
    return this.http.post<any[]>(this.urlprovider.electronical_voucher_invoice() + '/lot', electronical_voucher_invoice, this.urlprovider.httpOptions);
  }

  public cancelElectronicalVoucherInvoice (electronical_voucher_invoice: ElectronicalVoucherInvoice) {
    return this.http.post<any>(this.urlprovider.electronical_voucher_invoice() + '/lot/xml' , electronical_voucher_invoice, this.urlprovider.httpOptions);
  }

  public sendXmlNotSign (enterprise: string, electronical_voucher_invoice: any) {
    return this.http.post<any>(this.urlprovider.xml_not_sign(enterprise), electronical_voucher_invoice, this.urlprovider.httpOptions);
  }

  public sendCreditNoteMail (enterprise: string, electronical_voucher_credit_note: any) {
    return this.http.post<any>(this.urlprovider.electronical_credit_note(enterprise) + '/sendmail', electronical_voucher_credit_note, this.urlprovider.httpOptions);
  }
  public sendCreditNoteSriAuth (enterprise: string, electronical_voucher_credit_note: any) {
    return this.http.post<any>(this.urlprovider.electronical_credit_note(enterprise) + '/sriauth', electronical_voucher_credit_note, this.urlprovider.httpOptions);
  }

}

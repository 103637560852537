import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RetentionRecivedMain } from '../../models/client/RetentionRecivedMain';
import { RetentionRecivedItem } from '../../models/client/RetentionRecivedItem';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class RetentionRecivedProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allRetentionReciveds(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<RetentionRecivedMain[]>(this.urlProvider.retention_recived(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<RetentionRecivedMain[]>(this.urlProvider.retention_recived(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allViewRetentionRecived(enterprise: string, value: string = '', branch_offices_id='') {
    return this.http.get<ViewPaginator>(this.urlProvider.retention_recived(enterprise) + '/view?'+ value +'&branch_offices_id='+branch_offices_id, this.urlProvider.httpOptions);
  }

  public nextPreviousPageRetentionRecived(url: string, per_page_value: number, filters = '') {
    return this.http.get<ViewPaginator>(url + '&row=' + per_page_value +'&'+ filters, this.urlProvider.httpOptions);
  }

  public getRetentionRecived(enterprise: string, id: string) {
    return this.http.get<RetentionRecived>(this.urlProvider.retention_recived(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getRetentionRecivedAccounting(enterprise: string,type='', id: string) {
    return (this.urlProvider.pdf_accouting_voucher(enterprise, type, id));
  }

  public getRetentionRecivedsReport(enterprise: string, filter: string) {
    return this.http.get<any>(this.urlProvider.reportinvoicedetailed(enterprise) + '/invoiceretention?' + filter, this.urlProvider.httpOptions);
  }

  public getRetentionRecivedsReportPdf(enterprise: string,filter='') {
    return (this.urlProvider.reportinvoicedetailed(enterprise) + '/invoiceretentionpdf?' + filter);
  }

  public insertRetentionRecived(enterprise: string, retention_recived: RetentionRecived) {
    return this.http.post<number>(this.urlProvider.retention_recived(enterprise), retention_recived, this.urlProvider.httpOptions);
  }

  public updateRetentionRecived(enterprise: string, retention_recived: RetentionRecived, id: string) {
    return this.http.put<Boolean>(this.urlProvider.retention_recived(enterprise) + '/' + id, retention_recived, this.urlProvider.httpOptions);
  }

  public cancelRetentionRecived(enterprise: string,  id: string, update_by: any,) {
    return this.http.put<Boolean>(this.urlProvider.retention_recived(enterprise) + '/delete/' + id, update_by, this.urlProvider.httpOptions);
  }



}

export interface RetentionRecived {
  invoice_retention_01main: RetentionRecivedMain,
  invoice_retention_02item: RetentionRecivedItem[],
  delete_invoice_retention_02item? : any[];
}


import { Action } from '@ngrx/store';
import { Upload } from '../states/upload';

export enum UploadActionTypes {
  LOAD = '[UPLOAD] LOAD',
  UPDATE = '[UPLOAD] UPDATE',
  DELETE = '[UPLOAD] DELETE'
}
export class Load implements Action {
  readonly type = UploadActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = UploadActionTypes.UPDATE;
  constructor(public payload: Upload[]) {}
}

export class Delete implements Action {
  readonly type = UploadActionTypes.DELETE;
}

export type UploadActionsUnion = Load | Update | Delete;

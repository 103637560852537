import { UserMenuGardActionTypes, UserMenuGardActionsUnion } from '../actions/user_menu_gards.action';
import { UserMenuGards } from '../states/UserMenuGards';

import * as CryptoJS from 'crypto-js';

const initialState: UserMenuGards[] = [];

export function userMenuGardsReducer(state: UserMenuGards[] = initialState, action: UserMenuGardActionsUnion): UserMenuGards[] {
  let key = window.btoa('gfsys_user_menu_gards'), user_menu_gards;
  switch (action.type) {
    case UserMenuGardActionTypes.LOAD:
      user_menu_gards = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user_menu_gards);
      return initialState;
    case UserMenuGardActionTypes.UPDATE:
      user_menu_gards = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user_menu_gards);
      return action.payload;
    case UserMenuGardActionTypes.DELETE:
      localStorage.removeItem(key);
      return initialState;
    default:
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualInvoice: UserMenuGards[] = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualInvoice;
        }
      } else {
        user_menu_gards = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, user_menu_gards);
        return state;
      }
  }
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { UserWebsocket } from '../../states/UserWebsocket';
// import { Usuario } from '../classes/usuario';


@Injectable({
  providedIn: 'root'
})
export class WebsocketProvider {

  public socketStatus = false;
  public user_websocket$: Observable<UserWebsocket>;
  public user_websocket_state: UserWebsocket = null;

  constructor(
    // private socket: Socket,
    
  ) {
    
    // this.cargarStorage();
    this.checkStatus();
  }

  checkStatus() {

    // this.socket.on('connect', () => {
    //   console.log('Conectado al servidor');
    //   this.socketStatus = true;
    // });

    // this.socket.on('disconnect', () => {
    //   console.log('Desconectado del servidor');
    //   this.socketStatus = false;
    // });
  }


  

  listen( event: string ) {
    // return this.socket.fromEvent( event );
  }

  





}

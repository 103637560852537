import { BreadcrumbActionTypes, BreadcrumbActionsUnion } from '../actions/breadcrumb.action';
import { BreadcrumbState } from '../states/breadcrumb';

import * as CryptoJS from 'crypto-js';

const initialState: BreadcrumbState[] = [];

export function breadcrumbReducer (state: BreadcrumbState[] = initialState, action: BreadcrumbActionsUnion): BreadcrumbState[] {
  const key = window.btoa('gfsys_breadcrumb');
  let breadcrumb;
  switch (action.type) {
    case BreadcrumbActionTypes.LOAD:
      breadcrumb = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, breadcrumb);
      return state;
    case BreadcrumbActionTypes.UPDATE:
      breadcrumb = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, breadcrumb);
      return action.payload;
    default:
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          breadcrumb = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return breadcrumb;
        } else {
          return state;
        }
      } else {
        breadcrumb = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, breadcrumb);
        return state;
      }
  }
}

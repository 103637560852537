import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AccountingPeriodDetailEnterprise }  from '../../models/client/AccountingPeriodDetailEnterprise';
import { URLProvider } from '../url.providers';

@Injectable()
export class AccountingPeriodDetailEnterpriseProvider {

  constructor(
    private http: HttpClient,
    private urlprovider: URLProvider
  ) {}

  public allAccountingPeriodsDetailEnt( token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<AccountingPeriodDetailEnterprise[]>(this.urlprovider.accountingperioddetailenterprise() + '?' + token + '=' + value, this.urlprovider.httpOptions);
    } else {
      return this.http.get<AccountingPeriodDetailEnterprise[]>(this.urlprovider.accountingperioddetailenterprise(), this.urlprovider.httpOptions);
    }
  }

  public getAccountingPeriodDetailEnt( id: string) {
    return this.http.get<AccountingPeriodDetailEnterprise>(this.urlprovider.accountingperioddetailenterprise() + '/' + id, this.urlprovider.httpOptions);
  }

  public getAccountingPeriodDetailEntFilter( id_periodo: number, enterprise_id: number ) {
    return this.http.get<AccountingPeriodDetailEnterprise[]>(this.urlprovider.accountingperioddetailenterprise() + '?filter=true&accounting_period_01main_id='+id_periodo.toString()+'&enterprises_id='+ enterprise_id.toString(), this.urlprovider.httpOptions);
  }

  public insertAccountingPeriodDetailEnt( accounting_period_detail_ent: any) {
    return this.http.post<number>(this.urlprovider.accountingperioddetailenterprise(), accounting_period_detail_ent, this.urlprovider.httpOptions);
  }

  public updateAccountingPeriodDetailEnt( accounting_period_detail_ent: AccountingPeriodDetailEnterprise[]) {
    return this.http.put<Boolean>(this.urlprovider.accountingperioddetailenterprise() , accounting_period_detail_ent, this.urlprovider.httpOptions);
  }

  public updateAllPeriodEnterprise( status:any) {
    return this.http.put<Boolean>(this.urlprovider.accountingperioddetailenterprise()+'/all' ,status, this.urlprovider.httpOptions);
  }

  public getPeriodDetailEntValidate( branch_office_id: string, enterprises_id: string, date: string) {
    return this.http.get<any>(this.urlprovider.accountingperioddetailenterprise() + '/validate?branch_office_id=' + branch_office_id+'&enterprises_id='+enterprises_id+'&date='+date, this.urlprovider.httpOptions);
  }
  public getPeriodDetailEntValidatemonth( enterprises_id: string, date: string) {
    return this.http.get<any>(this.urlprovider.accountingperioddetailenterprise() + '/validatemonth?enterprises_id='+enterprises_id+'&date='+date, this.urlprovider.httpOptions);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BuyRetentionDetail } from '../../models/client/BuyRetentionDetail';
import { URLProvider } from '../url.providers';

@Injectable()
export class BuyRetentionElectronicalProvider {
//192.168.10.172/app/electronicalbuyretention/{dbname}/{access_key}
  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public mailRetentions(enterprise: string, email_detail: RetentionEmail[]) {
    return this.http.post<any>(this.urlProvider.electronicalbuyretention(enterprise) + '/sendmail' , email_detail, this.urlProvider.httpOptions);
  }

  public singRetentions(enterprise: string, email_detail: RetentionEmail[]) {
    return this.http.post<any>(this.urlProvider.electronicalbuyretention(enterprise) + '/sriauth' , email_detail, this.urlProvider.httpOptions);
  }

  public pdfRetention(enterprise: string, access_key: string) {
    return this.urlProvider.electronicalbuyretention(enterprise) + '/'+access_key;
  }

  public pdfRetentionID(enterprise: string, id: string) {
    return this.urlProvider.electronicalbuyretention(enterprise) + '/id/'+id;
  }

  public pdfRetentionHP(enterprise: string, access_key: string) {
    return this.urlProvider.electronicalbuyretention(enterprise) + '/hp/'+access_key;
  }

}

export interface RetentionEmail
{
  access_key    : string;
  email    : string;
}

import { Action } from '@ngrx/store';
import { EnterpriseUserCreate } from '../states/EnterpriseUserCreate';

export enum EnterpriseUserCreateActionTypes {
  LOAD = '[ENTERPRISEUSERCREATE] LOAD',
  UPDATE = '[ENTERPRISEUSERCREATE] UPDATE',
  DELETE = '[ENTERPRISEUSERCREATE] DELETE'
}

export class Load implements Action {
  readonly type = EnterpriseUserCreateActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = EnterpriseUserCreateActionTypes.UPDATE;
  constructor(public payload: EnterpriseUserCreate) {}
}

export class Delete implements Action {
  readonly type = EnterpriseUserCreateActionTypes.DELETE;
}

export type EnterpriseUserCreateActionsUnion = Load | Update | Delete ;

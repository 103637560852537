import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { AdditionalDefaultInfo } from '../../../models/client/AdditionalDefaultInfo';

@Component({
  selector: 'app-more-information-modal',
  templateUrl: './more-information.modal.html',
  styleUrls: ['./more-information.modal.scss'],
})

export class MoreInformationModal {
  public more_informations: AdditionalDefaultInfo[] = []
  public addtional_information: any = [];
  public form: FormGroup;
  public items: FormArray;

  constructor(
    public dialogRef: MatDialogRef<MoreInformationModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log('data', data);
    this.more_informations = data.more_informations;
    this.addtional_information = data.addtional_information;
    this.form = this.createForm();
    if (this.addtional_information.length > 0) {
      for (let i = 0; i < this.addtional_information.length; i++) {
        this.addItem(this.addtional_information[i]);
      }
    } else {
      for (let i = 0; i < this.more_informations.length; i++) {
        this.addItem({info1: '', info2: ''});
      }
    }
  }
  
  get formData() { return <FormArray>this.form.get('Data'); }
  
  createForm(): FormGroup {
    return this.formBuilder.group(
      {
        items: this.formBuilder.array([])
      }
    )
  }
  
  createItem(information: any): FormGroup {
    let info1: any = '';
    let info2: any = '';
    if (information.info2 !== '' || moment(information.info1, moment.ISO_8601, true).isValid()) {
      if ((moment(information.info1, moment.ISO_8601, true).isValid()) && information.info1.includes('-')) {
        info1 = new Date(Number(information.info1.split('-')[0]), Number(information.info1.split('-')[1]), Number(information.info1.split('-')[2]));
        info2 = new Date(Number(information.info2.split('-')[0]), Number(information.info2.split('-')[1]), Number(information.info2.split('-')[2]));
      } else {
        info1 = information.info1;
      }
    } else {
      info1 = information.info1;
    }
    return this.formBuilder.group(
      {
        info1: info1,
        info2: info2
      }
    );
  }
  
  addItem(information: any) {
    this.items = this.form.get('items') as FormArray;
    this.items.push(this.createItem(information));
  }
  
  acept(): void {
    const additional_information = [];
    for (let control of this.items.controls) {
      let info1 = '';
      let info2 = '';
      if (control.value.info2 !== '') {
        info2 = moment(control.value.info2).format('YYYY-MM-DD');
      }
      if (moment(control.value.info1, moment.ISO_8601, true).isValid()) {
        info1 = moment(control.value.info1).format('YYYY-MM-DD');
      } else {
        info1 = control.value.info1;
      }
      additional_information.push({info1: info1, info2: info2});
    }
    this.addtional_information = additional_information;
    console.log('additional_information', additional_information);
    this.dialogRef.close(this.addtional_information);
  }
  
  close(): void {
    this.dialogRef.close([]);
  }
}

import { UserActionTypes, UserActionsUnion } from '../actions/user.action';
import { UserState } from '../states/user';


import * as CryptoJS from 'crypto-js';

const initialState: UserState =
{
  user_account: null ,
  user_account_roles: null,
  user_role_permissions: null,
  user_role_selected: null,
  is_superadmin: true,
  refresh_redux: null
};

export function userReducer(state: UserState = initialState, action: UserActionsUnion): UserState {
  let key, user;
  switch (action.type) {
    case UserActionTypes.LOAD:
      key = window.btoa('gfsys_user');
      user = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user);
      return initialState;
    case UserActionTypes.UPDATE:
      key = window.btoa('gfsys_user');
      user = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user);
      return action.payload;
    case UserActionTypes.DELETE:
        key = window.btoa('gfsys_user');
        localStorage.removeItem(key);
        return initialState;
    default:
      key = window.btoa('gfsys_user');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualUser: UserState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualUser
        }
      } else {
        key = window.btoa('gfsys_user');
        user = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, user);
        return state;
      }
  }
}

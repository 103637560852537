import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PredCostCenterDet } from '../../models/client/PredCostCenterDet';
import { URLProvider } from '../url.providers';

@Injectable()
export class PredCostCenterDetProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allPredCostCenterDet(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<PredCostCenterDet[]>(this.urlProvider.pred_cost_center_det(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<PredCostCenterDet[]>(this.urlProvider.pred_cost_center_det(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getPredCostCenterDet(enterprise: string, id: string) {
    return this.http.get<PredCostCenterDet>(this.urlProvider.pred_cost_center_det(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertPredCostCenterDet(enterprise: string, pred_cost_center_det: PredCostCenterDet) {
    return this.http.post<number>(this.urlProvider.pred_cost_center_det(enterprise), pred_cost_center_det, this.urlProvider.httpOptions);
  }

  public updatePredCostCenterDet(enterprise: string, pred_cost_center_det: PredCostCenterDet, id: string) {
    return this.http.put<Boolean>(this.urlProvider.pred_cost_center_det(enterprise) + '/' + id, pred_cost_center_det, this.urlProvider.httpOptions);
  }

  public deletePredCostCenterDet(enterprise: string, id: number) {
    return this.http.delete<Boolean>(this.urlProvider.pred_cost_center_det(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

}

import { Injectable } from '@angular/core';
@Injectable()
export class DomainsProvider {
    //  public dominio = 'http://127.0.0.1:8000/';
     public dominio = 'https://app.gfsys.info/app/'
    //public dominio = 'https://app.gfsys.info/develop/'
    // public dominio = 'http://129.213.85.186/app/'
    // public dominio = 'http://127.0.0.1:8000/'
    // public dominio = 'https://app.gfsys.info/app/'
    public gfsys_service_dominio = 'http://app.gfsys.info:2900/';
    public getMainDomain() {
        return this.dominio;
    }
    public getGfsysServiceDomain() {
      return this.gfsys_service_dominio;
    }
}

import { Component, Inject, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { RetentionRecivedProvider } from '../../../providers/client/retention_recived.provider';

import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';

import { RetentionRecivedMain } from '../../../models/client/RetentionRecivedMain';

/* END Redux */

@Component({
  templateUrl: './invoice-has-retention.modal.html',
  styleUrls: ['./invoice-has-retention.modal.scss'],
})



export class InvoiceHasRetention implements AfterViewInit, OnDestroy {

  public showSpinner: boolean = false;
  public retention_recived: any;
  public doneCheckbox = false;
  private enterprise: string = '';
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();


    constructor(
      public dialogRef: MatDialogRef<InvoiceHasRetention>,
      private toastrService: ToastrService,
      private retentionRecivedService: RetentionRecivedProvider,
      // private productionOrderService: ProductionOrderMainProvider,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: {invoice_number: number, enterprise: string} 
    ) {
      
        
    }

    ngAfterViewInit(): void {
      console.log(this.data);
      this.searchText = null;
      // this.getProductionOrders('');
      this.getRetentionRecived(this.data.invoice_number)
    }



    getRetentionRecived(document_number) {
      this.retentionRecivedService.allRetentionReciveds(this.data.enterprise, 'invoice_number', document_number).pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: RetentionRecivedMain[])=> {
          if (data.length > 0) {
            this.retention_recived = data;
            console.log(data[0]);
            
          }
        }
      )
    } 

    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    getPaginatorData(event){
      console.log(event);
      if(event.pageIndex === this.pageIndex + 1){
         this.lowValue = this.lowValue + this.pageSize;
         this.highValue =  this.highValue + this.pageSize;
      }
      else if(event.pageIndex === this.pageIndex - 1){
        this.lowValue = this.lowValue - this.pageSize;
        this.highValue =  this.highValue - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  

}

import { Component, Inject, OnInit } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnterprisePlan } from 'src/app/models/client/EnterprisePlan';
import { EnterprisePlanProvider } from 'src/app/providers/client/enterprise_plan.provider';
import { PredCostCenterProvider } from 'src/app/providers/client/pred_cost_center.provider';
import { PredCostCenterDetProvider } from 'src/app/providers/client/pred_cost_center_det.provider';
import { CostCenterProvider } from 'src/app/providers/client/cost_center.provider';
import { AccountAssigmentDetailProvider } from 'src/app/providers/client/account_assigment_detail.provider';
import { EnterpriseState } from 'src/app/states/enterprise';
import { ReplaySubject, Subject } from 'rxjs';
import { PredCostCenter } from 'src/app/models/client/PredCostCenter';
import { PredCostCenterDet } from 'src/app/models/client/PredCostCenterDet';
import { CostCenter } from 'src/app/models/client/CostCenter';
import { ClientProvider } from 'src/app/models/client/ClientProvider';
import { ToastrService } from 'ngx-toastr';
import { AccountAssigmentDetail } from 'src/app/models/client/AccountAssigmentDetail';



@Component({
  selector: 'app-cin-ceg-other-payments-modal',
  templateUrl: './cin-ceg-other-payments.modal.html',
  styleUrls: ['./cin-ceg-other-payments.modal.scss'],
})

export class CinCegOtherPaymentsModal implements OnInit {

  public labelCheck: string = '';
  public searchFilterText: string = '';
  public searchText: string = '';
  public accounting_plan_level_5: EnterprisePlan[];
  public accounting_plan_combo: EnterprisePlan[];
  public filteredAccountants: ReplaySubject<EnterprisePlan[]> = new ReplaySubject<EnterprisePlan[]>(1);
  //public accounting_plan = null;
  protected _onDestroy = new Subject<void>();

  public pred_cost_center: PredCostCenter[];
  public cost_center_selected:PredCostCenter;
  public cost_centers: CostCenter[];
  public cost_centers_values = []
  public other_payments = {
    client: null,
    value: 0,
    motive: null,
    debe: null,
    haber: null,
    gnd: false,
    pay_by_trade: false,
    invertir: false,
    accounting_plan: {
      account_type: 0,
      type: null,
      id_code_accountant: null
    }
  }
  public cost_center_id:Number=0;


  public cost_centers_detail: PredCostCenterDet[];
  public other_payments_arr: any[] = []
  ccDetailColumns: string[] = ['cc', 'percent', 'total'];
  constructor(
    private _snackBar: MatSnackBar,
    private enterprisePlanService: EnterprisePlanProvider,
    private predCostCenterService: PredCostCenterProvider,
    private PredCostCenterDetService: PredCostCenterDetProvider,
    private costCenterService: CostCenterProvider,
    private toastrService: ToastrService,
    private accountAssigmentDetailService: AccountAssigmentDetailProvider,
    public dialogRef: MatDialogRef<CinCegOtherPaymentsModal>,


    @Inject(MAT_DIALOG_DATA) public data: { date: string, enterprise_state: EnterpriseState, type: string, clients_providers?: ClientProvider[], tip: boolean, faltantes: boolean,diference:number }
  ) {
    debugger
    this.getAccountingPlansLevel1('level', 5, 'date', this.data.date);
    this.getCostCenterPred();
    this.getCostCenters();
    this.validaPorcentajeCC = this.validaPorcentajeCC.bind(this);
    this.validaTotalCC = this.validaTotalCC.bind(this);
    if (this.data.clients_providers.length == 1) {
      this.other_payments.client = this.data.clients_providers[0].id
    }
    this.configLabels();
    if (this.data.faltantes) {
      this.getAcc()
    }
  }

  ngOnInit(): void {
    console.log(this.other_payments);

  }

  changeAcc(e:any){
    debugger;
    console.log(this.other_payments.accounting_plan);
    console.log(e,this.data.enterprise_state.configuration.cost_center,);
    if (this.cost_center_id!=0 && (this.other_payments.accounting_plan.account_type == 4 || this.other_payments.accounting_plan.account_type == 5 )) {
      this.cost_center_selected = this.pred_cost_center.find(element => element.id === this.cost_center_id);
      this.changeCostCenter(this.cost_center_id);
    }
  }

  filterMyOptions(e: string) {
    this._onDestroy;
    this.filterAccountants(e);
  }

  protected filterAccountants(client: any) {
    if (!this.accounting_plan_combo) {
      return;
    }
    let search = client;
    if (!search) {
      this.filteredAccountants.next(this.accounting_plan_combo.slice());
    }
    this.filteredAccountants.next(
      this.accounting_plan_combo.filter(
        plan =>
          plan.description.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          plan.id_code_accountant.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );

  }

  print(){
    console.log("esCC", this.data.enterprise_state.configuration.cost_center);

  }

  showBcoCta(e: any) {
    if (e.checked) {
      this.accounting_plan_combo = this.accounting_plan_level_5.filter(
        plan => ((plan.gfsys_account === 'BCO'))
      );
      this.filteredAccountants.next(
        this.accounting_plan_combo.filter(
          plan =>
            plan.description.toLowerCase().indexOf('') > -1 ||
            plan.id_code_accountant.toLowerCase().indexOf('') > -1

        )
      );
    } else {
      this.accounting_plan_combo = this.accounting_plan_level_5.filter(
        plan => ((plan.gfsys_account != 'CLI') && (plan.gfsys_account != 'PRO') && (plan.gfsys_account != 'BCO'))
      );
      this.filteredAccountants.next(
        this.accounting_plan_combo.filter(
          plan =>
            plan.description.toLowerCase().indexOf('') > -1 ||
            plan.id_code_accountant.toLowerCase().indexOf('') > -1

        )
      );
    }


  }

  getCostCenters() {
    this.costCenterService.allCostCenter(this.data.enterprise_state.entity.db_name).subscribe(
      (data: CostCenter[]) => {
        debugger;
        this.cost_centers = data;
      },
      error => {
        console.log('error', error)
      }
    )
  }

  configLabels() {
    if (this.data.type.toLowerCase() == 'ceg') {
      this.labelCheck = "Cambiar Crédito";
    } else {
      this.labelCheck = "Cambiar Débito";
    }
  }

  getCostCenterPred() {
    debugger;
    this.predCostCenterService.allPredCostCenter(this.data.enterprise_state.entity.db_name).subscribe(
      (data: PredCostCenter[]) => {
        debugger;
        this.pred_cost_center = data;
        console.log(this.pred_cost_center);

        const predet=this.pred_cost_center.filter(element=>element.active==true);
        if (predet.length>0) {
          this.cost_center_id=predet[0].id;
        }
      },
      error => {
        console.log('error', error)
      }
    )
  }

  changeCostCenter(id) {
    this.PredCostCenterDetService.allPredCostCenterDet(this.data.enterprise_state.entity.db_name, 'cost_center_predetermine_id', id).subscribe(
      (data: PredCostCenterDet[]) => {
        this.cost_centers_detail = data
        console.log("cc_deta", data)
        this.checkCostCenters(data)
      }

    )
  }

  checkCostCenters(data: PredCostCenterDet[]) {
    this.cost_centers_values = []
    for (let i = 0; i < data.length; i++) {
      let index_of_cost_center = this.cost_centers.findIndex(v => v.id == data[i].cost_center_id);

      if (index_of_cost_center >= 0) {
        let total = Number(data[i].pct_value) / 100 * Number(this.other_payments.value)
        if (this.data.type.toLowerCase() == 'cin') {
          this.cost_centers_values.push({
            cost_center_id: data[i].cost_center_id,
            pct_value: data[i].pct_value,
            debit: 0,
            haver: total,
            total: total
          })
        } else {
          this.cost_centers_values.push({
            cost_center_id: data[i].cost_center_id,
            pct_value: data[i].pct_value,
            debit: total,
            haver: 0,
            total: total
          })
        }

      }
    }
  }

  changeCostCenterPredAdded(id, current_other_payment: any) {
    this.PredCostCenterDetService.allPredCostCenterDet(this.data.enterprise_state.entity.db_name, 'cost_center_predetermine_id', id).subscribe(
      (data: PredCostCenterDet[]) => {
        console.log("dataPred", data)
        console.log("other_payments", current_other_payment)
        let current_total_value = Number(current_other_payment.other_payments.value)

        current_other_payment.cost_centers.splice(0)
        for (let i = 0; i < data.length; i++) {
          let index_of_cost_center = this.cost_centers.findIndex(v => v.id == data[i].cost_center_id);

          if (index_of_cost_center >= 0) {
            let total = Number(data[i].pct_value) / 100 * Number(current_total_value)
            if (this.data.type.toLowerCase() == 'cin') {
              current_other_payment.cost_centers.push({
                cost_center_id: data[i].cost_center_id,
                pct_value: data[i].pct_value,
                debit: 0,
                haver: total,
                total: total
              })
            } else {
              current_other_payment.cost_centers.push({
                cost_center_id: data[i].cost_center_id,
                pct_value: data[i].pct_value,
                debit: total,
                haver: 0,
                total: total
              })
            }

          }
        }



      }

    )
  }

  getAccountingPlansLevel1(token, value, token2, value2) {
    this.enterprisePlanService.allEnterprisePlans(this.data.enterprise_state.entity.db_name, token, value, token2, value2).subscribe(
      (data: EnterprisePlan[]) => {
        this.accounting_plan_level_5 = data;
        this.accounting_plan_combo = this.accounting_plan_level_5.filter(
          plan => ((plan.gfsys_account != 'CLI') && (plan.gfsys_account != 'PRO') && (plan.gfsys_account != 'BCO'))
        );
        if (this.data.tip) {
          this.accounting_plan_combo = this.accounting_plan_combo.filter(v => (v.description).toLowerCase().includes('propina'))
        }

        this.filteredAccountants.next(
          this.accounting_plan_combo.filter(
            plan =>
              plan.description.toLowerCase().indexOf('') > -1 ||
              plan.id_code_accountant.toLowerCase().indexOf('') > -1

          )
        );
      }
    )
  }
  public acc = [];


  getAcc() {
    this.accountAssigmentDetailService.allAccountAssigmentDetails(this.data.enterprise_state.entity.db_name, 'acc_id', '14').subscribe(
      (data: AccountAssigmentDetail[]) => {
        this.acc = data.filter(v => !v.locked)
        this.acc.forEach(element => {
          element.description = element.alias;
        });
      }
    )
  }



  validaPorcentajeCC(e) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (e.value >= 0) {
          const payment_total: number = 0;
          let new_cost_center_value = this.cost_centers_values.filter(v => v.cost_center_id != e.data.cost_center_id);
          let pct = 0
          for (let i = 0; i < new_cost_center_value.length; i++) {
            pct += Number(new_cost_center_value[i].pct_value)
          }
          pct = e.value + pct;
          if (pct > 100) {
            // alert('Error');
            this.showError('Error', 'Porcentaje acumulado mayor a 100');
            resolve('' === e.value);
          } else {
            resolve(e.value === e.value);
            let indexOf = this.cost_centers_values.findIndex(v => v.cost_center_id == e.data.cost_center_id);
            this.cost_centers_values[indexOf].total = Number(e.value) / 100 * Number(this.other_payments.value)
          }
        } else {
          resolve('' === e.value);
          this.showError('Error', 'No se permiten valores negativos');
          // alert('No se permiten valores negativos');
        }
      }, 500);
    });


  }

  validaTotalCC(e) {

    return new Promise((resolve) => {
      setTimeout(() => {
        if (e.value >= 0) {
          const payment_total: number = 0;
          let new_cost_center_values = this.cost_centers_values.filter(v => v.cost_center_id != e.data.cost_center_id);
          let pct = 0
          for (let i = 0; i < new_cost_center_values.length; i++) {
            pct += Number(new_cost_center_values[i].total)
          }
          pct = e.value + pct;
          if (pct > this.other_payments.value) {
            // alert('Error');
            this.showError('Error', 'Monto acumulado es superior al Valor');
            resolve('' === e.value);
          } else {
            resolve(e.value === e.value);
            let indexOf = this.cost_centers_values.findIndex(v => v.cost_center_id == e.data.cost_center_id);
            this.cost_centers_values[indexOf].pct_value = Number(e.value) * 100 / Number(this.other_payments.value)

          }
        } else {
          resolve('' === e.value);
          this.showError('Error', 'No se permiten valores negativos');
          // alert('No se permiten valores negativos');
        }
      }, 500);
    });
  }

  updateDebitCC(newData, value, currentRowData) {
    newData.debit = value
    newData.haver = 0
    newData.total = value
  }
  updateCreditCC(newData, value, currentRowData) {
    newData.debit = 0
    newData.haver = value
    newData.total = value
  }

  switchEvent(e: any) {

    if (this.data.type.toLowerCase() == 'cin') {
      if (e.key.other_payments.invertir === true) {
        e.key.other_payments.debe = e.key.other_payments.value;
        e.key.other_payments.haber = 0;
        e.key.cost_centers.forEach(cc => {
          cc.debit = cc.haver
          cc.haver = 0;
        });
      } else {
        e.key.other_payments.debe = 0;
        e.key.other_payments.haber = e.key.other_payments.value;
        e.key.cost_centers.forEach(cc => {
          cc.haver = cc.debit
          cc.debit = 0;
        });
      }
    } else {
      if (e.key.other_payments.invertir === true) {
        e.key.other_payments.debe = 0;
        e.key.other_payments.haber = e.key.other_payments.value;
        e.key.cost_centers.forEach(cc => {
          cc.haver = cc.debit
          cc.debit = 0;
        });
      } else {
        e.key.other_payments.debe = e.key.other_payments.value;
        e.key.other_payments.haber = 0;
        e.key.cost_centers.forEach(cc => {
          cc.debit = cc.haver
          cc.haver = 0;
        });
      }
    }
    console.log('Registro', e);
  }

  /**
   * Retorna true si es
   * @param data
   * @returns
   */
  isCin() {
    if (this.data.type.toLowerCase() == "cin") {
      return true;
    } else {
      return false;
    }

  }

  onCellPrepared(e) {

    if (!e.column.allowEditing && (e.columnIndex == 3 || e.columnIndex == 2) && e.rowType == 'data') {
      e.cellElement.className += 'blocked-cell'
    }
  }


  costCenterUpdating(event, data, e, debit = false) {
    if (event.value >= 0) {

    } else {
      this.showError('Error', 'No se permiten valores negativos');
      // alert('No se permiten valores negativos');
    }
    setTimeout(() => {

    }, 500);
  }

  add() {
    debugger
    console.log("other_payment_arr", this.other_payments_arr)
    let account_type = this.other_payments.accounting_plan.account_type
    console.log('OTHER PAYMENTS', this.other_payments)
    console.log()
    if (this.data.enterprise_state.configuration.cost_center =='SI' && (account_type == 5 || account_type == 4) && this.cost_centers_values.length == 0) {
      this.showError('Error en Centro de Costo', 'Error');
      return;
    }
    let cc_values_total = 0;
    this.cost_centers_values.forEach(cc => {
      if (this.data.type.toLowerCase() == "cin") {
        cc_values_total += cc.haver
      } else {
        cc_values_total += cc.debit
      }

    })
    console.log(this.other_payments.value, cc_values_total)

    if (this.data.enterprise_state.configuration.cost_center =='SI' && (account_type == 5 || account_type == 4) && Number(this.other_payments.value) != Number(cc_values_total.toFixed(2)) ) {
      this.showError('Error en Centro de Costo. Debe RECALCULAR los Centros de Costo', 'Error');
      return;
    }
    var client_aux = this.other_payments.client;

    if (Number(this.other_payments.value) > 0 && this.other_payments.client && this.other_payments.accounting_plan && this.other_payments.accounting_plan.id_code_accountant && this.other_payments.motive) {
      console.log("type", this.data.type.toLowerCase())
      if (this.data.type.toLowerCase() == 'cin') {
        if (this.data.faltantes) {
          if (this.other_payments.accounting_plan.type == 'INGRESO') {
            this.other_payments.debe = 0;
            this.other_payments.haber = this.other_payments.value;
          } else {
            this.other_payments.debe = this.other_payments.value;
            this.other_payments.haber = 0;
          }
        } else {
          this.other_payments.debe = 0;
          this.other_payments.haber = this.other_payments.value;
        }
        if (this.other_payments.client) {
          let other_payments = {
            other_payments: this.other_payments,
            cost_centers: this.cost_centers_values
          }
          if (this.cost_centers_values.length > 0) {
            const total_pct = this.cost_centers_values.map(t => (t.pct_value * 1)).reduce((acc, value) => ((acc + value) * 1), 0) * 1;
            if (total_pct == 100) {
              this.other_payments_arr.push(other_payments);
              this.other_payments = {
                client: client_aux,
                value: null,
                motive: null,
                gnd: false,
                pay_by_trade: false,
                debe: null,
                haber: null,
                invertir: false,
                accounting_plan: {
                  id_code_accountant: null,
                  account_type: null,
                  type: null
                }
              }
              this.cost_centers_values = []
            } else {
              this.showError('Error', 'No se completa el 100% en los centros de costo')
            }
          } else {
            other_payments.cost_centers = [];
            this.other_payments_arr.push(other_payments);
            this.other_payments = {
              value: null,
              client: client_aux,
              motive: null,
              gnd: false,
              pay_by_trade: false,
              debe: null,
              haber: null,
              invertir: false,
              accounting_plan: {
                id_code_accountant: null,
                account_type: null,
                type: null
              }
            }

          }
        } else {
          this.showError('Error', 'Todos los Campos son obligatorios')
        }
      } else {
        let indexOfClient = this.data.clients_providers.findIndex(v => v.id == client_aux);
        this.other_payments.client = this.data.clients_providers[indexOfClient].identification;
        this.other_payments.debe = this.other_payments.value;
        this.other_payments.haber = 0;
        let other_payments = {
          other_payments: this.other_payments,
          cost_centers: this.cost_centers_values
        }
        if (this.cost_centers_values.length > 0) {
          const total_pct = this.cost_centers_values.map(t => (t.pct_value * 1)).reduce((acc, value) => ((acc + value) * 1), 0) * 1;
          if (total_pct == 100) {
            this.other_payments_arr.push(other_payments);
            this.other_payments = {
              client: client_aux,
              value: null,
              motive: null,
              gnd: false,
              pay_by_trade: false,
              debe: null,
              haber: null,
              invertir: false,
              accounting_plan: {
                id_code_accountant: null,
                account_type: null,
                type: null
              }
            }
            this.cost_centers_values = []
          } else {
            this.showError('Error', 'No se completa el 100% en los centros de costo')
          }
        } else {
          other_payments.cost_centers = [];
          this.other_payments_arr.push(other_payments);
          this.other_payments = {
            value: null,
            client: client_aux,
            motive: null,
            gnd: false,
            pay_by_trade: false,
            debe: null,
            haber: null,
            invertir: false,
            accounting_plan: {
              id_code_accountant: null,
              account_type: null,
              type: null
            }
          }
        }
      }
    } else {
      this.showError('Error', 'Todos los Campos son obligatorios')
    }
    console.log("otherPayArr", this.other_payments_arr)
    console.log("otherPay", this.other_payments)

  }


  accountType(item) {
    let account = this.accounting_plan_level_5.find(v => v.id_code_accountant == item.id_code_accountant);
    if (account && account.account_type == "5") {
      return false;
    } else {
      item.gnd = false
      item.daily_accountan_seat03_cost_center = []
      return true;
    }
  }

  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 10000
    });
  }

  showError(error, mensaje) {
    this.toastrService.error(`${mensaje}`, `${error}`, {
      timeOut: 10000,
      positionClass: 'toast-bottom-center'
    });
  }

  showSuccess(success, mensaje) {
    this.toastrService.success(success, `Mensaje: ${mensaje}`, {
      timeOut: 13000,
      positionClass: 'toast-bottom-center'
    });
  }

  close(): void {
    console.log("arr", this.other_payments_arr)
    let formatted_other_payments = []
    // this.other_payments_arr.forEach(op => {
    //   op.cost_centers.array.forEach(cc => {
    //     let new_cc = {
    //       //aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaahhh
    //     }
    // //     let other_payments = {
    //       other_payments: op.other_payments,
    //       cost_centers: new_cc
    //     }
    //     formatted_other_payments.push(other_payments)
    //   });
    // })
    this.dialogRef.close(this.other_payments_arr);
  }
}

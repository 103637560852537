import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {  UserRolePermision  } from '../../models/client/UserRolePermision';
import { URLProvider } from '../url.providers';

@Injectable()
export class UserRolePermisionProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allUserRolePermisions(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<UserRolePermision[]>(this.urlProvider.user_role_permision(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<UserRolePermision[]>(this.urlProvider.user_role_permision(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getUserRolePermision(enterprise: string, id: string) {
    return this.http.get<UserRolePermision>(this.urlProvider.user_role_permision(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getSupervisionRolePermision(enterprise: string, id: string, email:string) {
    return this.http.get<any>(this.urlProvider.user_role_permision(enterprise) + '/supervision/' + id+'/'+email, this.urlProvider.httpOptions);
  }

  public insertUserRolePermision(enterprise: string, user_role_permision: UserRolePermision) {
    return this.http.post<Boolean>(this.urlProvider.user_role_permision(enterprise), user_role_permision, this.urlProvider.httpOptions);
  }

  public updateUserRolePermision(enterprise: string, user_role_permision: UserRolePermision, id: string) {
    return this.http.put<Boolean>(this.urlProvider.user_role_permision(enterprise) + '/' + id, user_role_permision, this.urlProvider.httpOptions);
  }
  public deleteUserRolePermision(enterprise: string, id: string) {
    return this.http.delete<Boolean>(this.urlProvider.user_role_permision(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

}

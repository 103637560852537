import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { InvoicePaymentMethod } from '../../models/client/InvoicePaymentMethod';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class InvoicePaymentMethodProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allInvoicesPaymentMethod(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<InvoicePaymentMethod[]>(this.urlProvider.invoice_payment_method(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<InvoicePaymentMethod[]>(this.urlProvider.invoice_payment_method(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getInvoicePaymentMethod(enterprise: string, id: string) {
    return this.http.get<InvoicePaymentMethod>(this.urlProvider.invoice_payment_method(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public allViewInvoicePaymentMethod(enterprise: string, value: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.invoice_payment_method(enterprise) + '/view?'+ value, this.urlProvider.httpOptions);
   
  }

  public getInvoicePaymentMethodNextPreviousPage(url, filter = '') {
    return this.http.get<ViewPaginator>(url+'&'+ filter, this.urlProvider.httpOptions);
  }

  public insertInvoicePaymentMethod(enterprise: string, invoice_payment_method: InvoicePaymentMethod) {
    return this.http.post<number>(this.urlProvider.invoice_payment_method(enterprise), invoice_payment_method, this.urlProvider.httpOptions);
  }

  public insertLotInvoicePaymentMethod(enterprise: string, invoice_payment_method: InvoicePaymentMethod[]) {
    return this.http.post<Boolean>(this.urlProvider.invoice_payment_method(enterprise) + '/lot', invoice_payment_method, this.urlProvider.httpOptions);
  }

  public updateInvoicePaymentMethod(enterprise: string, invoice_payment_method: InvoicePaymentMethod, id: string) {
    return this.http.put<Boolean>(this.urlProvider.invoice_payment_method(enterprise) + '/' + id, invoice_payment_method, this.urlProvider.httpOptions);
  }
  public getInvoicePaymentMethodMasive(enterprise: string, invoice_payment_method:any,type:string) {
    return this.http.put<any>(this.urlProvider.invoice_payment_method(enterprise) + '/masive/validate?type='+type , invoice_payment_method, this.urlProvider.httpOptions);
  }

  public deleteInvoicePaymentMethod(enterprise: string, id: string) {
    return this.http.delete<Boolean>(this.urlProvider.invoice_payment_method(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }
}

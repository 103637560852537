import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IncomePaymentProofMain, IncomePaymentProofComplete, PendingVouchers, ClientsSelected, GetIncomePaymentProof } from '../../models/client/IncomePaymentProofMain';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';

@Injectable()
export class IncomePaymentProofMainProvider {

  constructor(
    private urlProvider: URLProvider,
    private http: HttpClient
  ) {}

  public allIncomePaymentProof(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<IncomePaymentProofMain[]>(this.urlProvider.incomepaymentproof(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<IncomePaymentProofMain[]>(this.urlProvider.incomepaymentproof(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allIncomePaymentProofView(enterprise: string, token: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.incomepaymentproof(enterprise)+'/view' +  token , this.urlProvider.httpOptions);
  }

  public getIncomePaymentProofNextPreviousPage(url, per_page='') {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }
  public listIncomePaymentProof(enterprise: string, start_date: string = '', end_date: string = '') {
    if (start_date !== '' && end_date !== '') {
      return this.http.get<IncomePaymentProofMain[]>(this.urlProvider.incomepaymentproof(enterprise) + '/list?start_date=' + start_date + '&end_date=' + end_date, this.urlProvider.httpOptions);
    } else {
      return this.http.get<IncomePaymentProofMain[]>(this.urlProvider.incomepaymentproof(enterprise) + '/list', this.urlProvider.httpOptions);
    }
  }

  public getIncomePaymentProof(enterprise: string, id: number) {
    return this.http.get<GetIncomePaymentProof>(this.urlProvider.incomepaymentproof(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getIncomePaymentProofItems(enterprise: string, id: number) {
    return this.http.get<any>(this.urlProvider.incomepaymentproof(enterprise) + '/02item/' + id, this.urlProvider.httpOptions);
  }

  public pdfPaymentProof(enterprise: string, type: string,id: string) {
    return this.urlProvider.pdfpaymentproof(enterprise)+ '/' + type+ '/' + id;
  }

  public pdfPaymentProofTirilla(enterprise: string, id: string) {
    return this.urlProvider.incomepaymentproof(enterprise)+ '/pdf/tirilla/' + id;
  }
  public downloadExcelMasiveVouchersComissionRetention(enterprise: string) {
    return this.urlProvider.incomepaymentproof(enterprise)+ '/vouchers/excel';
  }

  public loadExcelMasiveVouchersComissionRetention(enterprise: string, data: any[]) {
    return this.http.post<any[]>(this.urlProvider.incomepaymentproof(enterprise) + '/vouchers/excel', data, this.urlProvider.httpOptions);
  }
  public findVouchersType(enterprise: string) {
    return this.http.get<any[]>(this.urlProvider.incomepaymentproof(enterprise) + '/load-data/types', this.urlProvider.httpOptions);
  }
  public LoaddownloadExcelMasiveVouchersComissionRetention(enterprise: string, data: any[], filter = '') {
    return this.http.post<any[]>(this.urlProvider.incomepaymentproof(enterprise) + '/load-data/download-excel'+filter, data, this.urlProvider.httpOptions);
  }

  public domainUrl() {
    return this.urlProvider.dominio;
  }

  public getSequential(enterprise: string, branch_office_code: string, stablishment_code: string, project_name: string) {
    return this.http.get<string>(this.urlProvider.incomepaymentproofaction(enterprise) + '/sequential?branch_office_code=' + branch_office_code + '&stablishment_code=' + stablishment_code + '&project_name=' + project_name, this.urlProvider.httpOptions);
  }

  public getInvoiceByClients(enterprise: string, clients_selected: ClientsSelected[]) {
    return this.http.post<PendingVouchers[]>(this.urlProvider.incomepaymentproofaction(enterprise) + '/invoices', clients_selected, this.urlProvider.httpOptions);
  }
  public getRedimirByClientsRetention(enterprise: string, identification:string, start_date: string, end_date: string) {
    return this.http.get<any>(this.urlProvider.incomepaymentproofaction(enterprise) + '/redimirRetention?identification=' + identification + '&start_date=' + start_date + '&end_date=' + end_date, this.urlProvider.httpOptions);
  }

  public getInvoiceByClientsPaginate(enterprise: string, clients_selected: ClientsSelected[], filter = '') {
    return this.http.post<any>(this.urlProvider.incomepaymentproofaction(enterprise) + '/invoices/paginate' + '?' + filter, clients_selected, this.urlProvider.httpOptions);
  }

  public getInvoiceByClientsPaginateNextPreviousPage(url, data) {
    return this.http.post<any>(url, data, this.urlProvider.httpOptions);
  }

  public getRedimirByClients(enterprise: string, clients_selected: ClientsSelected[]) {
    return this.http.post<any[]>(this.urlProvider.incomepaymentproofaction(enterprise) + '/redimir', clients_selected, this.urlProvider.httpOptions);
  }

  public getRedimirRetentionByClients(enterprise: string, identification: string, start_date: string, end_date: string) {
    return this.http.get<any[]>(this.urlProvider.incomepaymentproofaction(enterprise) + '/redimirRetention?identification=' + identification + '&start_date=' + start_date + '&end_date=' + end_date , this.urlProvider.httpOptions);
  }

  public insertIncomePaymentProof(enterprise: string, income_payment_proof: IncomePaymentProofComplete) {
    return this.http.post<boolean>(this.urlProvider.incomepaymentproof(enterprise), income_payment_proof, this.urlProvider.httpOptions);
  }

  public insertThirdPartyIncome(enterprise: string, third: any) {
    return this.http.post<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/third', third, this.urlProvider.httpOptions);
  }
  public getThirdPartyIncome(enterprise: string) {
    return this.http.get<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/third/get', this.urlProvider.httpOptions);
  }
  
  public getReportGndLedger(enterprise: string,start_date:any,end_date:any,report:any) {
    return this.http.get<any>(this.urlProvider.incomepaymentproof(enterprise)+'/gnd/report/ledger?start_date='+start_date+'&end_date='+end_date+'&report='+report, this.urlProvider.httpOptions);
  }
  public getCinThirdPartyIncome(enterprise: string, third: any,date:any) {
    return this.http.put<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/cinthird/get?end_date='+date, third, this.urlProvider.httpOptions);
  }

  public getCinThirdPartyIncomePay(enterprise: string, third: any,date:any) {
    return this.http.put<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/get/thirdpay?end_date='+date, third, this.urlProvider.httpOptions);
  }

  public deleteThirdPartyIncome(enterprise: string, id: any) {
    return this.http.delete<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/third?id='+id, this.urlProvider.httpOptions);
  }

  public updateIncomePaymentProof(enterprise: string, income_payment_proof: IncomePaymentProofComplete, id: any) {
    return this.http.put<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/'+id, income_payment_proof, this.urlProvider.httpOptions);
  }

  public deleteIncomePaymentProof(enterprise: string, id: any, updated_by: any,) {
    return this.http.put<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/delete/'+id, updated_by, this.urlProvider.httpOptions);
  }

  public returnIncomePaymentProof(enterprise: string, id: any, updated_by: any,) {
    return this.http.put<boolean>(this.urlProvider.incomepaymentproof(enterprise)+'/returned/'+id, updated_by, this.urlProvider.httpOptions);
  }

  public getIncomePaymentProofReport(enterprise: string, filters = "") {
    return this.http.get<any>(this.urlProvider.incomepaymentproofreports(enterprise) + '?' + filters, this.urlProvider.httpOptions);
  }

  public getAdvanceReport(enterprise: string, filters = "") {
    return this.http.get<any>(this.urlProvider.incomepaymentproofreports(enterprise) + '/advance?' + filters, this.urlProvider.httpOptions);
  }

  public getAdvanceReportPdf(enterprise: string, filters = "") {
    return this.urlProvider.incomepaymentproofreports(enterprise)+ '/advance/pdf?' + filters;
  }

  public getIncomePaymentProofReportPdf(enterprise: string, filters = "") {
    return this.urlProvider.incomepaymentproofreports(enterprise)+ '/pdf?' + filters;
  }

  public updateValidatedIncomePaymentProof(enterprise: string, id, validate_cin) {
    return this.http.put<any>(this.urlProvider.incomepaymentproof(enterprise) + '/validated/' + id , validate_cin, this.urlProvider.httpOptions);
  }

  public updateValidatedPaymentMethod(enterprise: string, id, validate_cin) {
    return this.http.put<any>(this.urlProvider.incomepaymentproof(enterprise) + '/validatedvoucher/' + id , validate_cin, this.urlProvider.httpOptions);
  }

}

import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-cancel-by-batch-modal',
  templateUrl: './cancel-by-batch.modal.html',
  styleUrls: ['./cancel-by-batch.modal.scss'],
})

export class CancelByBatchModal {
  public doneCheckbox = false
  public motivo: string;

    constructor(
      public dialogRef: MatDialogRef<CancelByBatchModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {}
    ngOnInit(): void {
      console.log('CHECK',this.data);

    //  this.checkInput()

    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input-cancel');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
      }
      this.checkInput()
    }

    checkInput() {
      debugger;

      let checkboxes: any = document.getElementsByClassName('mat-checkbox-input-cancel')
    //  let inputmotivo: any = document.getElementsByClassName('mat-input')
      let cancelButton: any = document.getElementsByClassName('cancel')
      this.doneCheckbox = false
     /* if (`${inputmotivo[0].value}` == "") {
        for (let x = 0; x < cancelButton.length; x++) {
          cancelButton[x].disabled = true
        }

      } else {
        for (let x = 0; x < cancelButton.length; x++) {
          cancelButton[x].disabled = false
        }
      }*/


    }


}

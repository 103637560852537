import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User, EnterpriseAdminUser } from '../../models/admin/User';
import { URLProvider } from '../url.providers';

@Injectable()
export class UserProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allUsers(token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<User[]>(this.urlProvider.user() + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<User[]>(this.urlProvider.user(), this.urlProvider.httpOptions);
    }
  }

  public getEnterpriseUserAdmin(enterprise_id: number) {
    return this.http.get<EnterpriseAdminUser>(this.urlProvider.user() + '/useradmin/' + enterprise_id, this.urlProvider.httpOptions);
  }

  public getAllEnterpriseUser(enterprise_id: number) {
    return this.http.get<any>(this.urlProvider.user() + '/userenterprise/' + enterprise_id, this.urlProvider.httpOptions);
  }

  public getUser(id: string) {
    return this.http.get<User>(this.urlProvider.user() + '/' + id, this.urlProvider.httpOptions);
  }

  public isSuperAdmin(email: string) {
    return this.http.get<boolean>(this.urlProvider.user() + '?superadmin=' + email, this.urlProvider.httpOptions);
  }

  public insertUser(user: User) {
    return this.http.post<number>(this.urlProvider.user(), user, this.urlProvider.httpOptions);
  }

  public updateUser(user: User, id: string) {
    return this.http.put<Boolean>(this.urlProvider.user() + '/' + id, user, this.urlProvider.httpOptions);
  }

}

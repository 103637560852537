import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AjeGroupProvider } from 'src/app/providers/client/aje_group.provider';
import { EnterpriseState } from 'src/app/states/enterprise';
import { Utilities } from 'src/app/utils/utilities';

@Component({
  selector: 'app-aje-group',
  templateUrl: './aje-group.component.html',
  styleUrls: ['./aje-group.component.scss']
})
export class AjeGroupComponent implements OnInit {
  showcreateMov = false
  showSendMov = false
  showsearchPendings = false
  showList =true
  subMenuTitle = 'Lista de Opciones'
  enterprise : string
  enterprise$: Observable<EnterpriseState>
  results = []
  shippableList = []
  percentajeAdvance = 0
  _onDestroy = new Subject<void>();
  paramsToMdwForm = new FormGroup({
    documentId: new FormControl(''),
    documentType: new FormControl(''),
    date: new FormControl('')
  })
  executingProcess = false
 

  constructor(
    private ajeGroupService: AjeGroupProvider, 
    public utilities:Utilities,
    private enterpriseStore: Store<{enterprise: EnterpriseState}>,
    private router: Router
  ) {
      this.enterprise$ = enterpriseStore.pipe(select('enterprise'));
  }

  ngOnInit() {
    this.getEnterpriseRedux()
  }

  getEnterpriseRedux() {
    this.enterprise$.pipe(takeUntil(this._onDestroy)).subscribe(
      (data: EnterpriseState) => {
      

        if (data.entity === null) {
          this.utilities.showNotification('Debe ininciar sesión y poseer permisos para acceder a esta vista');
          this.router.navigate(['/auth/login']);
        } else {
          this.enterprise = data.entity.db_name;
        }
      },
      error => {
        console.log('error getEnterpriseConfiguration', error);
      }
    );
  }


  changeView(optionToShow){
    this.showcreateMov = false
    this.showSendMov = false
    this.showsearchPendings = false
    this.showList = false
    switch (optionToShow) {
      case 'create-mov':
        this.showcreateMov = true
        this.subMenuTitle = 'Crear Movimeinto de Inventario'
        break;
      case 'send-mov':
        this.showSendMov = true
        this.subMenuTitle = 'Enviar Movimeinto de Inventario a Middleware'
        break;
      case 'search-pending':
        this.showsearchPendings = false
        this.subMenuTitle = 'Buscar Pendientes'
        break;
      case 'list':
        this.showList = true
        break;
      default:
        this.showList = true
        break;
    }
  }


  executeProcess(){
    if(this.showcreateMov){
      this.createMovementstoMDW()
    } else if(this.showSendMov){
      this.sendMovementstoMDW()
    } else if (this.showsearchPendings){

    }
  }


  createMovementstoMDW(){
    this.executingProcess = true
    let body = {
      document_id : this.paramsToMdwForm.value.documentId,
      document_type : this.paramsToMdwForm.value.documentType,
      date : this.paramsToMdwForm.value.date,
    }
    console.log(this.paramsToMdwForm)
    console.log(body)
    console.log(this.enterprise)
    
    this.ajeGroupService.createMovementsToMDW(this.enterprise, body).subscribe(
      (data: any) => {
        this.results.push(data)
        console.log("data response", data)
        console.log("result", this.results)
        this.executingProcess = false
      },
      error => {
        this.utilities.showError(error.error.response, 'Error al crear Movimientos para MDW')
        this.executingProcess = false
        
      }
    )
  }

  async sendMovementstoMDW(){
    
    console.log(this.paramsToMdwForm)
    let counterAdvance = 0
    this.percentajeAdvance = 0
    let sessionId = this.getSessionId().toString()
    console.log("lengh", this.shippableList.length)
    this.executingProcess = true
    for(let i = 0; i < this.shippableList.length ;i++){
      
      let params = 'document_id=' + this.shippableList[i].documentId
      params += '&document_type=' + this.shippableList[i].documentType
      params += '&session_id=' + sessionId
      console.log(sessionId, this.getSessionId())
      // await this.ajeGroupService.sendMovementsToMDW(this.enterprise,params).subscribe(
      //   (data: any) => {
      //     this.results.push(data)
      //     console.log("data response", data)
      //     console.log("result", this.results)
      //   },
      //   error => {
      //     this.utilities.showError(error.error.response, 'Error al crear Movimientos para MDW')
          
      //   }
        
      // )
      try {
        let data = await this.ajeGroupService.sendMovementsToMDW(this.enterprise,params).toPromise()
        this.results.push(data)
        console.log("data response", data)
        console.log("result", this.results)
      } catch (error) {
        console.error(error)
        this.utilities.showError(error.error.response, 'Error al crear Movimientos para MDW');
      }
      debugger
      counterAdvance += 1
      this.percentajeAdvance = (counterAdvance * 100) / this.shippableList.length
    }
    this.executingProcess = false
    return
    
    
  }

  getSessionId(): string {
    const now: Date = new Date();
  
    const year: string = now.getFullYear().toString();
    const month: string = (now.getMonth() + 1).toString().padStart(2, '0');
    const day: string = now.getDate().toString().padStart(2, '0');
    const hours: string = now.getHours().toString().padStart(2, '0');
    const minutes: string = now.getMinutes().toString().padStart(2, '0');
    const seconds: string = now.getSeconds().toString().padStart(2, '0');
  
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  addToShipableList(){
    let newShippable = {
      documentId : this.paramsToMdwForm.value.documentId,
      documentType : this.paramsToMdwForm.value.documentType,
    }
    
    this.shippableList.push(newShippable)
    console.log("se agregó a lista de envio:", newShippable)


  }

  deleteItemShippable(index: number) {
    this.shippableList.splice(index, 1);
  }


  
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { NoRegisterLayoutComponent } from './layouts/no-register/no-register-layout.component';
import { AjeGroupComponent } from './external-integrations/aje-group/aje-group.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  }, {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: './auth/login/login.module#LoginModule'
      }, {
        path: 'register',
        loadChildren: './auth/register/register.module#RegisterModule'
      },
      {
        path: 'recovery/:token',
        loadChildren: './auth/reset-password/reset-password.module#ResetPasswordModule'
      }
    ]
  },
  {
    path: '',
    component: NoRegisterLayoutComponent,
    children: [
      {
        path: 'enterprise/:create',
        loadChildren: './enterprise/admin-enterprise.module#AdminEnterpriseModule'
      },
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'currency',
        loadChildren: './currency/currency.module#CurrenyModule'
      }, {
        path: 'country',
        loadChildren: './country/country.module#CountryModule'
      }, {
        path: 'custom-district',
        loadChildren: './custom_district/custom-district.module#CustomDistrictModule'
      }, {
        path: 'credit-card',
        loadChildren: './credit-card/credit-card.module#CreditCardModule'
      }, {
        path: 'voucher-type',
        loadChildren: './voucher-type/voucher-type.module#VoucherTypeModule'
      },
      {
        path: 'regime',
        loadChildren: './regimes/regimes.module#RegimesModule'
      }, {
        path: 'enterprise',
        loadChildren: './enterprise/admin-enterprise.module#AdminEnterpriseModule'
      },
      {
        path: 'enterprise/update/:id',
        loadChildren: './enterprise/admin-enterprise.module#AdminEnterpriseModule'
      },
       {
        path: 'company-groups',
        loadChildren: './company-groups/company-groups.module#CompanyGroupsModule'
      },
      {
        path: 'users-websocket',
        loadChildren: './users-websockets-connection/users-websockets-connection.module#UsersWebsocketConnectionModule'
      },
       {
        path: 'admin',
        children: [
          {
            path: 'dashboard',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
          },
          {
            path: 'dashboard/:_id',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
          },
          {
            path: 'notifications',
            loadChildren: './notifications/notifications.module#NotificationsModule'
          },
          {
            path: 'parameters',
            children: [
              {
                path: '',
                loadChildren: './parameters/stores/stores.module#StoresModule'
              },
              {
                path: 'transaction-type',
                loadChildren: './transaction-type/transaction-type.module#TransactionTypeModule'
              },
              {
                path: 'upload-signatures',
                loadChildren: './upload-signatures/upload-signatures.module#UploadSignaturesModule'
              },
              {
                path: 'payment-method',
                loadChildren: './payment-method/payment-method.module#PaymentMethodModule'
              }, {
                path: 'user',
                children: [
                  {
                    path: 'role',
                    loadChildren: './role/role.module#RoleModule'
                  },
                  {
                    path: 'user-account',
                    loadChildren: './user-account/user-account.module#UserAccountModule'
                  },

                ]
              }, {
                path: 'cash-register',
                children: [
                  {
                    path: 'new',
                    loadChildren: './cash-register/new/new-cash-register.module#NewCashRegisterModule'
                  },
                  {
                    path: 'update/:id',
                    loadChildren: './cash-register/update/update-cash-register.module#UpdateCashRegisterModule'
                  },
                  {
                    path: 'open/:id',
                    loadChildren: './cash-register/open/open-cash-register.module#OpenCashRegisterModule'
                  },
                  {
                    path: 'reports/:id',
                    loadChildren: './cash-register/reports/reports-cash-register.module#ReportsCashRegisterModule'
                  },
                  {
                    path: 'close/:id/:idmov',
                    loadChildren: './cash-register/close/close-cash-register.module#CloseCashRegisterModule'
                  },
                  {
                    path: 'close/:id/:idmov/:accion',
                    loadChildren: './cash-register/close/close-cash-register.module#CloseCashRegisterModule'
                  },
                  {
                    path: 'movements/:id/:move',
                    loadChildren: './cash-register/movements/movements-cash-register.module#MovementsCashRegisterModule'
                  },
                  {
                    path: 'list',
                    loadChildren: './cash-register/list/list-cash-register.module#ListCashRegisterModule'
                  },
                  {
                    path: 'management',
                    loadChildren: './cash-register/management/management-cash-register.module#ManagementCashRegisterModule'
                  }
                ]
              },{
                path: 'branch-office',
                children: [
                  {
                    path: 'branch-office-enterprise',
                    loadChildren: './branch-office-enterprise/branch-office-enterprise.module#BranchOfficeEnterpriseModule'
                  }, {
                    path: 'branch-office',
                    loadChildren: './branch-office/branch-office.module#BranchOfficeModule'
                  }
                ]
              }, {
                path: 'additional-params',
                loadChildren: './additional-default-info/additional-default-info.module#AdditionalDefaultInfoModule'
              }, {
                path: 'additional-params-pos',
                loadChildren: './additional-default-info-POS/additional-default-info-POS.module#AdditionalDefaultInfoPOSModule'
              }, {
                path: 'more-information',
                loadChildren: './more-information/more-information.module#MoreInformationModule'
              }, {
                path: 'new-bank-reconciliation',
                loadChildren: './bank-reconciliation/new/new-bank-reconciliation.module#NewBankReconciliationModule'
              },
              {
                path: 'edit-bank-reconciliation/:id',
                loadChildren: './bank-reconciliation/new/new-bank-reconciliation.module#NewBankReconciliationModule'
              },
              {
                path: 'bank-reconciliation',
                loadChildren: './bank-reconciliation/bank-reconciliation.module#BankReconciliationModule'
              } ,{
                path: 'accounting-periods',
                loadChildren: './accounting-periods/accounting-periods.module#AccountingPeriodsModule'
              }, {
                path: 'account-assignment',
                loadChildren: './account-assignment/account-assigment.module#AccountAssigmentModule'
              }, {
                path: 'emission-point',
                loadChildren: './emission-point/emission-point.module#EmissionPointModule'
              }, {
                path: 'sequential-document',
                loadChildren: './sequential-document/sequential-document.module#SequentialDocumentModule'
              },
              {
                path:'general-config',
                loadChildren:'./general-config/general-config.module#GeneralConfigModule'
              },
              {
                path: 'cost-center',
                loadChildren: './cost-center/cost-center.module#CostCenterModule'
              }, {
                path: 'predetermine-cost-center',
                loadChildren: './predetermine-cost-center/predetermine-cost-center.module#PredetermineCostCenterModule'
              },
              {
                path: 'accounting-plan',
                loadChildren: './accounting-plan/accounting-plan.module#AccountingPlanModule'
              },
              {
                path: 'code-structure',
                children: [
                  {
                    path: 'list',
                    loadChildren: './code-structure/code-structure.module#CodeStructureModule'
                  },
                ]
              },

              {
                path: 'payment-channels',
                children: [
                  {
                    path: '',
                    loadChildren: './payment-channels/payment-channels.module#PaymentChannelsModule'
                  },
                  {
                    path: 'cards-comissions',
                    loadChildren: './cards-comissions/cards-comissions.module#CardsComissionsModule'
                  },
                ]
              },
              {
                path: 'sri-automatization-admin',
                loadChildren: './sri-credentials-admin/sri-credentials-admin.module#SriCredentialsAdminModule'
              },
              {
                path: 'sri-automatization-superadmin',
                loadChildren: './sri-credentials-superadmin/sri-credentials-superadmin.module#SriCredentialsSuperadminModule'
              },
              {
                path: 'enterprise-automatic-sriauth',
                loadChildren: './enterprise-automatic-sriauth/enterprise-automatic-sriauth.module#EnterpriseAutomaticSriauthModule'
              },
              {
                path: 'confice',
                loadChildren: './iva/iva.module#IvaModule'
              },
              {
                path: 'ice',
                loadChildren: './ice/ice.module#IceModule'
              },
              // {
              //   path: 'tax-administration',
              //   loadChildren: './tax-administration/tax-administration.module#TaxAdministrationModule'
              // },
              {
                path: 'expiration-configuration',
                loadChildren: './expiration-report-configuration/expiration-report-configuration.module#ExpirationReportConfigurationModule'
              },
            ]
          }, {
            path: 'BD',
            children: [
              {
                path: 'client',
                children: [
                  {
                    path: 'client-provider-type',
                    loadChildren: './client-provider-type/client-provider-type.module#ClientProviderTypeModule'
                  }, {
                    path: 'client-provider',
                    loadChildren: './client-provider/client-provider.module#ClientProviderModule'
                  },
                  {
                    path: 'client-provider-destinations',
                    loadChildren: './client-provider-destinations/client-provider-destinations.module#ClientProviderDestinationsModule'
                  },
                ]
              }, {
                path: 'seller',
                loadChildren: './seller/seller.module#SellerModule'
              },
              {
                path: 'vehicle',
                loadChildren: './vehicle/vehicle.module#VehicleModule'
              },
              {
                path: 'driver',
                loadChildren: './driver/driver.module#DriverModule'
              },
              {
                path: 'initial-charges',
                loadChildren: './initial-charges/initial-charges.module#InitialCharges'
              },
            ]
          }, {
            path: 'inventory',
            children: [
              {
                path: 'warehouse',
                loadChildren: './wharehouse/wharehouse.module#WharehouseModule'
              }, {
                path: 'product',
                children: [
                  {
                    path: 'category',
                    loadChildren: './product-category/product-category.module#ProductCategoryModule'
                  },
                  {
                    path: 'filter',
                    loadChildren: './product-filter/product-filter.module#ProductFilterModule'
                  },
                  {
                    path: 'product',
                    loadChildren: './product/product.module#ProductModule'
                  },
                  {
                    path: 'product-combo',
                    loadChildren: './product-combo/product-combo.module#ProductComboModule'
                  },
                  {
                    path: 'made-internally',
                    loadChildren: './product-made-internally/product-made-internally.module#ProductMadeInternallyModule'
                  },
                  {
                    path: 'made-internally/report',
                    loadChildren: './product-made-internally-report/product-made-internally-report.module#ProductMadeInternallyReportModule'
                  },
                  {
                    path: 'piece',
                    loadChildren: './product-piece/product-piece.module#ProductPieceModule'
                  },
                  {
                    path: 'kardex',
                    loadChildren: './product-kardex/product-kardex.module#ProductKardexModule'
                  },
                  {
                    path: 'kardex-daily',
                    loadChildren: './product-kardex-daily/product-kardex-daily.module#ProductKardexDailyModule'
                  },

                  {
                    path: 'kardex-reprocess',
                    loadChildren: './kardex-reprocess/kardex-reprocess.module#KardexReprocessModule'
                  },                  {
                    path: 'kardex-puro-ego',
                    loadChildren: './kardex-puro-ego/kardex-puro-ego.module#KardexPuroEgoModule'
                  },
                  {
                    path: 'kardex/:inventory',
                    loadChildren: './product-kardex/product-kardex.module#ProductKardexModule'
                  },
                  {
                    path: 'invrep',
                    loadChildren: './product-inv-rep/product-inv-rep.module#ProductInvRepModule'
                  },
                  {
                    path: 'provider-product-codes',
                    loadChildren: './provider-product-codes-base/provider-product-codes-base.module#ProviderProductCodesBaseModule'
                  },
                  {
                    path: 'template/:type',
                    loadChildren: './product-template/product-template.module#ProductTemplateModule'
                  },
                  {
                    path: 'inventory-age',
                    loadChildren: './inventory-age/inventory-age.module#InventoryAgeModule'
                  },
                  {
                    path: 'price-list/:type',
                    loadChildren: './price-list/price-list.module#PriceListModule'
                  }
                ]
              }, {
                path: 'inventory-movement',
                children: [
                  {
                    path: 'inventory-adjustment-type',
                    loadChildren: './inventory-adjustment-type/inventory-adjustment-type.module#InventoryAdjustmentTypeModule'
                  },
                  {
                    path: 'inventory-adjustment',
                    loadChildren: './inventory-adjustment/inventory-adjustment.module#InventoryAdjustmentMainModule'
                  },
                  {
                    path: 'inventory-adjustment/:client-cloth/:id',
                    loadChildren: './inventory-adjustment/inventory-adjustment.module#InventoryAdjustmentMainModule'
                  },
                  {
                    path: 'inventory-transfer',
                    loadChildren: './inventory-transfer/inventory-transfer.module#InventoryTransferModule'
                  },

                  {
                    path: 'inventory-transfer-accep',
                    loadChildren: './inventory-transfer-accep/inventory-transfer-accep.module#InventoryTransferModule'
                  },
                  {
                    path: 'inventory-transfer-recived',
                    loadChildren: './inventory-transfer-recived/inventory-transfer-recived.module#InventoryTransferRecivedModule'
                  },
                  {
                    path: 'inventory-transfer-done',
                    loadChildren: './inventory-transfer-done/inventory-transfer-done.module#InventoryTransferDoneModule'
                  },
                  {
                    path: 'inventory-transfer-pivot',
                    loadChildren: './inventory-transfer-pivot/inventory-transfer-pivot.module#InventoryTransferPivotModule'
                  },
                  {
                    path: 'physical-inventory',
                    loadChildren: './physical-inventory/physical-inventory.module#PhysicalInventoryModule'
                  },
                  {
                    path: 'movements',
                    loadChildren: './inventory-move/inventory-move.module#InventoryMoveModule'
                  },
                  {
                    path: 'product-conversion',
                    loadChildren: './product-conversion/product-conversion.module#ProductConversionModule'
                  },

                ]
              }
            ]
          }, {
            path: 'sell',
            children: [
              {
                path: 'invoice',
                children: [
                  {
                    path: 'free',
                    loadChildren: './billing-free/billing-free.module#FreeBillingModule'
                  },
                  {
                    path: 'create',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'create/clinic/:clinic',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'create/remission-guide/:remission-guide',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'masive',
                    loadChildren: './masive-billing/masive-billing.module#MasiveBillingModule'
                  },

                  {
                    path: 'list',
                    loadChildren: './billing/list/list-billing.module#ListBillingModule'
                  },
                  {
                    path: 'list/:id',
                    loadChildren: './billing/list/list-billing.module#ListBillingModule'
                  },
                  {
                    path: 'update/:id',
                    loadChildren: './billing/update/update-billing.module#UpdateBillingModule'
                  },
                  {
                    path: 'update/payment-method/:payment-method-id',
                    loadChildren: './billing/update/update-billing.module#UpdateBillingModule'
                  },
                  {
                    path: 'retention/:invoice',
                    loadChildren: './retention-received/retention-received.module#RetentionReceivedModule'
                  },
                  {
                    path: 'create/:odp',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'create/order/:id',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'create/liquidation/:idl',
                    loadChildren: './billing/new/new-billing.module#NewBillingModule'
                  },
                  {
                    path: 'update/:odp/:id',
                    loadChildren: './billing/update/update-billing.module#UpdateBillingModule'
                  },
                  {
                    path: 'list/order/:odp',
                    loadChildren: './billing/list/list-billing.module#ListBillingModule'
                  },
                  {
                    path: 'advance',
                    loadChildren: './income-payment-proof/advanced-from-invoice/advanced-from-invoice.module#AdvancedFromInvoiceModule'

                  },
                  {
                    path: ':pay',
                    loadChildren: './income-payment-proof/new/new-income-payment-proof.module#NewIncomePaymentProofModule'
                  },
                  {
                    path: 'transfer/advance',
                    loadChildren: './income-payment-proof/transfer-advance/new/new-transfer-advance.module#NewTransferAdvanceModule'
                  },
                  {
                    path: 'transfer/advance/:id',
                    loadChildren: './income-payment-proof/transfer-advance/update/update-transfer-advance.module#UpdateTransferAdvanceModule'
                  }

                ]
              },
               {
                path: 'ecommerce',
                children: [
                  {
                    path: 'new',
                    loadChildren: './billing/ecommerce/new/ecommerce-new.module#EcommerceNewModule'
                  }, {
                    path: 'summary',
                    loadChildren: './billing/ecommerce/summary/ecommerce-summary.module#EcommerceSummaryModule'
                  }
                ]
              }, {
                path: 'credit-note',
                children: [
                  {
                    path: 'create',
                    loadChildren: './credit-note/new/new-credit-note.module#NewCreditNoteModule'
                  },
                  {
                    path: 'list',
                    loadChildren: './credit-note/list/list-credit-note.module#ListCreditNoteModule'
                  },
                  {
                    path: 'update/:id',
                    loadChildren: './credit-note/update/update-credit-note.module#UpdateCreditNoteModule'
                  },

                ]
              }, {
                path: 'report',
                children: [
                  {
                    path: 'sales-invoices',
                    loadChildren: './sales-reports/sales-reports.module#SalesReportsModule'
                  },{
                    path: 'close-sales-report',
                    loadChildren: './close-sales-reports/close-sales-reports.module#CloseSalesReportsModule'
                  },
                  {
                    path: 'sales-invoices-detailed',
                    loadChildren: './sales-reports-detailed/sales-reports-detailed.module#SalesReportsDetailedModule'
                  },
                  {
                    path: 'vouchers-reconcile',
                    loadChildren: './vouchers-reconcile-reports/vouchers-reconcile-reports.module#VouchersReconcileReportsModule'
                  },
                  {
                    path: 'ice',
                    loadChildren: './ice-report/ice-report.module#IceReportModule'
                  },
                  {
                    path: 'advances',
                    loadChildren: './income-payment-proof-reports/all-advances-reports/all-advances-reports.module#AllAdvanceReportsModule'
                  },
                  {
                    path: 'retention-received',
                    loadChildren: './retention-received-report/retention-received-report.module#RetentionReceivedReportModule'
                  },

                  {
                    path: ':cli-account-status',
                    loadChildren: './account-status-report/account-status-report.module#AccountStatusReportModule'
                  },

                ]
              },
              {
                path: 'promotions',
                children: [
                  {
                    path: 'list',
                    loadChildren: './promotions/list/list-promotions.module#ListPromotionsModule'
                  },
                  {
                    path: 'create',
                    loadChildren: './promotions/new/new-promotions.module#NewPromotionsModule'
                  },
                  {
                    path: 'update/:id',
                    loadChildren: './promotions/update/update-promotions.module#UpdatePromotionsModule'
                  },
                ]
              },
              {
                path: 'stock-inquiry',
                children: [
                  {
                    path: '',
                    loadChildren: './product-stock-inquiry/product-stock-inquiry.module#ProductStockInquiryModule'
                  },
                ]
              },
              {
                path: 'merchandise-reception',
                children: [
                  {
                    path: '',
                    loadChildren: './merchandise-reception/merchandise-reception.module#MerchandiseReceptionModule'
                  },
                  {
                    path: 'repairs-form',
                    loadChildren: './repairs-form/repairs-form.module#RepairsFormModule'
                  },
                ]
              },
              {
                path:'analysis-integration',
                loadChildren:'./analysis-integration/analysis-integration.module#AnalysisIntegrationModule'
              },
              {
                path:'consignment',
                children: [
                  {
                    path: 'create',
                    loadChildren:'./new-consignment/new-consignment.module#NewConsignmentModule'
                  },
                  {
                    path: 'list',
                    loadChildren:'./list-consignment/list-consignment.module#ListConsignmentModule'
                  },
                  {
                    path: 'update/:id',
                    loadChildren:'./update-consignment/update-consignment.module#UpdateConsignmentModule'
                  },
                  {
                    path: 'duplicate/:id',
                    loadChildren:'./new-consignment/new-consignment.module#NewConsignmentModule'
                  }
                ]
              },
              {
                path:'liquidation',
                children: [
                  {
                    path: 'create/:id',
                    loadChildren:'./new-liquidation/new-liquidation.module#NewLiquidationModule'
                  },
                  {
                    path: 'list',
                    loadChildren:'./list-liquidation/list-liquidation.module#ListLiquidationModule'
                  },
                  {
                    path: 'update/:ide',
                    loadChildren:'./new-liquidation/new-liquidation.module#NewLiquidationModule'
                  },
                ]
              },
            ]
          }, {
            path: 'buy',
            children: [
              {
                path: 'purchase-order',
                loadChildren: './purchase-order/purchase-order.module#PurchaseOrderModule'
              },
              {
                path: 'purchase-order-devs',
                loadChildren: './purchase-orders-devs/purchase-order-devs.module#PurchaseOrderDevsModule'
              },
              {
                path: 'list',
                loadChildren: './purchase-list/purchase-list.module#PurchaseListModule'
              }, {
                path: 'list/:id',
                loadChildren: './purchase-list/purchase-list.module#PurchaseListModule'
              }, {
                path: 'reports',
                loadChildren: './buy-reports/buy-reports.module#BuyReportsModule'
              },
              {
                path: 'report/:pro-account-status',
                loadChildren: './account-status-report/account-status-report.module#AccountStatusReportModule'
              },
               {
                path: 'retentions',
                loadChildren: './buy-retentions/buy-retentions.module#BuyRetentionsModule'
              },{
                path: 'purchase',
                loadChildren: './purchase/admin-purchase.module#AdminPurchaseModule'
              }, {
                path: 'purchase/:id/:paso/:accion',
                loadChildren: './purchase/admin-purchase.module#AdminPurchaseModule'
              }, {
                path: 'purchase-annulment',
                loadChildren: './purchase-annulment/purchase-annulment.module#PurchaseAnnulmentModule'
              },
              {
                path: 'retention',
                children: [
                  {
                    path: 'list/:id',
                    loadChildren: './retention-list/retention-list.module#RetentionListModule'
                  },{
                    path: 'list',
                    loadChildren: './retention-list/retention-list.module#RetentionListModule'
                  }

                ]
              }, {
                path: 'clearance',
                children: [
                  {
                    path: 'list/:id',
                    loadChildren: './clearance-list/clearance-list.module#ClearanceListModule'
                  },{
                    path: 'list',
                    loadChildren: './clearance-list/clearance-list.module#ClearanceListModule'
                  }

                ]
              }
            ]
            },{
            path: 'import',
            children: [
              {
                path: 'requisition',
                loadChildren: './buy-requisition/buy-requisition.module#BuyRequisitionModule'
              },
              {
                path: 'requisition/:mailbox',
                loadChildren: './buy-requisition/buy-requisition.module#BuyRequisitionModule'
              },
            ]
          },
          {
            path: 'production',
            children: [
              {
                path: 'production-order',
                loadChildren: './production-order/list/production-order-list.module#ProductionOrderListModule'
              }, {
                path: 'new-production-order',
                loadChildren: './production-order/new/new-production-order.module#NewProductionOrderModule'
              },
              {
                path: 'update-production-order/:id',
                loadChildren: './production-order/update/update-production-order.module#UpdateProductionOrderModule'
              }, {
                path: 'production-order-form',
                loadChildren: './production-order-form/form-production-order.module#FormProductionOrderModule'
              }, {
                path: 'production-order-format',
                loadChildren: './production-order-format/production-order-format.module#ProductionOrderFormatModule'
              },

              {
                path: 'production-order-received',
                loadChildren: './production-order-received/list/production-order-received-list.module#ProductionOrderReceivedListModule'
              },
              {
                path: 'update-production-order/:id/:recieved',
                loadChildren: './production-order/update/update-production-order.module#UpdateProductionOrderModule'
              },
              {
                path: 'new-production-order-lot',
                loadChildren: './production-order-lot/new/new-production-order-lot.module#NewProductionOrderLotModule'
              },
              {
                path: 'update-production-order-lot/:id',
                loadChildren: './production-order-lot/update/update-production-order-lot.module#UpdateProductionOrderLotModule'
              },

              {
                path: ':supplies-format',
                loadChildren: './production-order-format/production-order-format.module#ProductionOrderFormatModule'
              },
            ]
          }, {
            path: 'delivery-note',
            children: [
              {
                path: 'create',
                loadChildren: './delivery-note/new/new-delivery-note.module#NewDeliveryNoteModule'
              },
              {
                path: 'list',
                loadChildren: './delivery-note/list/list-delivery-note.module#ListDeliveryNoteModule'
              },
              {
                path: 'update/:id',
                loadChildren: './delivery-note/update/update-delivery-note.module#UpdateDeliveryNoteModule'
              },

            ]
          },
          {
            path: 'remission-guide',
            children: [
              {
                path: 'create',
                loadChildren: './referral-guide/new/new-referral-guide.module#NewReferralGuideModule'
              },
              {
                path: 'list',
                loadChildren: './referral-guide/list/list-referral-guide.module#ListReferralGuideModule'
              },
              {
                path: 'update/:id',
                loadChildren: './referral-guide/update/update-referral-guide.module#UpdateReferralGuideModule'
              },
              {
                path: 'liquidate/:id',
                loadChildren: './referral-guide/liquidate/liquidate-referral-guide.module#LiquidateReferralGuideModule'
              },
              {
                path: 'invoice/:invoice-id',
                loadChildren: './referral-guide/new/new-referral-guide.module#NewReferralGuideModule'
              },
              {
                path: 'tansfer/:tansfer-id',
                loadChildren: './referral-guide/new/new-referral-guide.module#NewReferralGuideModule'
              },
              {
                path: 'delivery-note/:delivery-note-id',
                loadChildren: './referral-guide/new/new-referral-guide.module#NewReferralGuideModule'
              },
              {
                path: 'transport-order',
                loadChildren: './transport-order/transport-order.module#TransportOrderModule'
              }
            ]
          },
          {
            path: 'retention-received',
            children: [
              {
                path: '',
                loadChildren: './retention-received/retention-received.module#RetentionReceivedModule'
              },
              {
                path: 'xml/:xml-id',
                loadChildren: './retention-received/retention-received.module#RetentionReceivedModule'
              }
            ]
          },
          {
            path: 'income-payment-proof',
            children: [
              {
                path: 'create',
                loadChildren: './income-payment-proof/new/new-income-payment-proof.module#NewIncomePaymentProofModule'
              },{
                path: 'list',
                loadChildren: './income-payment-proof/list/list-income.module#ListIncomeModule'
              }, {
                path: 'update/:id',
                loadChildren: './income-payment-proof/update/update-income-payment-proof.module#UpdateIncomePaymentProofModule'
              },
              {
                path: 'report',
                children: [
                  {
                    path: '',
                    loadChildren: './income-payment-proof-reports/all-income-reports/all-income-reports.module#AllIncomeReportsModule'

                  },

                ]
              },
              {
                path:'third-party-income',
                loadChildren:'./third-party-income/third-party-income.module#ThirdPartyIncomeModule'
              },
            ]
          },
          {
            path: 'egress-payment-proof',
            children: [
              {
                path: 'create',
                loadChildren: './egress-payment-proof/new/new-egress-payment-proof.module#NewEgressPaymentProofModule'
              },
              {
                path: 'update/:id',
                loadChildren: './egress-payment-proof/update/update-egress-payment-proof.module#UpdateEgressPaymentProofModule'
              },
              {
                path: 'update/:bank-confirmation/:id',
                loadChildren: './egress-payment-proof/update/update-egress-payment-proof.module#UpdateEgressPaymentProofModule'
              },
              {
                path: 'list',
                loadChildren: './egress-payment-proof/list/list-egress.module#ListEgressModule'
              },
              {
                path: 'cancel/:id',
                loadChildren: './egress-payment-proof/cancel/cancel-egress-payment-proof.module#CancelEgressPaymentProofModule'
              },
              {
                path: 'repayment/:id',
                loadChildren: './egress-payment-proof/repayment/repayment-egress-payment-proof.module#RepaymentEgressPaymentProofModule'
              },
              {
                path: 'report',
                children: [
                  {
                    path: '',
                    loadChildren: './egress-payment-proof-reports/all-egress-reports/all-egress-reports.module#AllEgressReportsModule'

                  },
                ]
              },
              {
                path: 'cash-management',
                loadChildren: './cash-management/cash-management.module#CashManagementModule'
              },
            ]
          },
          {
            path: 'contract-orders',
            children: [
              {
                path: 'dispatch-products/:fab',
                loadChildren: './inventory-transfer/inventory-transfer.module#InventoryTransferModule'
              }, {
                path: 'receptions/:fab',
                  loadChildren: './inventory-adjustment/inventory-adjustment.module#InventoryAdjustmentMainModule'
              },

              {
                path: 'inv-supplies',
                children: [
                  {
                    path: '',
                    loadChildren: './inv-supplies/inv-supplies.module#InvSuppliesModule'
                  },
                ]
              },
              {
                path: 'production-order-monitoring',
                  loadChildren: './production-order-monitoring/production-order-monitoring.module#ProductionOrderMonitoringModule'
              },

            ]
          },
          {
            path: 'daily-seat',
            children: [
              {
                path: ':mailbox',
                loadChildren: './daily_seat/daily_seat.module#DailySeatModule'
              },
              {
                path: '',
                loadChildren: './daily_seat/daily_seat.module#DailySeatModule'
              }
            ]
          },
          {
            path: 'financial',
            children: [
              {
                path: 'financial-state',
                children: [
                  {
                    path: 'balance',
                    loadChildren: './financial-state-balance/financial-state-balance.module#FinancialStateBalanceModule'
                  }, {
                    path: 'ledger-report',
                    loadChildren: './ledger-report/ledger-report.module#LedgerReportModule'
                  },
                  {
                    path: ':out-of-square-seats',
                    loadChildren: './ledger-report/ledger-report.module#LedgerReportModule'
                  },
                  {
                    path: ':docs-no-ledger',
                    loadChildren: './ledger-report/ledger-report.module#LedgerReportModule'
                  }

                ]
              },
              {
                    path:'accounting-analysis',
                    loadChildren:'./accounting-analysis/accounting-analysis.module#AccountingAnalysisModule'
              }
            ]
          },
          {
            path: 'tax-annexes',
            children: [
              {
                path: 'xml-loader/:status',
                loadChildren: './load-sri-xml/load-sri-xml.module#LoadSriXmlModule'
              },{
                path: 'xml-excluido/:status',
                loadChildren: './load-sri-xml/load-sri-xml.module#LoadSriXmlModule'
              },{
                path: 'new-register-template',
                loadChildren: './register-template/new/new-register-template.module#NewRegisterTemplateModule'
              },{
                path: 'update-register-template/:id',
                loadChildren: './register-template/new/new-register-template.module#NewRegisterTemplateModule'
              },{
                path: 'list-register-template',
                loadChildren: './register-template/list/list-register-template.module#ListRegisterTemplateModule'
              },{
                path: 'ats',
                loadChildren: './ats/ats.module#ATSModule'
              },{
                path: 'anexo-ice',
                loadChildren: './anexo-ice/anexo-ice.module#AnexoIceModule'
              }, {
                path: 'sri-automatization',
                loadChildren: './sri-credentials-client/sri-credentials-client.module#SriCredentialsModule'
              },
              {
                path: 'xml-analisis',
                loadChildren: './xml-analisis/xml-analisis.module#XmlAnalisisModule'
              },
              {
                path: 'report-xml-mailbox-before-processing',
                loadChildren: './report-existing-holds-in-the-xml-mailbox-before-processing/report-existing-holds-in-the-xml-mailbox-before-processing.module#ReportExistingHoldsInTheXmlMailboxBeforeProcessingModule'
              },
              {
                path: 'gnd-reports',
                loadChildren: './gnd-reports/gnd-reports.module#GndReportsModule'
              }
            ]
          },
          {
            path: 'checkbook',
            children: [
              {
                path: ':create',
                loadChildren: './checkbook/checkbook.module#CheckbookModule'
              },
              {
                path: '',
                loadChildren: './checkbook/checkbook.module#CheckbookModule'
              }
            ]
          },
          {
            path: 'integrations',
            children: [
              {
                path: '',
                loadChildren: './integrations/integrations.module#IntegrationsModule'
              },
              {
                path: 'aje-group',
                component: AjeGroupComponent
              },
              {
                path: ':integration',
                loadChildren: './integrations/integrations.module#IntegrationsModule'
              },

            ]
          },
          {
            path: 'collection-management',
            children: [
              {
                path: 'portfolio-admin',
                loadChildren: './collection-management/portfolio-admin/portfolio-admin.module#PortfolioAdminModule'
              },
              {
                path: 'portfolio-tracing',
                loadChildren: './collection-management/portfolio-tracing/portfolio-tracing.module#PortfolioTracingModule'
              }

            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

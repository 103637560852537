import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Promotion } from '../../../models/client/Promotion';


@Component({
  selector: 'app-promotions-able-modal',
  templateUrl: './promotions-able.modal.html',
  styleUrls: ['./promotions-able.modal.scss'],
})

export class PromotionsAbleModal {
  public doneCheckbox = false
  public active_promotion = 0;
  public motivo: string;
  public header_columns: string[] = ['bar_code', 'aux_code', 'provider', 'quality','size','name_product'];
  public promotions_able = []
    constructor(
      public dialogRef: MatDialogRef<PromotionsAbleModal>,
      @Inject(MAT_DIALOG_DATA) public data: {promotions_able: Promotion[], promotions: Promotion[]},

    ) {
      console.log(this.data.promotions_able);
      for (const promotion of this.data.promotions_able) {
        if (promotion.duplicate_promotion) {
          promotion.selected = false;
        } else {
          promotion.selected = true;
        }
      }
    }


    close(): void {

      let save = {
        promotions: this.data.promotions_able.filter(v=> v.selected == true),
      }
      this.dialogRef.close(save);
    }


}

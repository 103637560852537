import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BuyRetentionDetail } from '../../models/client/BuyRetentionDetail';
import { URLProvider } from '../url.providers';

@Injectable()
export class BuyClearanceElectronicalProvider {
  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public mailClearances(enterprise: string, email_detail: ClearanceEmail[]) {
    return this.http.post<any>(this.urlProvider.electronicalbuyclearance(enterprise) + '/sendmail' , email_detail, this.urlProvider.httpOptions);
  }

  public singClearances(enterprise: string, email_detail: ClearanceEmail[]) {
    return this.http.post<any>(this.urlProvider.electronicalbuyclearance(enterprise) + '/sriauth' , email_detail, this.urlProvider.httpOptions);
  }

  public pdfClearance(enterprise: string, access_key: string) {
    return this.urlProvider.electronicalbuyclearance(enterprise) + '/'+access_key;
  }
}

export interface ClearanceEmail
{
  access_key    : string;
  email    : string;
}

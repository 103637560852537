import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GridPermissions } from '../../models/client/GridPermissions';
import { URLProvider } from '../url.providers';
import { UserAccountRole } from '../../models/client/UserAccountRole';

@Injectable()
export class GridPermissionsProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allGridPermissions(enterprise: string, value: string = '') {
    if (value !== '') {
      return this.http.get<GridPermissions[]>(this.urlProvider.gridpermissions(enterprise) + '?filter=true&'  + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<GridPermissions[]>(this.urlProvider.gridpermissions(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getGridPermissions(enterprise: string, id: string) {
    return this.http.get<GridPermissions>(this.urlProvider.gridpermissions(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getUsersByRole(enterprise: string, search) {
    return this.http.get<UserAccountRole[]>(this.urlProvider.gridpermissions(enterprise) + '/usersbyrole?' + search, this.urlProvider.httpOptions);
  }

  public insertGridPermissions(enterprise: string, gridpermissions: any) {
    return this.http.post<number>(this.urlProvider.gridpermissions(enterprise), gridpermissions, this.urlProvider.httpOptions);
  }

  public updateGridPermissions(enterprise: string, gridpermissions: GridPermissions, id: string) {
    return this.http.put<Boolean>(this.urlProvider.gridpermissions(enterprise) + '/' + id, gridpermissions, this.urlProvider.httpOptions);
  }

}

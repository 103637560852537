import { Component, Inject } from '@angular/core';
import { AccountingPeriodDetailEnterpriseProvider } from "../../../providers/client/accounting_period_detail_enterprise.provider";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { AccountingPeriodDetailEnterprise }  from '../../../models/client/AccountingPeriodDetailEnterprise';


@Component({
  selector: 'app-yes-no-confirmation-ap-modal',
  templateUrl: './yes-no-confirmation-ap.modal.html',
  styleUrls: ['./yes-no-confirmation-ap.modal.scss'],
})

export class YesNoConfirmationAPModal {
  public doneCheckbox = false;
  public registerADE: AccountingPeriodDetailEnterprise={
    accounting_period_01main_id: 0,
    accounting_period_02detail_id: 0,
    enterprises_id: 0,
    branch_office_id: 0
  };
  public motivo: string;
  public target: string;
  public checked: boolean=false;
  public checkedCV: boolean=true;
  public checkedPC: boolean=true;
  public checkedI: boolean=true;

    constructor(
      public dialogRef: MatDialogRef<YesNoConfirmationAPModal>,
      private accountingPeriodDetailEnterpriseService: AccountingPeriodDetailEnterpriseProvider,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
      console.log('Data de Dialogo', data);
      debugger;
      if(data.d.data.sucursal_id){
        this.target='Sucursales ';
        let nro_mes:number = data.d.columnIndex-2;
        let id:number = 0;
        switch (nro_mes) {
          case 1:
            id =data.d.data.ene_index;
          break;
          case 2:
            id =data.d.data.feb_index;
          break;
          case 3:
            id =data.d.data.mar_index;
          break;
          case 4:
            id =data.d.data.abr_index;
          break;
          case 5:
            id =data.d.data.may_index;
          break;
          case 6:
            id =data.d.data.jun_index;
          break;
          case 7:
            id =data.d.data.jul_index;
          break;
          case 8:
            id =data.d.data.ago_index;
          break;
          case 9:
            id =data.d.data.sep_index;
          break;
          case 10:
            id =data.d.data.oct_index;
          break;
          case 11:
            id =data.d.data.nov_index;
          break;
          case 12:
            id =data.d.data.dic_index;
          break;
          }
        this.loadRegistreValues(id);
      }else{
        this.target='Empresas ';
        if(data.d.displayValue==='CERRADO'){
          this.checkedCV=false;
          this.checkedPC=false;
          this.checkedI=false;
        }else{
          this.checkedCV=true;
          this.checkedPC=true;
          this.checkedI=true;

        }
      }
    }

loadRegistreValues(id:number){
  this.accountingPeriodDetailEnterpriseService.getAccountingPeriodDetailEnt(id.toString()).subscribe(
    (data: AccountingPeriodDetailEnterprise) => {
      debugger
      this.checkedCV=data.b_document;
      this.checkedPC=data.b_accountant;
      this.checkedI=data.b_general;
      }
  )
}

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Warehouse } from '../../models/client/Wharehouse';
import { URLProvider } from '../url.providers';

@Injectable()
export class WarehouseProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allWarehouse(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<Warehouse[]>(this.urlProvider.warehouse(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Warehouse[]>(this.urlProvider.warehouse(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allWarehouseEstablisment(enterprise: string, branch_office_id:string ) {
      return this.http.get<Warehouse[]>(this.urlProvider.warehouse(enterprise) + '/bybranchoffice/' + branch_office_id, this.urlProvider.httpOptions);
  }

  public getWarehouse(enterprise: string, id: string) {
    return this.http.get<Warehouse>(this.urlProvider.warehouse(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertWarehouse(enterprise: string, warehouse: Warehouse) {
    return this.http.post<number>(this.urlProvider.warehouse(enterprise), warehouse, this.urlProvider.httpOptions);
  }

  public updateWarehouse(enterprise: string, warehouse: Warehouse, id: string) {
    return this.http.put<Boolean>(this.urlProvider.warehouse(enterprise) + '/' + id, warehouse, this.urlProvider.httpOptions);
  }

}

import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PurchaseProvider } from "../../../providers/client/purchase.provider";
import { BuyRetentionMainProvider } from "../../../providers/client/buy_retention_main.provider";



@Component({
  selector: 'app-delete-purchase-modal',
  templateUrl: './delete-purchase.modal.html',
  styleUrls: ['./delete-purchase.modal.scss'],
})

export class DeletePurchaseModal {
  public doneCheckbox = false
  public motivo: string;

    constructor(
      public dialogRef: MatDialogRef<DeletePurchaseModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private PurchaseService: PurchaseProvider,
      private BuyRetentionMainService: BuyRetentionMainProvider,

    ) {}
    ngOnInit(): void {

      this.checkInput()

    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input-cancel');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true; 
      }
      this.checkInput()
    }

    checkInput() {
      let checkboxes: any = document.getElementsByClassName('mat-checkbox-input-cancel')
      let inputmotivo: any = document.getElementsByClassName('mat-input')
      let cancelButton: any = document.getElementsByClassName('cancel')
      this.doneCheckbox = false
      if (`${inputmotivo[0].value}` == "") {
        for (let x = 0; x < cancelButton.length; x++) {
          cancelButton[x].disabled = true
        }

      } else {
        for (let x = 0; x < cancelButton.length; x++) {
          cancelButton[x].disabled = false
        }
      }


    }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {UserAccount} from '../../models/client/UserAccount';

import {URLProvider} from '../url.providers';
import {ViewPaginator} from 'src/app/models/client/ViewPaginator';

@Injectable()
export class UserAccountProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {
  }

  public allUserAccounts(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<UserAccount[]>(this.urlProvider.user_account(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<UserAccount[]>(this.urlProvider.user_account(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getallUserAccounts(enterprise: string, token: string = '') {
    return this.http.get<any[]>(this.urlProvider.user_account(enterprise) + '/status/get', this.urlProvider.httpOptions);
  }


  public allUserAccountView(enterprise: string, token: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.user_account(enterprise) + '/view?' + token, this.urlProvider.httpOptions);
  }

  public getNextPreviousPage(url) {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }

  public getUserAccount(enterprise: string, id: string) {
    return this.http.get<UserAccount>(this.urlProvider.user_account(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertUserAccount(enterprise: string, user_account: UserAccount) {
    return this.http.post<number>(this.urlProvider.user_account(enterprise), user_account, this.urlProvider.httpOptions);
  }

  public insertUserAccountMasive(enterprise: string, user_account: any) {
    return this.http.post<any>(this.urlProvider.user_account(enterprise) + '/masive', user_account, this.urlProvider.httpOptions);
  }

  public newPassApp(enterprise: string, users:any) {
    return this.http.post<any>(this.urlProvider.user_account(enterprise) + '/encrypt/pass',users, this.urlProvider.httpOptions);
  }

  public updateUserAccount(enterprise: string, UserAcount: UserAccount, id: string) {
    return this.http.put<Boolean>(this.urlProvider.user_account(enterprise) + '/' + id, UserAcount, this.urlProvider.httpOptions);
  }

  public updateUserStatus(enterprise: string, user: any) {
    return this.http.put<any>(this.urlProvider.user_account(enterprise) + '/status/update', user, this.urlProvider.httpOptions);
  }

  public massiveUpdateUserStatus(enterprise: string, user: any) {
    return this.http.put<any>(this.urlProvider.user_account(enterprise) + '/massivestatus/update', user, this.urlProvider.httpOptions);
  }

  public updateUserAccountActive(enterprise: string, UserAcount: UserAccount) {
    return this.http.put<Boolean>(this.urlProvider.user_account(enterprise) + '/update/active', UserAcount, this.urlProvider.httpOptions);
  }

}

import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support.modal.html',
  styleUrls: ['./support.modal.scss'],
})

export class SupportModal {
    public enterprise = '';
    public showSpinner: boolean = false;

    constructor(
      private toastrService: ToastrService,
      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef:MatDialogRef<SupportModal>
    ) {}

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-advances-modal',
  templateUrl: './advances.modal.html',
  styleUrls: ['./advances.modal.scss'],
})

export class AdvancesModal implements OnInit{
  public searchFilterText: string = '';
  public searchText: string = '';
  public advance = {
    value: null,
    motive: null,
    is_advance_retention: false
  }
  public advances = [];
  public title: string = '';

  constructor(
    public dialogRef: MatDialogRef<AdvancesModal>,


    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data', JSON.stringify(data));
  }
  ngOnInit(): void {

    if(this.data.title != null && this.data.title != undefined){
      this.title = this.data.title;
    }else{
      this.title = 'Anticipos';
    }
    console.log('data2', this.data.title);
  }

  add(){
    if ((this.advance.value>0) && (this.advance.motive)) {
      this.advances.push(this.advance);
    }
  }

  newAdvance($event){
    if (this.data.type == 'cin') {
      debugger

      if (this.data.clients_providers.length == 1) {
        $event.data.client = this.data.clients_providers[0].id;
      }
    }else{
      if (this.data.clients_providers.length == 1) {
        $event.data.client = this.data.clients_providers[0].id;
      }
      if(this.title =='Anticipos de Retenciones'){
        $event.data.motive = 'Retención TC';
        $event.data.is_advance_retention = true;

      }
      if (this.title =='Anticipos de Comisiones'){
        $event.data.motive = 'Comisión TC';
        $event.data.is_advance_retention = false;
      }
     
    }
  }
  close(): void {
    debugger;
    console.log('advances', this.advances);
     this.dialogRef.close(this.advances);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class RepairsFormProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allRepairsForms(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<RepairsForm[]>(this.urlProvider.repairsform(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<RepairsForm[]>(this.urlProvider.repairsform(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allRepairsFormView(enterprise: string, token: string = '', value: string = '', filters ='') {
    return this.http.get<ViewPaginator>(this.urlProvider.repairsform(enterprise) + '/view?' + token + '=' + value + filters, this.urlProvider.httpOptions);
  }

  public getNextPreviousPage(url) {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }

  public getRepairsForm(enterprise: string, id: string) {
    return this.http.get<RepairsForm>(this.urlProvider.repairsform(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertRepairsForm(enterprise: string, repairs_form: RepairsForm) {
    return this.http.post<number>(this.urlProvider.repairsform(enterprise), repairs_form, this.urlProvider.httpOptions);
  }

  public updateRepairsForm(enterprise: string, repairs_form: RepairsForm, id: string) {
    return this.http.put<Boolean>(this.urlProvider.repairsform(enterprise) + '/' + id, repairs_form, this.urlProvider.httpOptions);
  }


}

export interface RepairsForm{
  id?: number,
  name: any,
  parent: number,
  duration: number,
  active: boolean,
  items?: RepairsForm[],
}

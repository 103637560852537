import { UploadActionTypes, UploadActionsUnion } from '../actions/upload.actions';
import { Upload } from '../states/upload';
import * as CryptoJS from 'crypto-js';

const initialState: Upload[] = [];

export function uploadReducer(state: Upload[] = initialState, action: UploadActionsUnion): Upload[] {
  let key, upload;
  switch (action.type) {
    case UploadActionTypes.LOAD:
      key = window.btoa('upload');
      upload = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, upload);
      return initialState;
    case UploadActionTypes.UPDATE:
      key = window.btoa('upload');
      console.log('UploadActionTypes.UPDATE', action.payload);
      debugger;
      upload = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, upload);
      return action.payload;
    case UploadActionTypes.DELETE:
        key = window.btoa('upload');
        localStorage.removeItem(key);
        return initialState;
    default:
      key = window.btoa('upload');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualupload: Upload[] = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualupload
        }
      } else {
        key = window.btoa('upload');
        upload = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, upload);
        return state;
      }
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { PurchaseProvider } from '../../../providers/client/purchase.provider';
import { RetentionRecivedProvider, RetentionRecived } from '../../../providers/client/retention_recived.provider';
import { InvoicePaymentMethodProvider } from '../../../providers/client/invoice_payment_method.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { DxDataGridComponent } from "devextreme-angular";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-filters',
  templateUrl: './product-filters.modal.html',
  styleUrls: ['./product-filters.modal.scss']
})
export class ProductFiltersComponent implements OnInit {
  public filter_atributes: any=[];
  public filter_selected: any=[];


  constructor(
    public dialogRef: MatDialogRef<ProductFiltersComponent>,

    private toastrService: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    debugger;
    console.log('data', JSON.stringify(data),data);
    this.filter_atributes=data.InvProductFilter;    
    
  }

  showError(error, mensaje) {
    this.toastrService.error(`${mensaje}`, `${error}`, {
      timeOut: 10000,
      positionClass: 'toast-bottom-center'
    });
  }

  changingSelectValue(e:any){
    debugger
    let validate_number=this.filter_atributes.filter(element=>element.check==true);
    if (validate_number.length==11) {
      const updatedIndex = this.filter_atributes.findIndex(item => item.id === e.data.id);
      if (updatedIndex !== -1) {
        this.filter_atributes[updatedIndex].check=false;
        this.showError('Error','No se pueden seleccionar mas de 10 Atributos');
        return
      }
      
    }
    this.filter_selected=e.data.Atributes
    console.log(e);
    
  }
  changingSelectValueFilter(e:any){
    debugger
    let validate_number_att=this.filter_selected.filter(element=>element.check==true);
    if (validate_number_att.length==11) {
      const updatedIndex = this.filter_selected.findIndex(item => item.id === e.data.id);
      if (updatedIndex !== -1) {
        this.filter_selected[updatedIndex].check=false;
        this.showError('Error','No se pueden seleccionar mas de 10 detalles de Atributos');
        return
      }
      
    }
    console.log(e);
    const updatedIndex = this.filter_atributes.findIndex(item => item.id === e.data.inv_product_filter_id);
    if (updatedIndex !== -1) {
      const updatedIndexAtt = this.filter_selected.findIndex(item => item.id === e.data.id);      
      if (updatedIndexAtt !== -1) {
        this.filter_atributes[updatedIndex].Atributes[updatedIndexAtt] = e.data;
      }
    }
  }

  close(): void {
    debugger;
    let valid=true;
    let attributes=[];
    let element_name='';
    let first_atributes=this.filter_atributes;
    this.filter_atributes=this.filter_atributes.filter(element=>element.check==true);
    this.filter_atributes.forEach(element => {
      attributes = element.Atributes.filter(element=>element.check);
      if (attributes.length==0) {
        valid=false;
        element_name=element.name;
        return;
      }else{
        element.Atributes= element.Atributes.filter(element=>element.check);
      }
    });
    if (valid) {
      this.dialogRef.close(this.filter_atributes);      
    }else{
      this.showError('Error','El Atributo: '+element_name+' no tiene ningun detalle seleccionado');
      this.filter_atributes=first_atributes;
    }
  }
  ngOnInit() {
  }

}

import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";
import { ToastrService } from 'ngx-toastr';

import { BuyPurchaseOrder01Main } from '../../../models/client/BuyPurchaseOrder01Main';

@Component({
  templateUrl: './buy-purchase-order.modal.html',
  styleUrls: ['./buy-purchase-order.modal.scss']
})

export class BuyPurchaseOrderModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(
    public dialogRef: MatDialogRef<BuyPurchaseOrderModal>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, buy_purchase_orders: BuyPurchaseOrder01Main[]},
    private toastrService: ToastrService
  ) {

    for (let index = 0; index < this.data.buy_purchase_orders.length; index++) {
      this.data.buy_purchase_orders[index]['applied_amount']= Number(Number(this.data.buy_purchase_orders[index]['balance_amount']).toFixed(2));
      console.log('data modal', this.data.buy_purchase_orders[index]['applied_amount']);
    }
  }

  close() {
    this.data.selected = this.dataGrid.selectedRowKeys;
    this.dialogRef.close(this.data);
  }

  changeValues(e){
    let apply_amount : number= Number(Number(e.newData.applied_amount).toFixed(2));
    let balance_amount : number= Number(Number(e.oldData.balance_amount).toFixed(2));
    if(apply_amount >= 0){
      if(apply_amount > balance_amount){
        e.newData.applied_amount = e.oldData.applied_amount;
        this.showError('Cantidad a Aplicar no Puede Ser Mayor a Cantidad por Recibir','Error');
      }
    }else{
      e.newData.applied_amount = e.oldData.applied_amount;
      this.showError('Cantidad a Aplicar Debe Ser Mayor a Cero (0)','Error');

    }


  }

  changeColor($event){
    if (!$event.column.allowEditing && $event.rowType == "data") {
      $event.cellElement.style.backgroundColor="#ddd"
    }
  }

  showError(error, mensaje: string) {
    this.toastrService.error(error, mensaje, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center'
    });
  }

  showSuccess(success, mensaje: string) {
    this.toastrService.success(success, mensaje, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center'
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

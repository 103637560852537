import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-recive-retention-modal',
  templateUrl: './recive-retention.modal.html',
  styleUrls: ['./recive-retention.modal.scss'],
})

export class ReciveRetentionModal {
  public retention_recived:any ;
  public checkFuction = false;
    constructor(
      public dialogRef: MatDialogRef<ReciveRetentionModal>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    check(boolean) {
      this.retention_recived = boolean;
      this.checkFuction = true
    }
    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true

      }
    }

}

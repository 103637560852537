import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AccountAssigmentDetail }  from '../../models/client/AccountAssigmentDetail';
import { URLProvider } from '../url.providers';

@Injectable()
export class AccountAssigmentDetailProvider {

  constructor(
    private http: HttpClient,
    private urlprovider: URLProvider
  ) {}

  public allAccountAssigmentDetails(enterprise: string, token: string = '', value: any = '') {
    if (token !== '') {
      return this.http.get<AccountAssigmentDetail[]>(this.urlprovider.accdetails(enterprise) + '?' + token + '=' + value, this.urlprovider.httpOptions);
    } else {
      return this.http.get<AccountAssigmentDetail[]>(this.urlprovider.accdetails(enterprise), this.urlprovider.httpOptions);
    }
  }

  public getAccountAssigmentDetail(enterprise: string, id: string) {
    return this.http.get<AccountAssigmentDetail>(this.urlprovider.accdetails(enterprise) + '/' + id, this.urlprovider.httpOptions);
  }

  public insertAccountAssigmentDetail(enterprise: string, account_assigment_detail: AccountAssigmentDetail) {
    return this.http.post<number>(this.urlprovider.accdetails(enterprise), account_assigment_detail, this.urlprovider.httpOptions);
  }

  public insertLotAccountAssigmentDetail(enterprise: string, account_assigment_detail: any) {
    return this.http.post<number>(this.urlprovider.accdetails(enterprise)+'/lot', account_assigment_detail, this.urlprovider.httpOptions);
  }

  public updateAccountAssigmentDetail(enterprise: string, account_assigment_detail: AccountAssigmentDetail, id: string) {
    return this.http.put<Boolean>(this.urlprovider.accdetails(enterprise) + '/' + id, account_assigment_detail, this.urlprovider.httpOptions);
  }

  public deleteAccountAssigmentDetail(enterprise: string,  id: string) {
    return this.http.delete<Boolean>(this.urlprovider.accdetails(enterprise) + '/' + id, this.urlprovider.httpOptions);
  }

}

import { Action } from '@ngrx/store';
import { Menu } from '../states/menu';

export enum MenuActionTypes {
  LOAD = '[MENU] LOAD',
  UPDATE = '[MENU] UPDATE',
  COLLAPSE = '[MENU] COLLAPSE',
  DELETE = '[MENU] DELETE'
}

export class Load implements Action {
  readonly type = MenuActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = MenuActionTypes.UPDATE;
  constructor(public payload: Menu[]) {}
}

export class Delete implements Action {
  readonly type = MenuActionTypes.DELETE;
}

export type MenuActionsUnion = Load | Update | Delete;

import { EnterpriseActionTypes, EnterpriseActionsUnion } from '../actions/enterprise.action';
import { EnterpriseState } from '../states/enterprise';


import * as CryptoJS from 'crypto-js';

const initialState: EnterpriseState =
{
  acc: null,
  configuration: null,
  entity: null,
  establishment: null,
  integration: null,
  inventory: null,
  invoice: null,
  representatives: null,
  taxes: null,
  branchofficesenterprise: null,
  aloha_enterprise: false,
};

export function enterpriseReducer(state: EnterpriseState = initialState, action: EnterpriseActionsUnion): EnterpriseState {
  let key, enterprise;
  switch (action.type) {
    case EnterpriseActionTypes.LOAD:
      key = window.btoa('gfsys_enterprise');
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return initialState;
    case EnterpriseActionTypes.UPDATE:
      key = window.btoa('gfsys_enterprise');
      console.log('EnterpriseActionTypes.UPDATE', action.payload);
      debugger;
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return action.payload;
    case EnterpriseActionTypes.DELETE:
        key = window.btoa('gfsys_enterprise');
        localStorage.removeItem(key);
        return initialState;
    default:
      key = window.btoa('gfsys_enterprise');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualEnterprise: EnterpriseState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualEnterprise
        }
      } else {
        key = window.btoa('gfsys_enterprise');
        enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, enterprise);
        return state;
      }
  }
}

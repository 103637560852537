import { Component, OnInit, Renderer2 } from '@angular/core';
import { EncryptProvider } from '../../../providers/utils/encrypt.provider';
import { UploadProvider } from '../../../providers/upload/upload.provider';
import { HasUserRolePermissionProvider } from '../../../providers/utils/has_user_role_permission.provider';
import { FavoriteMenuProvider } from '../../../providers/client/favorite_menu.provider';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { UserAccountProvider } from '../../../providers/client/user_account.provider';
import { Router } from '@angular/router';


/* Redux */
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Menu } from '../../../states/menu';
import { Body } from '../../../states/body';
import { EnterpriseState } from '../../../states/enterprise';
import { UserState } from '../../../states/user';
import { Update } from '../../../actions/menu.action';
import { Update as UpdateMenu} from '../../../actions/menu.action';

/* END Redux */

@Component({
    selector: 'app-admin-sidebar',
    templateUrl: './admin-sidebar.component.html',
    styleUrls: ['./admin-sidebar.component.scss']
})

export class AdminSidebarComponent implements OnInit {
  public menu$: Observable<Menu[]>;
  private user$: Observable<UserState>;
  public enterprise$: Observable<EnterpriseState>;
  public url_logo: string = '';
  public user: string = '';
  public body$: Observable<Body>;
  public enterprise_state: EnterpriseState = null;
  public menu_state: Menu[] = [];
  public lightScrollbarOptions: any = { axis: 'y', theme: 'light' };
  permissions: any;
  public user_id:any='';
  public enterprise:any='';

  public searchText = ''
  public favorite_menu: any[] = [];
  user_state: UserState;
  public style = '';
  constructor(
    private store: Store<{ menu: Menu[], body: Body }>,
    private userStore: Store<{ user: UserState }>,
    private storeMenu: Store<{ menu: Menu[] }>,
    private storeEnterprise: Store<{enterprise: EnterpriseState}>,
    private encryptService: EncryptProvider,
    private user_menu_gard_state: HasUserRolePermissionProvider,
    private uploadService: UploadProvider,
    private mScrollbarService: MalihuScrollbarService,
    private renderer: Renderer2,
    private favoriteMenuService: FavoriteMenuProvider,
    private userAcountService: UserAccountProvider,
    private router: Router,


  ) {
    this.menu$ = store.pipe(select('menu'));
    this.user$ = this.userStore.pipe(select('user'));
    this.enterprise$ = this.storeEnterprise.pipe(select('enterprise'));
    this.body$ = store.pipe(select('body'));

  }


  hasPermission(type: string){
    if (this.permissions) {
      return this.user_menu_gard_state.verifyGard(type, this.permissions);

    } else {
      return false;
    }
  }

  ngOnInit() {
    this.mScrollbarService.initScrollbar('#mCSB_1', this.lightScrollbarOptions);
    // this.mScrollbarService.initScrollbar('#mCSB_2', this.lightScrollbarOptions);
    this.getUserRedux();
    this.getEnterpriseRedux();
    this.getMenuRedux();
    this.permissions = this.user_menu_gard_state.getPermissions(null, this.enterprise_state.identity);
    this.url_logo = this.uploadService.previewLogoFile();
    this.getFavoritesMenu();
    // this.renderer.


  }
  getFavoritesMenu(){
    this.favoriteMenuService.allFavoriteMenu(this.enterprise_state.entity.db_name, 'useraccount_id', this.user_state.user_account[0].id).subscribe(
      (data: any[]) => {
        this.favorite_menu = data;
        setTimeout(() => {
          this.updateFavoriteMenu()

        }, 5000);
      }
    )
  }

  addFavoriteMenu(id, menu){
    let favorite_menu = {
      _id: null,
      db_name: this.enterprise_state.entity.db_name,
      useraccount_id: this.user_state.user_account[0].id,
      menu_id: id,
      menu: menu
    }
    this.favoriteMenuService.insertFavoriteMenu(this.enterprise_state.entity.db_name, favorite_menu).subscribe(
      (data: any) => {
        favorite_menu._id = data;
        this.favorite_menu.push(favorite_menu)
        this.updateFavoriteMenu()
      }
    )
  }

  deleteFavoriteMenu(id){
    let index = this.favorite_menu.findIndex(v=> v.menu_id == id);
        if (index >= 0) {
          if (this.favorite_menu[index]._id) {
            var _id = this.favorite_menu[index]._id
          } else {
            this.favorite_menu.splice(index, 1);
            return
          }
        }
    this.favoriteMenuService.updateFavoriteMenu(this.enterprise_state.entity.db_name, _id).subscribe(
      (data: any) => {
        if (index >= 0) {
          this.favorite_menu.splice(index, 1);
          this.updateFavoriteMenu()
        }
      }
    )
  }

  updateFavoriteMenu(){
    let index = this.menu_state.findIndex(v=> v.id == 'favorite-menu');
    let children = []
    this.favorite_menu.forEach(element => {
      if (element.menu) {
        children.push(element.menu)
      }
    });
    let menu =  [];

    this.menu_state.forEach(v=> menu.push(v))
    // let menu = this.menu_state
    if (index >= 0) {
      menu[index] = {
        id: 'favorite-menu',
        label: 'Favoritos',
        icon: 'fas fa-star',
        span: 'fa fa-chevron-down',
        active: false,
        href: null,
        children: children,
      }
    } else {
      let menu_fav = {
        id: 'favorite-menu',
        label: 'Favoritos',
        icon: 'fas fa-star',
        span: 'fa fa-chevron-down',
        active: false,
        href: null,
        children: children,
      }
      menu.splice(0, 0, menu_fav);
    }
    let fav_menu = menu
    this.storeMenu.dispatch(new UpdateMenu(fav_menu));

  }



  isFavoriteMenu(menu_id, menu){
    let index = this.favorite_menu.findIndex(v=> v.menu_id == menu_id);
    if (index >= 0) {
      if (!this.favorite_menu[index].menu) {
        this.favorite_menu[index].menu = menu
      }
      return true;
    } else {
      return false;
    }
  }

  includesMenu(label) {
    if (this.searchText.length > 2) {
      if (label.toLowerCase().includes(this.searchText.toLowerCase())) {
        return 'bg-selected';
      } else {
        return '';
      }
    }

  }

  includesChildMenu(menu: Menu) {
    if (this.searchText.length > 2) {
      let index = menu.children.findIndex(v=> v.label.toLowerCase().includes(this.searchText.toLowerCase())
      || v.children.findIndex(x=> x.label.toLowerCase().includes(this.searchText.toLowerCase())) >= 0 )


      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    }

  }

  getMenuRedux(){
    this.menu$.subscribe(
      (data: any) => {
        this.menu_state = data;
        let index = this.menu_state.findIndex(v=> v.id == 'favorite-menu');

        if (index < 0) {
          this.updateFavoriteMenu();
        }
      },
      error => {
        console.log('error menu_state', error);
      }
    )
  }

  setMenu(){
    let a = document.body.classList.contains('nav-sm');
      if (a) {
        return 'scroll-children-sm'
      } else {
        return '';
      }
  }

   getEnterpriseRedux(){
    this.enterprise$.subscribe(
      (data: any) => {
        this.enterprise_state = data;
        this.enterprise = data.entity.db_name;
      },
      error => {
        console.log('error enterprise_state', error);
      }
    )
  }

  getUserRedux() {
    this.user$.subscribe(
      (data: UserState) => {
        if (
          (data.user_account !== null && data.user_account.length > 0) &&
          (data.user_account_roles !== null && data.user_account_roles.length > 0)
        ) {
          this.user_state = data;
          this.user = data.user_account[0].name + ' ' + data.user_account[0].surname;
          this.user_id=data.user_account[0].id
        }
      },
      error => {
        console.log('error getUserRedux()', error);
      }
    )
  }

  collapseMenu(menu: string, submenu: string = '') {
    const key = window.btoa('gfsys_menu');
    if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
      let actualMenu: Menu[] =  JSON.parse(this.encryptService.get(localStorage.getItem(key)));
      if (submenu === '') {
        for (let i = 0; i < actualMenu.length; i++) {
          if (actualMenu[i].label === menu && !actualMenu[i].active) {
            actualMenu[i].active = true;
          } else {
            actualMenu[i].active = false;
          }
        }
      } else {
        for (let i = 0; i < actualMenu.length; i++) {
          for (let j = 0; j < actualMenu[i].children.length; j++) {
            if (actualMenu[i].children[j].label === submenu && !actualMenu[i].children[j].active) {
              actualMenu[i].children[j].active = true;
            } else {
              actualMenu[i].children[j].active = false;
            }
          }
        }
      }
      this.store.dispatch(new Update(actualMenu));
    }
  }

 async goTo(menu: string, submenu: string = '') {    
  debugger;
    let object = {
      id: this.user_id,
      metod: "get",
      status:true
    } 
    const data= await this.userAcountService.updateUserStatus(this.enterprise,object).toPromise();
    if(data.userget[0].active==false){
      this.router.navigate(['/auth/login']);
    }else{
      if(data.userget[0].update_status){
        let object2 = {
          id: this.user_id,
          metod: "put2",
          status:true
        } 
        const data2= await this.userAcountService.updateUserStatus(this.enterprise,object2).toPromise();
        window.location.reload();
      }
    }


    const key = window.btoa('gfsys_menu');
    this.body$.subscribe(
      (data: Body) => {
        let actualMenu: Menu[] =  JSON.parse(this.encryptService.get(localStorage.getItem(key)));
        if (submenu === '') {
          for (let i = 0; i < actualMenu.length; i++) {
            for (let j = 0; j < actualMenu[i].children.length; j++) {
              if (actualMenu[i].children[j].label === menu && !actualMenu[i].children[j].active) {
                actualMenu[i].children[j].active = true;
              } else {
                actualMenu[i].children[j].active = false;
              }
            }
          }
        } else {
          for (let i = 0; i < actualMenu.length; i++) {
            for (let j = 0; j < actualMenu[i].children.length; j++) {
              if (actualMenu[i].children[j].label === submenu && !actualMenu[i].children[j].active && data.class === 'nav-md') {
                actualMenu[i].children[j].active = true;
              } else {
                actualMenu[i].children[j].active = false;
              }
            }
          }
        }
        /*console.log('menu', menu, 'submenu', submenu);
        console.log('actualMenu', actualMenu);*/
        //this.store.dispatch(new Update(actualMenu));
      }
    );
  }

  fullScreen() {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }

}

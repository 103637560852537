import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-no-register-layout',
    templateUrl: './no-register-layout.component.html',
    styleUrls: ['./no-register-layout.component.scss']
})

export class NoRegisterLayoutComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.removeClass(document.body, 'auth');
    this.renderer.addClass(document.body, 'nav-md');
  }

}

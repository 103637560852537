import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-price-list-branch-office-configuration-modal',
  templateUrl: './price-list-branch-office-configuration.modal.html',
  styleUrls: ['./price-list-branch-office-configuration.modal.scss'],
})

export class PriceListBranchOfficeConfigurationModal {
  public searchFilterText: string = '';
  public searchText: string = '';
  public advance = {
    value: null,
    motive: null,
  }
  public advances = []

  constructor(
    public dialogRef: MatDialogRef<PriceListBranchOfficeConfigurationModal>,


    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data', JSON.stringify(data));
  }

  add(){
    if ((this.advance.value>0) && (this.advance.motive)) {
      this.advances.push(this.advance);
    }
  }

  newAdvance($event){
    if (this.data.type == 'cin') {
      debugger

      if (this.data.clients_providers.length == 1) {
        $event.data.client = this.data.clients_providers[0].id;
      }
    }else{
      if (this.data.clients_providers.length == 1) {
        $event.data.client = this.data.clients_providers[0].id;
      }
    }
  }

  changeDefault() {
    if(this.data.branch_office_emission_point.default_bo){
      this.data.branch_office_emission_point.active = true;
    }
  }
  close(): void {
    debugger;
     this.dialogRef.close(this.data.branch_office_emission_point);
  }
}

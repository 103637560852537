import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PredCostCenter } from '../../models/client/PredCostCenter';
import { URLProvider } from '../url.providers';

@Injectable()
export class PredCostCenterProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allPredCostCenter(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<PredCostCenter[]>(this.urlProvider.pred_cost_center(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<PredCostCenter[]>(this.urlProvider.pred_cost_center(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getPredCostCenter(enterprise: string, id: number) {
    return this.http.get<PredCostCenter>(this.urlProvider.pred_cost_center(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertPredCostCenter(enterprise: string, pred_cost_center: PredCostCenter) {
    return this.http.post<number>(this.urlProvider.pred_cost_center(enterprise), pred_cost_center, this.urlProvider.httpOptions);
  }

  public updatePredCostCenter(enterprise: string, pred_cost_center: PredCostCenter, id: string) {
    return this.http.put<Boolean>(this.urlProvider.pred_cost_center(enterprise) + '/' + id, pred_cost_center, this.urlProvider.httpOptions);
  }

}

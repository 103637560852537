import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxBarcodeModule } from 'ngx-barcode';

import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

/* COMPONENTS */
import { AdminSidebarComponent } from './components/admin/sidebar/admin-sidebar.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
/* END COMPONENTS */

/* Layouts */
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { NoRegisterLayoutComponent } from './layouts/no-register/no-register-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* END Layouts*/

/* Redux */
import { StoreModule } from '@ngrx/store';
import { menuReducer } from './reducers/menu.reducer';
import { uploadReducer } from './reducers/upload.reducer';
import { enterpriseCreateReducer } from './reducers/enterprise_user_create.reducer';
import { enterpriseReducer } from './reducers/enterprise.reducer';
import { userReducer } from './reducers/user.reducer';
import { userWebSocketReducer } from './reducers/user_websocket.reducer';
import { sessionReducer } from './reducers/session.reducer';
import { invoiceReducer } from './reducers/invoice.reducer';
import { userMenuGardsReducer } from './reducers/user_menu_gards.reducer';
import { breadcrumbReducer } from './reducers/breadcrumb.reducer';
import { bodyReducer } from './reducers/body.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
/* END Redux */

/* Providers */
import { EncryptProvider } from './providers/utils/encrypt.provider';
import { LoginAuthenticateProvider } from "./providers/admin/login-authenticate.provider";
import { URLProvider } from './providers/url.providers';
import { DomainsProvider } from "./providers/domains.provider";
import { UploadProvider } from './providers/upload/upload.provider';
import { GridPermissionsProvider } from './providers/client/grid_permissions.provider';
import { GridPermissionsUtilProvider } from './providers/utils/grid-permissions-util.provider';
import { UserMenuProvider } from "./providers/client/user_menu.provider";
import { UserAccountProvider } from "./providers/client/user_account.provider";
import { UserAccountRoleProvider } from "./providers/client/user_account_role.provider";
import { UserRolePermisionProvider } from "./providers/client/user_role_permisions.provider";
import { RoleProvider } from './providers/client/role.provider';
import { CreateMenuProvider } from './providers/utils/create_menu.provider';
import { SpeedTestProvider } from './providers/utils/speed_test.provider';
import { WebsocketProvider } from './providers/utils/websocket.provider';
import { UserProvider } from './providers/admin/user.provider';
import { LoginProvider } from "./providers/admin/login.provider";
import { LoginRefreshProvider } from "./providers/utils/login-refresh.provider";
import { FavoriteMenuProvider } from './providers/client/favorite_menu.provider';
import { NotificationProvider } from './providers/client/notifications.provider';
import { SendNotificationUtilProvider } from "./providers/utils/send_notifications.provider";
import { PieceReservationRuleProvider } from "./providers/client/piece-reservation-rule.provider";
import { GetStockPieceProvider } from "./providers/utils/get_stock_piece.provider";


/* END Providers */

/* Firebase */
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
/* END Firebas */

/* Modal */
import { SupportModal } from './components/modals/support/support.modal';
import { LoadModal } from './components/modals/load/load.modal';
import { YesNoConfirmationModal } from './components/modals/yes-no-confirmation/yes-no-confirmation.modal';
import { YesNoConfirmationAPModal } from './components/modals/yes-no-confirmation-ap/yes-no-confirmation-ap.modal';
import { YesNoConfirmationBOModal } from './components/modals/yes-no-confirmation-bo/yes-no-confirmation-bo.modal';
import { DateFromUntilModal } from './components/modals/date-from-until/date-from-until.modal';
import { DatepickerModal } from './components/modals/datepicker/datepicker.modal';
import { RetentionDateFromUntilModal } from './components/modals/retention/date-from-until/date-from-until.modal';
import { FilterPurchaseModal } from './components/modals/filter-purchase/filter-purchase.modal';
import { SignByBatchModal } from './components/modals/sign-by-batch/sign-by-batch.modal';
import { StockWarningModal } from './components/modals/stock-warning/stock-warning.modal';
import { ForwardByBatchModal } from './components/modals/forward-by-batch/forward-by-batch.modal';
import { CancelByBatchModal } from './components/modals/cancel-by-batch/cancel-by-batch.modal';
import { InvoiceMailSendModal } from './components/modals/invoice-mail-send/invoice-mail-send.modal';
import { RetentionMailSendModal } from './components/modals/retention-mail-send/retention-mail-send.modal';
import { ClearanceMailSendModal } from './components/modals/clearance-mail-send/clearance-mail-send.modal';
import { ReciveRetentionModal } from './components/modals/recive-retention/recive-retention.modal';
import { DeletePurchaseModal } from './components/modals/delete-purchase/delete-purchase.modal';
import { CinCegOtherPaymentsModal } from './components/modals/cin-ceg-other-payments/cin-ceg-other-payments.modal';
import { InvoiceQuantityReturnedModal } from './components/modals/invoice-quantity-left/invoice-quantity-left.modal';
import { IncomePaymentVouchersModal } from './components/modals/income-payment-vouchers/income-payment-vouchers.modal';
import { EgressPaymentVouchersModal } from './components/modals/egress-payment-vouchers/egress-payment-vouchers.modal';
import { EgressAdvanceOthersModal } from './components/modals/egress-advance-others/egress-advance-others.modal';
import { EgressRedimirModal } from './components/modals/egress-redimir/egress-redimir.modal';
import { RepairsItemsModal } from './components/modals/repairs_items/repairs_items.modal';
import { EgressPaymentProofListModal } from './components/modals/egress-payment-proof-list/egress-payment-proof-list.modal'
import { ProductionOrderSuppliesModal } from './components/modals/production-order-inv-supplies/production-order-inv-supplies.modal';
import { PendingItemsDetail } from './components/modals/pending-items/pending-items.modal';
import { AddDecrease } from './components/modals/add_decrease/add_decrease.modal';
import { AddVoucherImageModal } from './components/modals/add-voucher-image/add-voucher-image.modal';
import { getTicketsModal } from './components/modals/get-tickets-modal/get-tickets.modal';
import { PriceListBranchOfficeConfigurationModal } from './components/modals/price-list-branch-office-configuration/price-list-branch-office-configuration.modal';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RetentionProcessModal } from './components/modals/retention-process/retention-process.modal';
// import { AddLot } from './components/modals/add_lot/add_lot.modal';

import { AdvancesModal } from './components/modals/advances/advances.modal';
import { OrdersModal } from "./components/modals/orders/orders.modal";
import { ProductCatalogModal } from "./components/modals/product-catalog/product-catalog.modal";

import { VouchersRetentionsComissionsModal } from './components/modals/vouchers-retentions-comissions/vouchers-retentions-comissions.modal';
import { ProductFiltersComponent } from './components/modals/product-filters/product-filters.modal';

import { IncomePaymentRedeemModal } from './components/modals/income-payment-redeem/income-payment-redeem.modal';
import { BuyPurchaseOrderModal } from './components/modals/buy-purchase-order/buy-purchase-order.modal';
import { BuyProductionOrderModal } from './components/modals/buy-production-order/buy-production-order.modal';
import { ProductsInPiece } from './components/modals/products-in-piece/products-in-piece.modal';
import { IncomePaymentProofMainProvider } from './providers/client/income_payment_proof_main.provider';
import { AjeGroupProvider } from './providers/client/aje_group.provider';

import { RefoundInvoiceModal } from './components/modals/refund_invoice/refund_invoice.modal';
import { InvoiceListDeliveryModal } from './components/modals/invoice-list-delivery-note/invoice-list-delivery.modal';
import { InvoiceListDeliveryModalFilterPipe } from './components/modals/invoice-list-delivery-note/invoice-list-delivery-filter.pipe';
import { MenuListFilter } from './components/admin/sidebar/menu-list-filter.pipe';

import { ProductWarehouseDetail } from './components/modals/product-warehouse-detail/product-warehouse-detail.modal';
import { InvoiceHasRetention } from './components/modals/invoice-has-retention/invoice-has-retention.modal';
import { ODPModal } from './components/modals/odp-modal/odp.modal';
import { CredentialModal } from './components/modals/credential-modal/credential.modal';
import { PromotionsAbleModal } from './components/modals/promotions-able/promotions-able.modal';
import { UserRefreshLogin } from './components/modals/user-refresh-login/user-refresh-login.modal';
import { MoreInformationModal } from './components/modals/more-information/more-information.modal';
import { GetSriSignatureModal } from './components/modals/get-sri-signature/get-sri-signature.modal';
// import { PriceListComponent } from './components/price-list/price-list.component';
/* END Modal */

/* MATERIAL */
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//import { NgxSoundsModule } from 'ngx-sounds';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
/* END MATERIAL */
// Toast snackbar
import { ToastrModule } from 'ngx-toastr';

// NGX electron
import { NgxElectronModule } from 'ngx-electron';

/* Dialog transparent */
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModal } from './components/modals/loading-modal/loading-modal.modal';
/* END Dialog transparent */
import { ChangeAppProvider } from "./providers/client/change_app.provider";
import { HasUserRolePermissionProvider } from './providers/utils/has_user_role_permission.provider';

import {
  DxSelectBoxModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxDataGridModule
} from 'devextreme-angular';


import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY',
  },
};

import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';

// sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// const config: SocketIoConfig = {
//   url: environment.wsUrl , options: {secure: true}
// };
import {
  DxFileUploaderModule,

} from 'devextreme-angular';
import {HttpInterceptorProviders} from "./interceptors";
import { AjeGroupComponent } from './external-integrations/aje-group/aje-group.component';
import { MainLoadingMaskComponent } from './shared/components/main-loading-mask/main-loading-mask.component';

@NgModule({
  declarations: [
    AppComponent,
    // PriceListComponent,
    AuthLayoutComponent,
    AdminLayoutComponent,
    AdminSidebarComponent,
    NoRegisterLayoutComponent,
    TopBarComponent,
    LoadModal,
    DateFromUntilModal,
    RetentionDateFromUntilModal,
    DatepickerModal,
    SignByBatchModal,
    RetentionProcessModal,
    StockWarningModal,
    FilterPurchaseModal,
    ForwardByBatchModal,
    CancelByBatchModal,
    InvoiceMailSendModal,
    RetentionMailSendModal,
    ClearanceMailSendModal,
    ReciveRetentionModal,
    YesNoConfirmationModal,
    YesNoConfirmationAPModal,
    YesNoConfirmationBOModal,
    DeletePurchaseModal,
    SupportModal,
    LoadingModal,
    IncomePaymentVouchersModal,
    EgressPaymentVouchersModal,
    EgressAdvanceOthersModal,
    EgressRedimirModal,
    InvoiceQuantityReturnedModal,
    CinCegOtherPaymentsModal,
    AdvancesModal,
    OrdersModal,
    ProductCatalogModal,
    IncomePaymentRedeemModal,
    VouchersRetentionsComissionsModal,
    ProductFiltersComponent,
    BuyPurchaseOrderModal,
    BuyProductionOrderModal,
    ProductsInPiece,
    RefoundInvoiceModal,
    ProductWarehouseDetail,
    ODPModal,
    CredentialModal,
    InvoiceHasRetention,
    MoreInformationModal,
    InvoiceListDeliveryModal,
    InvoiceListDeliveryModalFilterPipe,
    MenuListFilter,
    RepairsItemsModal,
    ProductionOrderSuppliesModal,
    EgressPaymentProofListModal,
    PromotionsAbleModal,
    PendingItemsDetail,
    UserRefreshLogin,
    AddDecrease,
    AddVoucherImageModal,
    getTicketsModal,
    PriceListBranchOfficeConfigurationModal,
    GetSriSignatureModal,
    AjeGroupComponent,
    MainLoadingMaskComponent,
    // AddLot
   ],
  imports: [
    StoreModule.forRoot(
      {
        menu: menuReducer,
        upload: uploadReducer,
        enterprise: enterpriseReducer,
        enterprise_create: enterpriseCreateReducer,
        user: userReducer,
        session: sessionReducer,
        invoice: invoiceReducer,
        breadcrumb: breadcrumbReducer,
        body: bodyReducer,
        user_menu_gards: userMenuGardsReducer,
        user_websocket: userWebSocketReducer
      }, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    AngularFireModule.initializeApp(environment.firebase),
    NgxMaskModule.forRoot(options),
    AngularFirestoreModule,
    BrowserModule,
    FormsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxBarcodeModule,
    NgxElectronModule,
    HttpClientModule,
    ModalModule.forRoot(),
    LoadingBarHttpModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    DxDataGridModule,
    NgxMaterialTimepickerModule,
    MalihuScrollbarModule.forRoot(),
    //NgxSoundsModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxFileUploaderModule,
    DxSelectBoxModule,
    NgbModule
    // SocketIoModule.forRoot(config),
  ],
  providers: [
    AjeGroupProvider,
    EncryptProvider,
    LoginAuthenticateProvider,
    URLProvider,
    DomainsProvider,
    UploadProvider,
    {provide: MAT_DATE_LOCALE, useValue:  'co'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    UserMenuProvider,
    UserAccountProvider,
    UserAccountRoleProvider,
    UserRolePermisionProvider,
    RoleProvider,
    CreateMenuProvider,
    SpeedTestProvider,
    HasUserRolePermissionProvider,
    GridPermissionsProvider,
    GridPermissionsUtilProvider,
    WebsocketProvider,
    UserProvider,
    LoginProvider,
    LoginRefreshProvider,
    FavoriteMenuProvider,
    NotificationProvider,
    SendNotificationUtilProvider,
    PieceReservationRuleProvider,
    GetStockPieceProvider,
    IncomePaymentProofMainProvider,
    ChangeAppProvider,
    HttpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoadModal,
    DateFromUntilModal,
    SignByBatchModal,
    RetentionProcessModal,
    StockWarningModal,
    FilterPurchaseModal,
    ForwardByBatchModal,
    CancelByBatchModal,
    InvoiceMailSendModal,
    RetentionMailSendModal,
    ClearanceMailSendModal,
    ReciveRetentionModal,
    RetentionDateFromUntilModal,
    DatepickerModal,
    YesNoConfirmationModal,
    YesNoConfirmationAPModal,
    YesNoConfirmationBOModal,
    DeletePurchaseModal,
    SupportModal,
    LoadingModal,
    IncomePaymentVouchersModal,
    EgressPaymentVouchersModal,
    EgressAdvanceOthersModal,
    EgressRedimirModal,
    InvoiceQuantityReturnedModal,
    IncomePaymentVouchersModal,
    CinCegOtherPaymentsModal,
    AdvancesModal,
    OrdersModal,
    ProductCatalogModal,
    IncomePaymentRedeemModal,
    VouchersRetentionsComissionsModal,
    ProductFiltersComponent,
    BuyPurchaseOrderModal,
    BuyProductionOrderModal,
    ProductsInPiece,
    RefoundInvoiceModal,
    ProductWarehouseDetail,
    ODPModal,
    CredentialModal,
    InvoiceHasRetention,
    MoreInformationModal,
    InvoiceListDeliveryModal,
    RepairsItemsModal,
    ProductionOrderSuppliesModal,
    EgressPaymentProofListModal,
    PromotionsAbleModal,
    PendingItemsDetail,
    UserRefreshLogin,
    AddDecrease,
    AddVoucherImageModal,
    getTicketsModal,
    PriceListBranchOfficeConfigurationModal,
    GetSriSignatureModal
    // AddLot
    // PriceListComponent
  ]
})
export class AppModule { }


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Purchase } from '../../models/client/Purchase';
import { PurchaseDetail } from '../../models/client/PurchaseDetail';
import { UpdateToken } from '../../models/client/UpdateToken';
import { ViewPaginator } from '../../models/client/ViewPaginator';
import { URLProvider } from '../url.providers';

@Injectable()
export class PurchaseProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allPurchase(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getExistePurchase(enterprise: string, ak:string) {
    return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise) + '?' + 'auth_sri='+ak, this.urlProvider.httpOptions).toPromise();
  }

  public validateStockBuy06(enterprise: string, buy_01main_id:string) {
    return this.http.get<any[]>(this.urlProvider.purchase(enterprise) + '/validatestock06?' + 'buy_01main_id='+buy_01main_id, this.urlProvider.httpOptions).toPromise();
  }


  public viewBuyPurchases(enterprise: string, per_page_value: number,  token: string = "", value: string = "", token2: string = "", value2: string = "") {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/view?'+token+'='+value+'&row=' + per_page_value+ '&' + token2 + '=' + value2 , this.urlProvider.httpOptions);
  }

  public viewBuyPurchaseslist(enterprise: string, start_date, end_date: string = '', per_page_value: number  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/view?list=true&'+'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public filterBuyPurchaseslist(enterprise: string, filter: string = '', per_page_value: number  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/list?'+filter+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public getDocumentTypelist(enterprise: string  ) {
    return this.http.get<string[]>(this.urlProvider.purchase(enterprise) + '/document_types' , this.urlProvider.httpOptions);
  }

  public viewBuylist(enterprise: string, start_date, end_date: string = '', per_page_value: number  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/list?'+'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public viewBuylistAnnulment(enterprise: string, start_date, end_date: string = '', per_page_value: number  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/list?'+'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date+'&status_intern=ANULADO'+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }
  public viewBuylistAnnulmentAll(enterprise: string, filter, per_page_value: number  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/list?'+filter+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public viewBuyPurchasesmailbox(enterprise: string,  per_page_value: number, branch_offices_id : string  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/mailbox?' + 'branch_offices_id=' + branch_offices_id+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public viewBuyPurchasesmailboxSearch(enterprise: string,  per_page_value: number, search : string  ) {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/mailbox?' + 'search=' + search+'&row=' + per_page_value , this.urlProvider.httpOptions);
  }

  public nextPreviousPagePurchases(url: string, per_page_value: number, branch_offices_id : string , filter:string='' ) {
    debugger;
    return this.http.get<ViewPaginator>(url + '&branch_offices_id=' + branch_offices_id+ '&row=' + per_page_value +filter, this.urlProvider.httpOptions);
  }

  public nextPreviousPagePurchasesAnnulment(url: string, per_page_value: number, filter:string='' ) {
    debugger;
    return this.http.get<ViewPaginator>(url + '&row=' + per_page_value +filter, this.urlProvider.httpOptions);
  }

  public getValidaPurchase(enterprise: string, identification: string, tipo_documento: string = '', document_number: string = '') {
    return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise) + '?' + 'identification='+identification+'&sri_vouchers_types_id='+tipo_documento+'&document_number='+document_number, this.urlProvider.httpOptions);
  }

 public allViewPurchase(enterprise: string, value: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.purchase(enterprise) + '/view?'+ value, this.urlProvider.httpOptions);

  }

  public getPurchaseNextPreviousPage(url, filter = '') {
    return this.http.get<ViewPaginator>(url+'&'+ filter, this.urlProvider.httpOptions);
  }


  public getListPurchases(enterprise: string, start_date, end_date: string = '') {
    return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise) + '/list' + '?' + 'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date, this.urlProvider.httpOptions);
  }

  public getListAnnulmentPurchases(enterprise: string, document_number:string, status_intern:string ,identification:string) {
    return this.http.get<Purchase[]>(this.urlProvider.purchase(enterprise) + '/bystatus' + '?' + 'identification' + '=' + identification+ '&document_number' + '=' + document_number+'&status_intern='+status_intern , this.urlProvider.httpOptions);
  }

  public getDuplicatePurchase(enterprise: string, document_id:string) {
    return this.http.get<any>(this.urlProvider.purchase(enterprise) + '/duplicatebuy/' + document_id, this.urlProvider.httpOptions);
  }

  public allPurchaseDetail(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<PurchaseDetail[]>(this.urlProvider.purchasetaxbase(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<PurchaseDetail[]>(this.urlProvider.purchasetaxbase(enterprise), this.urlProvider.httpOptions);
    }
  }


  public getPurchase(enterprise: string, id: string) {
    return this.http.get<Purchase>(this.urlProvider.purchase(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertPurchase(enterprise: string, purchase: Purchase) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise), purchase, this.urlProvider.httpOptions);
  }

  public pdfPurchaseComp(enterprise: string, id: string) {
    return this.urlProvider.pdfComprobante(enterprise)+ '/COM/' + id;
  }
  //      return this.dominio + 'pdf/'+ id+'/'+ enterprise ;

  //CARGA INICIAL
  
  public insertInitialCharge(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise)+ '/initialcharge' , initialCharge, this.urlProvider.httpOptions);
  }

  public validateCxP(enterprise: string, initialCharge: any) {
    return this.http.post(this.urlProvider.purchase(enterprise)+ '/validatecxp' , initialCharge, this.urlProvider.httpOptions);
  }

  public insertInitialChargeAntProv(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise)+ '/initialchargeant' , initialCharge, this.urlProvider.httpOptions);
  }

  public insertInitialChargeNcProv(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise)+ '/initialchargencant' , initialCharge, this.urlProvider.httpOptions);
  }

  public insertInitialChargeNcCli(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise)+ '/initialchargenccli' , initialCharge, this.urlProvider.httpOptions);
  }

  public insertInitialChargeCXC(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.invoice(enterprise)+ '/initialchargecxc' , initialCharge, this.urlProvider.httpOptions);
  }

  public insertInitialChargeAntCli(enterprise: string, initialCharge: any) {
    return this.http.post<number>(this.urlProvider.purchase(enterprise)+ '/initialchargeantcli' , initialCharge, this.urlProvider.httpOptions);
  }

  public pdfPurchase(enterprise: string, id: string) {
    return this.urlProvider.purchase_pdf(enterprise,id);
  }

  public pdfPurchaseMain(enterprise: string, id: string) {
    return this.urlProvider.purchase_pdf(enterprise, id);
  }

  public insertPurchaseDetail(enterprise: string, purchasedetail: PurchaseDetail) {
    return this.http.post<number>(this.urlProvider.purchasetaxbase(enterprise), purchasedetail, this.urlProvider.httpOptions);
  }


  public updatePurchase(enterprise: string, purchase: any,  id: number) {
    return this.http.put<Boolean>(this.urlProvider.purchase(enterprise) + '/' + id, purchase, this.urlProvider.httpOptions);
  }

  public annulmentPurchase(enterprise: string, purchase: any) {
    return this.http.put<any>(this.urlProvider.purchase(enterprise) + '/annulmentpurchase' , purchase, this.urlProvider.httpOptions);
  }

  public deleteFisicoPurchase(enterprise: string,  id: number) {
    return this.http.delete<Boolean>(this.urlProvider.purchase(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public deleteBuy07(enterprise: string,  id: number) {
    return this.http.delete<any>(this.urlProvider.purchase(enterprise) + '/deletebuy07/' + id, this.urlProvider.httpOptions);
  }

  public deletePurchase(enterprise: string, user: UpdateToken,  id: number) {
    return this.http.put<Boolean>(this.urlProvider.purchase(enterprise) + '/delete/' + id, user , this.urlProvider.httpOptions);
  }

  public updatePurchaseDetail(enterprise: string, purchasedetail: PurchaseDetail, id: number) {
    return this.http.put<Boolean>(this.urlProvider.purchasetaxbase(enterprise) + '/' + id, purchasedetail, this.urlProvider.httpOptions);
  }

  public getDetailMasive(enterprise: string,  detail: excelExportInventoryData[], otherParams: any = '') {
    return this.http.post<any[]>(this.urlProvider.purchase(enterprise) + '/extracto?'+otherParams,detail, this.urlProvider.httpOptions);
  }

  public getDetailMasivePO(enterprise: string,  detail: any[]) {
    return this.http.post<any[]>(this.urlProvider.purchase(enterprise) + '/masivepurchaseorder',detail, this.urlProvider.httpOptions);
  }

}


export interface excelExportInventoryData {
  bar_code: string;
  code_lot : string;
  inv_piece: string;
  quantity: number;
  unit_cost:number;
  rate_name: string;
  warehouse_name:string;
}

import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-main-loading-mask',
  templateUrl: './main-loading-mask.component.html',
  styleUrls: ['./main-loading-mask.component.scss']
})
export class MainLoadingMaskComponent {
  constructor(
    protected readonly loadingService: LoadingService,
  ) {
  }
}

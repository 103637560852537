import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { FavoriteMenu } from '../../models/client/FavoriteMenu';
import { URLProvider } from '../url.providers';

@Injectable()
export class FavoriteMenuProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allFavoriteMenu(enterprise: string,  token: string = '',value: string = '') {
    if (value !== '') {
      return this.http.get<any[]>(this.urlProvider.favoritemenu(enterprise) + '?'+  token+'=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<any[]>(this.urlProvider.favoritemenu(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getFavoriteMenu(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.favoritemenu(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertFavoriteMenu(enterprise: string, FavoriteMenu: any) {
    return this.http.post<boolean>(this.urlProvider.favoritemenu(enterprise), FavoriteMenu, this.urlProvider.httpOptions);
  }

  public updateFavoriteMenu(enterprise: string, id: string) {
    return this.http.delete<Boolean>(this.urlProvider.favoritemenu(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

}

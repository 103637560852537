import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { UserWebsocket } from '../../states/UserWebsocket';
// import { Usuario } from '../classes/usuario';
import * as moment from 'moment';
import * as moment_timezone from "moment-timezone" ;
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SendNotificationUtilProvider {

  public socketStatus = false;
  public user_websocket$: Observable<UserWebsocket>;
  public user_websocket_state: UserWebsocket = null;

  constructor(
    // private socket: Socket,
    private _snackBar: MatSnackBar,
    private toastrService: ToastrService,
    private storeUserWebsocket: Store<{ session: UserWebsocket}>,
  ) {
    
    // this.cargarStorage();
  }

  sendNotification(notifications) {
      debugger
      notifications.forEach(element => {
        element.date = moment().format('YYYY-MM-DD HH:mm');
        element.timezone = moment_timezone.tz.guess(true);
      });
      this.emit('catch-notification', notifications);
      return true;
  }

  showSuccess(message, title) {
    this.toastrService.success(message, `${title}`, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center'
    });
  }

  showError(error, mensaje) {
    this.toastrService.error(error, `${mensaje}`, {
      timeOut: 40000,
      positionClass: 'toast-bottom-center',
    });
}

  emit( event: string, payload?: any, callback?: Function ) {

    console.log('Emitiendo', event);
    // emit('event', payload, callback?)
    // this.socket.emit( event, payload, callback );

  }

  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 6000
    });
  }




}

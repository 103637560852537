import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProductionOrderMain } from '../../models/client/ProductionOrderMain';
import { ProductionOrderItemsInsert } from '../../models/client/ProductionOrderItem';
import { ViewPaginator } from '../../models/client/ViewPaginator';

import { URLProvider } from '../url.providers';

@Injectable()
export class ProductionOrderMainProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allProductionOrders(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<ProductionOrderMain[]>(this.urlProvider.productionordermain(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<ProductionOrderMain[]>(this.urlProvider.productionordermain(enterprise), this.urlProvider.httpOptions);
    }
  }

  public productionOrderEndByProvider(enterprise: string, provider: string ) {
    return this.http.get<ViewProductionOrders[]>(this.urlProvider.productionordermain(enterprise) + '/endprovider/' + provider, this.urlProvider.httpOptions);

  }


  public allViewProductionOrders(enterprise: string, filters: string = '',) {
    return this.http.get<ViewPaginator>(this.urlProvider.productionordermain(enterprise) + '/view?' + filters , this.urlProvider.httpOptions);
  }

  public getPendingProductionOrders(enterprise: string) {
    return this.http.get<number>(this.urlProvider.productionordermain(enterprise) + '/pending' , this.urlProvider.httpOptions);
  }

  public getPendingInvoiceProductionOrders(enterprise: string, branch_office_id='') {
    return this.http.get<number>(this.urlProvider.productionordermain(enterprise) + '/pending-invoice?branch_office_id='+ branch_office_id, this.urlProvider.httpOptions);
  }
  public nextPreviousPageProductionOrders(url: string, per_page_value: number = 10, filters = '') {
    return this.http.get<ViewPaginator>(url + '&row=' + per_page_value +'&'+ filters, this.urlProvider.httpOptions);
  }

  public getProductionOrdersList(enterprise: string, start_date: string = '', end_date: string = '') {
    if (start_date !== '' && end_date !== '') {
      return this.http.get<ProductionOrderMain[]>(this.urlProvider.productionordermain(enterprise) + '/list?start_date=' + start_date + '&end_date=' + end_date, this.urlProvider.httpOptions);
    } else {
      return this.http.get<ProductionOrderMain[]>(this.urlProvider.productionordermain(enterprise) + '/list', this.urlProvider.httpOptions);
    }
  }

  public getNextSequential(enterprise: string, branch_office_tradename: string, project_name: string) {
    return this.http.get<string>(this.urlProvider.productionordermain(enterprise) + '/nextsequential?branch_office_tradename=' + branch_office_tradename + '&project_name=' + project_name, this.urlProvider.httpOptions);
  }

  public getProductionOrder(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.productionordermain(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }




  public getProductionOrderPdf(enterprise: string, id: string) {
    return this.urlProvider.productionordermain(enterprise) + '/viewpdf/' + id;
  }
  public getProductionOrderPrinter(enterprise: string, id: string) {
    return this.urlProvider.productionordermain(enterprise) + '/viewprinter/' + id;
  }
  public insertProductionOrder(enterprise: string, insert: Insert) {
    return this.http.post<Boolean>(this.urlProvider.productionordermain(enterprise), insert, this.urlProvider.httpOptions);
  }

  public updateProductionOrder(enterprise: string, insert: Insert, id: string) {
    return this.http.put<Boolean>(this.urlProvider.productionordermain(enterprise) + '/' +id, insert, this.urlProvider.httpOptions);
  }

  public getProductionOrderExcelData(enterprise: string, id: number) {
    return this.http.get<ProductionOrderExcelData[]>(this.urlProvider.getProductionOrderExcelData(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getProductionOrderExcelDataByDate(enterprise: string, date: string) {
    return this.http.get<any>(this.urlProvider.productionordermain(enterprise) + '/productiondetailbydate?date=' + date, this.urlProvider.httpOptions);
  }

  public sendProductionMail(enterprise: string, date: string) {
    return this.http.get<any>(this.urlProvider.productionordermain(enterprise) + '/report?date=' + date, this.urlProvider.httpOptions);
  }



  //

  public deleteProductionOrder(enterprise: string, updated_by: string, id: number) {
    return this.http.put<Boolean>(this.urlProvider.productionordermain(enterprise) + '/delete/' +id, {updated_by: updated_by}, this.urlProvider.httpOptions);
  }

  public getDeliverProductionOrder(enterprise: string, branch_office_id: string, search_value = '', filter = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.productionordermain(enterprise) + '/getdeliver?branch_office_id='+branch_office_id+'&search='+search_value + filter, this.urlProvider.httpOptions);
  }

  public getProductionOrderEnd(enterprise: string) {
    return this.http.get<ViewPaginator>(this.urlProvider.productionordermain(enterprise) + '/end', this.urlProvider.httpOptions);
  }


  public getDeliverProductionOrderReception(enterprise: string,  search_value = '', filter = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.productionordermain(enterprise) + '/getdeliver?search='+search_value + filter, this.urlProvider.httpOptions);
  }


}

export interface Insert {
  productionorder01main: ProductionOrderMain;
  items: ProductionOrderItemsInsert;
}

export interface ProductionOrderExcelData {
  filters: any[];
  attrib: any[];
  product: any;
  codigo: any[];
}

export interface ViewProductionOrders {
  id?:number;
  document_id?:number;
  item_id?:number;
  document_number: string;
  date_production_order: string;
  identification_client: string;
  name_client: string;
  deliver_date: string;
  bar_code: string;
  name_product: string;
  inv_product_id: number;
  quantity: number;
  rate_pct: number;
  subtotal: number;
  tax_base: number;
  vat: number;
  total: number;
}

import { SessionActionTypes, SessionActionsUnion } from '../actions/session.action';
import { SessionState } from '../states/session';


import * as CryptoJS from 'crypto-js';

const initialState: SessionState =
{
  email: null,
  token: null,
};

export function sessionReducer(state: SessionState = initialState, action: SessionActionsUnion): SessionState {
  let key, user;
  switch (action.type) {
    case SessionActionTypes.LOAD:
      key = window.btoa('gfsys_session');
      user = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user);
      return initialState;
    case SessionActionTypes.UPDATE:
      key = window.btoa('gfsys_session');
      user = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, user);
      return action.payload;
    default:
      key = window.btoa('gfsys_session');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualSession: SessionState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualSession
        }
      } else {
        key = window.btoa('gfsys_session');
        user = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, user);
        return state;
      }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public readonly loading = new BehaviorSubject<boolean>(false);
  /**
   * Contains in-progress loading requests
   */
  private loadingMap = {};

  constructor(
    private readonly router: Router,
  ) {
    this.listenRouter();
  }

  /**
   *
   * @param loading
   * @param url
   */
  public setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error(
        'The request URL must be provided to the LoadingService.setLoading function',
      );
    }
    if (loading) {
      this.loadingMap[url] = loading;
      this.loading.next(true);
    } else if (!loading && this.loadingMap[url]) {
      delete this.loadingMap[url];
    }
    if (Object.keys(this.loadingMap).length === 0) {
      this.loading.next(false);
    }
  }

  private listenRouter(): void {
    this.router.events.subscribe({
      next: (value) => {
        if (value instanceof NavigationStart) {
          this.setLoading(true, value.url);
        }
        if (
          value instanceof NavigationEnd ||
          value instanceof NavigationCancel ||
          value instanceof NavigationError
        ) {
          this.setLoading(false, value.url);
        }
      },
    });
  }

  public listen<T>(): (source: Observable<T>) => Observable<T> {
    return (source) => {
      let ref: string;
      return defer(() => {
        ref = Object.keys(this.loadingMap).length.toString();
        this.setLoading(true, `loader-${ref}`);
        return source.pipe(
          tap({
            next: _ => this.setLoading(false, `loader-${ref}`),
            error: _ => this.setLoading(false, `loader-${ref}`),
          })
        );
      });
    };
  }
}

import { Component, Inject, OnInit, AfterViewInit, ViewChild , OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Product } from '../../../models/client/Product';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
// import { ProductionOrderMainProvider } from '../../../providers/client/production_order_main.provider';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ProductProvider } from '../../../providers/client/product.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { GetDeliver } from '../../../models/client/GetDeliver';
import { ProductLot } from '../../../models/client/ProductLot';
import { BuyInvReceptionProvider } from '../../../providers/client/buy_inv_reception.provider';
import { WarehouseProvider } from '../../../providers/client/warehouse.provider';
import { Warehouse } from '../../../models/client/Wharehouse';

import { DxDataGridComponent } from "devextreme-angular";

/* END Redux */

@Component({
  selector: 'app-pending-items-modal',
  templateUrl: './pending-items.modal.html',
  styleUrls: ['./pending-items.modal.scss'],
})

export class PendingItemsDetail implements AfterViewInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  public showSpinner: boolean = false;
  public pending_array: pendingItems[]=[];
  public warehouses: Warehouse[]=[];
  public pending_view_paginator : ViewPaginator;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  //public block_stock:InventoryTransferItem;
  public warehouse_details_selected :ProductLot[] = [];
  public products_view_paginator: ViewPaginator = null;
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();


    constructor(
      public dialogRef: MatDialogRef<PendingItemsDetail>,
      private toastrService: ToastrService,
      private buyInvReceptionService: BuyInvReceptionProvider,
      private warehouseService :WarehouseProvider,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: {provider_id: number}
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
        console.log(this.data);

    }

    ngAfterViewInit(): void {
      console.log(this.data);
  //    this.products_view_paginator = null
      this.searchText = null;
      this.getWarehouses();
      this.getPendingItems(this.data.provider_id);

    }
    public warehouse_details: ProductLot[];
    public production_order_view_paginator: ViewPaginator;

    public base_material = 0;


    getPendingItems(id: number) {
      this.buyInvReceptionService.getBuyByProvider(this.enterprise, id.toString()).pipe(takeUntil(this._onDestroy)).subscribe(
        (data: any[]) => {
          debugger;
          this.pending_array= data;
          for (let index = 0; index < this.pending_array.length; index++) {
            this.pending_array[index].ID= index+1;
          }
          console.log(this.pending_array);
        }
      )
    }

    changeValues(e){
      debugger;
      let quantity : number= Number(Number(e.newData.quantity).toFixed(2));
      let quantity_recibed : number= Number(Number(e.newData.quantity_recibed).toFixed(2));
      let quantity_remaining : number= Number(Number(e.oldData.quantity_remaining).toFixed(2));
      if(quantity_recibed >= 0){
        if(quantity_recibed > quantity_remaining){
          e.newData.quantity_recibed = e.oldData.quantity_recibed;
          this.showError('Cantidad a Recibida no Puede Ser Mayor a Cantidad Restante');
        }else{
          e.oldData.quantity_remaining = Number(e.oldData.quantity_remaining)-Number(e.newData.quantity_recibed);
        }
      }else{
        e.newData.quantity_recibed = e.oldData.quantity_recibed;
        this.showError('Cantidad a Aplicar Debe Ser Mayor a Cero (0)');

      }


    }

    getWarehouses() {
      this.warehouseService.allWarehouse(this.enterprise).pipe(takeUntil(this._onDestroy)).subscribe(
        (data: Warehouse[]) => {
          debugger;
          this.warehouses= data;
          console.log('BODEGAS',this.warehouses);
        }
      )
    }

    changeColor($event){
      if (!$event.column.allowEditing && $event.rowType == "data") {
        $event.cellElement.style.backgroundColor="#ddd"
      }
    }

    close(): void {
    //  let block_stocks = [];
    //  let warehouse_details = []

    //  this.data.selected = this.dataGrid.selectedRowKeys;
    debugger;
    let result : pendingItems[]=[];
    let i_selected: number=0;
    for (let index = 0; index < this.dataGrid.selectedRowKeys.length; index++) {
      i_selected = this.pending_array.findIndex(v=> (v.ID == this.dataGrid.selectedRowKeys[index] && Number(v.quantity_recibed)===0 ));
      if(i_selected>=0){
        this.showError('Debe Indicar Cantidad Recibida Mayor a Cero(0) en Item '+this.pending_array[i_selected].name_product);
        return;
      }
    }
    for (let index = 0; index < this.dataGrid.selectedRowKeys.length; index++) {
      i_selected = this.pending_array.findIndex(v=> (v.ID == this.dataGrid.selectedRowKeys[index] && Number(v.quantity_recibed)>0 ));
      if(i_selected>=0){
        result.push(this.pending_array[i_selected]);
      }
    }
      this.dialogRef.close(result);
    }


    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }


    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }


}


export interface pendingItems {
  ID?:number;
  bar_code: string;
  name_warehouse: string;
  branch_offices_id: number;
  buy_01main_id: number;
  buy_06inv_product_id: number;
  client_provider_id: number;
  code_lot: string;
  convert_factor: number;
  convert_quantity: number;
  document_date: string;
  document_number: string;
  identification: string;
  inv_lot_id: number;
  inv_piece: string;
  inv_product_id: number;
  inv_product_warehouse_detail_id: number;
  name_product: string;
  quantity: number;
  quantity_recibed: number;
  quantity_remaining: number;
  warehouse_id: number;
}

import { Action } from '@ngrx/store';

import { InvoiceState } from '../states/invoice';

export enum InvoiceActionTypes {
  LOAD = '[INVOICE] LOAD',
  UPDATE = '[INVOICE] UPDATE',
  DELETE = '[INVOICE] DELETE'
}

export class Load implements Action {
  readonly type = InvoiceActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = InvoiceActionTypes.UPDATE;
  constructor (public payload: InvoiceState) {}
}

export class Delete implements Action {
  readonly type = InvoiceActionTypes.DELETE;
}

export type InvoiceActionsUnion = Load | Update | Delete;

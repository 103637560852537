import { Component } from '@angular/core';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load.modal.html',
  styleUrls: ['./load.modal.scss'],
})

export class LoadModal {

    constructor() {}
}

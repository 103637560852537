import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserMenu } from '../../models/client/UserMenu';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';

@Injectable()
export class UserMenuProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allUserMenus(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<UserMenu[]>(this.urlProvider.user_menu(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<UserMenu[]>(this.urlProvider.user_menu(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allUserMenusView(enterprise: string, token: string = '', value: string = '', search_value ='') {
    return this.http.get<ViewPaginator>(this.urlProvider.user_menu(enterprise) + '/view?' + token + '=' + value + '&search=' + search_value, this.urlProvider.httpOptions);
  }
  public getNextPreviousPage(url) {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }
  public getUserMenu(enterprise: string, id: string) {
    return this.http.get<UserMenu>(this.urlProvider.user_menu(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertUserMenu(enterprise: string, user_menu: UserMenu) {
    return this.http.post<Boolean>(this.urlProvider.user_menu(enterprise), user_menu, this.urlProvider.httpOptions);
  }

  public updateUserMenu(enterprise: string, user_menu: UserMenu, id: string) {
    return this.http.put<Boolean>(this.urlProvider.user_menu(enterprise) + '/' + id, user_menu, this.urlProvider.httpOptions);
  }

}

import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ElectronicalVoucherInvoiceProvider } from "../../../providers/utils/electronical-voucher-invoice.provider";

import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
/* Redux */

@Component({
  selector: 'app-sign-by-batch-modal',
  templateUrl: './sign-by-batch.modal.html',
  styleUrls: ['./sign-by-batch.modal.scss'],
})

export class SignByBatchModal {
    public doneCheckbox = false;
    private enterprise$: Observable<EnterpriseState>;
    public invoices= [];
    public xml_not_sign = []
    public enterprise = '';
    public showSpinner: boolean = false;

    constructor(
      private toastrService: ToastrService,
      private electronicalVoucherInvoiceService: ElectronicalVoucherInvoiceProvider,
      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private store: Store<{enterprise: EnterpriseState}>,
      private dialogRef:MatDialogRef<SignByBatchModal>
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        }
      );
    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
      }
    }

    signByBatch() {
      this.showSpinner = true;
      this.invoices = [];
      let checkboxes: any = document.getElementsByClassName('mat-checkbox-input')
      let checkboxesChecked = []
      this.doneCheckbox = false
      for (let i = 0; i < checkboxes.length; i++) {
        if (i+1 == checkboxes.length) {
          this.doneCheckbox = true
        }
        if (checkboxes[i].checked === true) {
          checkboxesChecked.push(checkboxes[i])
        }
      }
      if (this.doneCheckbox) {
        this.doneCheckbox = false
        for (let j = 0; j < checkboxesChecked.length; j++) {
          console.log(checkboxesChecked[j].value);
          this.invoices.push(
            {
              invoice_id: Number(checkboxesChecked[j].value)
            }
          )
        }
        this.electronicalVoucherInvoiceService.sriAuth(this.enterprise, this.invoices).subscribe(
          (data: any) => {
            this.showSpinner = false;
            if (data.invoices.length > 0) {
              for (let invoice of data.invoices) {
                if (invoice.status) {
                  this.showSuccess("AUTORIZADO", invoice.access_key);
                } else {
                  this.showError("ERROR", invoice.response);
                }
              }
              if (data.emails.status) {
                this.showSuccess("ENVÍO EMAIL", data.emails.response);
              } else {
                this.showSuccess("ERROR ENVÍO EMAIL", data.emails.response);
              }
            }
            this.dialogRef.close();
          },
          error => {
            this.dialogRef.close();
            this.showSpinner = false;
          }
        );
      } else {
        this.dialogRef.close();
        this.showSpinner = false;
      }
    }

    showError(error, factura) {
      this.toastrService.error(error, `Factura ${factura}`, {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success, factura) {
      this.toastrService.success(success, `Factura: ${factura}`, {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }
}

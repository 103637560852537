import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";

import { PendingVouchers } from '../../../models/client/EgressPaymentProofMain';

@Component({
  templateUrl: './egress-redimir.modal.html',
  styleUrls: ['./egress-redimir.modal.scss']
})

export class EgressRedimirModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(
    public dialogRef: MatDialogRef<EgressRedimirModal>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, pending_vouchers: PendingVouchers}
  ) {
    console.log('data modal', this.data);
  }

  close() {
    this.data.selected = this.dataGrid.selectedRowKeys;
    this.dialogRef.close(this.data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

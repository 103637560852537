import { Injectable } from '@angular/core';
import { UserMenuGards } from '../../states/UserMenuGards';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class HasUserRolePermissionProvider {

  public user_menu_gard_state$: Observable<UserMenuGards[]>;
  private user_menu_gard_state: UserMenuGards ;
  constructor(
    private storeUserMenuGards: Store<{ user_menu_gards: UserMenuGards[] }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user_menu_gard_state$ = this.storeUserMenuGards.pipe(select('user_menu_gards'));


  }

  getPermissions(adjustment_pro = false, enterprise_id= null, url_chage = null): any {
    if (adjustment_pro) {
      url = '/admin/inventory/inventory-movement/inventory-adjustment'
    } else if(enterprise_id) {
      var url = 'enterprise/update/'
    } else if(url_chage) {
      url = url_chage;
    }
    else {
      var url = this.router.url
    }

    this.user_menu_gard_state$.subscribe(
      (data: UserMenuGards[]) => {
        this.user_menu_gard_state=  data.find(v=> v.href == url)
      }
    )

    return this.user_menu_gard_state
  }

  verifyGard(type: string, user_menu_gard_state: UserMenuGards) {
    switch (type) {
      case 'create':
        return this.hasCreate(user_menu_gard_state);
      break;
      case 'edit':
        return this.hasEdit(user_menu_gard_state);
      break;

      case 'list':
        return this.hasList(user_menu_gard_state);
      break;

      case 'cancel':
        return this.hasCancel(user_menu_gard_state);
      break;

    }
  }

  private hasCreate(user_menu_gard_state: UserMenuGards) {
    let index = user_menu_gard_state.gards_object.findIndex(v=> v.users_guard_id == 1)
    let gard = user_menu_gard_state.gards_object[index];
    return index >= 0? gard: false;
  }

  private hasEdit(user_menu_gard_state: UserMenuGards) {
    let index = user_menu_gard_state.gards_object.findIndex(v=> v.users_guard_id == 2)
    let gard = user_menu_gard_state.gards_object[index];
    return index >= 0? gard: false;
  }

  private hasList(user_menu_gard_state: UserMenuGards) {
    let index = user_menu_gard_state.gards_object.findIndex(v=> v.users_guard_id == 3)
    let gard = user_menu_gard_state.gards_object[index];
    return index >= 0? gard: false;

  }

  private hasCancel(user_menu_gard_state: UserMenuGards) {
    let index = user_menu_gard_state.gards_object.findIndex(v=> v.users_guard_id == 4)
    let gard = user_menu_gard_state.gards_object[index];
    return index >= 0? gard: false;
  }
}

import { Action } from '@ngrx/store';

import { UserMenuGards } from '../states/UserMenuGards';

export enum UserMenuGardActionTypes {
  LOAD = '[USERMENUGARD] LOAD',
  UPDATE = '[USERMENUGARD] UPDATE',
  DELETE = '[USERMENUGARD] DELETE'
}

export class Load implements Action {
  readonly type = UserMenuGardActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = UserMenuGardActionTypes.UPDATE;
  constructor (public payload: UserMenuGards[]) {}
}

export class Delete implements Action {
  readonly type = UserMenuGardActionTypes.DELETE;
}

export type UserMenuGardActionsUnion = Load | Update | Delete;

import { Component, Inject,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from "devextreme-angular";
import Swal from 'sweetalert2';


@Component({
  selector: 'app-product-catalog-modal',
  templateUrl: './product-catalog.modal.html',
  styleUrls: ['./product-catalog.modal.scss'],
})

export class ProductCatalogModal {
  /* @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;*/
  @ViewChild('gridconsigments', { static: false }) gridconsigments: DxDataGridComponent; 


  public searchFilterText: string = '';
  public searchText: string = '';
  public client:any="";
  public third:any="";
  public motive:any="";
  public cash:any=0;
  public credit:any=0;
  public monto:number=0;
  public saldo:number=0;
  public cin_saldo:number=0;

  
  public advance = {
    credit: null,
    motive: null,
    cash:null,
  }
  public advances = []
  public cin_load = []
  public cin_pay = []
  public product_catalog:any=[];
  public consignments:any=[];



  constructor(
    public dialogRef: MatDialogRef<ProductCatalogModal>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    debugger;
    console.log('data', data);
    if (data.type=='cin') {
    this.product_catalog=data.catalog;
    }else{
      this.consignments=data.catalog
    }
  }

  addConsigments(){
    debugger;
    let select_consigments=this.gridconsigments.selectedRowKeys;
    this.dialogRef.close(select_consigments);
  }

  selectProduct(d:any){
    debugger
    console.log(d);
    this.dialogRef.close(d.data);
    
  }
  /* calculateTotal(){
    debugger;
    this.cin_saldo=0
    if(this.advances.length>0){
      for(let i =0;i<this.advances.length;i++){
        this.cin_saldo+=this.advances[i].total;
      }
    }
    
    
  }

  add(){
    debugger
    if(this.client!="" && this.third!="" && this.motive!=""){
      if(Number(this.cash)!=0 || Number(this.credit)!=0){
        this.cin_saldo=0
        //let total = parseFloat(this.cash.toFixed(2)) + parseFloat(this.credit.toFixed(2));
        let cash=Number(this.cash);
        let credit=Number(this.credit)
        let total=cash+credit;
        total=Number(total.toFixed(2));
        this.advances.push(
          {
            client: this.client,
            motive: this.motive,
            cash: cash,
            credit: credit,
            income_type_id: this.third,
            total: total,
          }
        );
        console.log("adv",this.advances);
        this.client="";
        this.third="";
        this.motive="";
        this.cash=0;
        this.credit=0;
        for(let i =0;i<this.advances.length;i++){
          this.cin_saldo+=this.advances[i].total;
        }
      }else{
      this.showError('Credito y debito no puden ser igual a 0', 'Error');
      }
    }else{
      this.showError('Debe llenar todos los campos del formulario', 'Error');
      
    }

  }

  close(): void {
    debugger;
    if(this.client!="" && this.third!="" && this.motive!=""){
      Swal.fire({
        title: 'Esta Seguro de Continuar?',
        text: "Parece que tienes una recaudación pendiente para agregar",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI, Continuar'
      }).then((result) => {
        if (result.value) {
          localStorage.setItem("cin_third_income","cin");
          this.dialogRef.close(this.advances);
        }
        else{
          console.log("resultado",result);
          }
      })
    }else{
      localStorage.setItem("cin_third_income","cin");
      this.dialogRef.close(this.advances);
    }
  }

  

  close2(){
    debugger;
    let advances=this.dataGrid.selectedRowKeys;
    for(let i=0;i<advances.length;i++){
      advances[i].balance=Number(advances[i].balance)
      advances[i].abonos=Number(advances[i].abonos)
      advances[i].total=Number(advances[i].total)
    }
    this.dialogRef.close(advances);
    console.log("hola",this.dataGrid.selectedRowKeys);
  }
  close3(){
    debugger;
    localStorage.setItem("cin_third_income","pay");
    let cin_pays=this.gridContainerpay.selectedRowKeys;
    this.dialogRef.close(cin_pays);
    console.log("hola",this.gridContainerpay.selectedRowKeys);
    
  }

  formatCash = (e: any) => {
    const value = e.value;
    // Aplica el formato deseado al valor
    const formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    e.displayValue = formattedValue;
  };

  onSelected(e:any){
    debugger
    this.monto=0;
    if( e.selectedRowKeys.length>0){
      for (let index = 0; index < e.selectedRowKeys.length; index++) {
        this.monto+=Number(e.selectedRowKeys[index].total);
      }
    }
    console.log(this.monto);
    
  }
  onSelected2(e:any){
    debugger
    this.saldo=0;
    if( e.selectedRowKeys.length>0){
      for (let index = 0; index < e.selectedRowKeys.length; index++) {
        this.saldo+=Number(e.selectedRowKeys[index].remaining_balance);
      }
    }
    console.log(this.monto);
    
  }
  showError(error, mensaje) {
    this.toastrService.error(`${mensaje}`, `${error}`, {
      timeOut: 10000,
      positionClass: 'toast-bottom-center'
    });
  } */

  
}

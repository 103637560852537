import { Component, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-invoice-quantity-left-modal',
  templateUrl: './invoice-quantity-left.modal.html',
  styleUrls: ['./invoice-quantity-left.modal.scss'],
})

export class InvoiceQuantityReturnedModal {
    public searchFilterText: string = '';
    public searchText: string = '';

    public quantity_to_return: number = 0;

    constructor(
      public dialogRef: MatDialogRef<InvoiceQuantityReturnedModal>,

      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      console.log(data);

      this.quantity_to_return= data.product_detail.quantity_returned
      console.log(this.quantity_to_return);
    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true

      }
    }

    notification(masage: string) {
      this._snackBar.open(masage, null, {
        duration: 10000
      });
    }

    close(){
      if (Number(this.data.product_detail.quantity) >= Number(this.quantity_to_return)) {
        this.dialogRef.close([this.data, this.quantity_to_return]);
      } else {
        this.notification('La cantidad a entregar no puede ser mayor a la cantidad a facturar')
      }
    }
}

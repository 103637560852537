import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Product } from '../../models/client/Product';
import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';
import { ProductLot } from '../../models/client/ProductLot';
/**LotUpload */
import { LotUpload } from '../../models/client/LotUpload';
/**END LotUpload */

@Injectable()
export class ProductProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allProducts(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<Product[]>(this.urlProvider.product(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Product[]>(this.urlProvider.product(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allProductsCatalog(enterprise: string, filter: string = '') {
    if (filter !== '') {
      return this.http.get<Product[]>(this.urlProvider.product(enterprise) + '/catalog?' + filter , this.urlProvider.httpOptions);
    } else {
      return this.http.get<Product[]>(this.urlProvider.product(enterprise)+ '/catalog', this.urlProvider.httpOptions);
    }
  }

public likeProducts(enterprise: string,search: string = '', code_bar: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '?search=' + search + '&code_bar=' +code_bar+ '&product_status=true&not_last_cost=true', this.urlProvider.httpOptions);
}

public likeProductsSearch(enterprise: string,search: string = '', code_bar: string = '') {
  return this.http.get<ViewPaginator>(this.urlProvider.productsearch(enterprise) + '?search=' + search + '&code_bar=' +code_bar+ '&product_status=true', this.urlProvider.httpOptions);
}

public getLikeProductsSearch(enterprise: string, params: {} = {}) {
  return this.http.get<ViewPaginator>(this.urlProvider.productsearch(enterprise), {
    ...this.urlProvider.httpOptions,
    params,
  });
}

public downloadInvProductComboExport(enterprise: string, filter) {
  return this.urlProvider.product(enterprise)+ '/download/excel' + filter;
}

public loadInvProductComboExport(enterprise: string,filter, data: any[]) {
  return this.http.post<any[]>(this.urlProvider.product(enterprise) + '/download/excel'+ filter, data, this.urlProvider.httpOptions);
}

public alohaProducts(enterprise: string, search: string) {
  return this.http.get<Product[]>(this.urlProvider.product(enterprise) + '?aloha=' + search, this.urlProvider.httpOptions);
}

public viewProducts(enterprise: string, per_page_value: number, search_value: string = '', token: string = "", value: string = "", token2: string = "", value2: string = "", filter = '') {
  return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '/view?search='+ search_value +' &row=' + per_page_value + '&' + token + '=' + value + '&' + token2 + '=' + value2 +'&'+filter, this.urlProvider.httpOptions);
}

public getMinMaxStock(enterprise: string,product:any) {
  return this.http.put<ViewPaginator>(this.urlProvider.product(enterprise) + '/stockminmax',product,this.urlProvider.httpOptions);
}
public getProductFilter(enterprise: string, id: any) {
  return this.http.get<any>(this.urlProvider.product(enterprise)+'/productfilter/'+id, this.urlProvider.httpOptions);
}
public getValidateMinMax(enterprise: string) {
  return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '/validatestockminmax',this.urlProvider.httpOptions);
}

public setValidateMinMax(enterprise: string) {
  return this.http.post<ViewPaginator>(this.urlProvider.product(enterprise) + '/validatestockminmax',null,this.urlProvider.httpOptions);
}

public viewRawMaterialProducts(enterprise: string,token, search_value: string = '', filter = '') {
  return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '/view?'+ token+'='+ search_value +'&'+ filter, this.urlProvider.httpOptions);
}

public nextPreviousPageProducts(url: string, per_page_value: number , token: string = "", value: string = "", token2: string = "", value2: string = "") {
  return this.http.get<ViewPaginator>(url + '&row=' + per_page_value + '&' + token + '=' + value + '&' + token2 + '=' + value2, this.urlProvider.httpOptions);
}

  public allLikeProducts(enterprise: string, tipo: string = '',search: string = '') {
    return this.http.get<Product[]>(this.urlProvider.product(enterprise) + '?search=' + search, this.urlProvider.httpOptions);
}

  public getProduct(enterprise: string, id: string, token= "") {
    return this.http.get<Product>(this.urlProvider.product(enterprise) + '/' + id + token, this.urlProvider.httpOptions);
  }

  public insertProduct(enterprise: string, product: any) {
    return this.http.post<number>(this.urlProvider.product(enterprise), product, this.urlProvider.httpOptions);
  }

  public async insertProductPOS(enterprise: string, product: any) {
    return this.http.post<any[]>(this.urlProvider.product(enterprise)+'?pos=true', product, this.urlProvider.httpOptions).toPromise();
  }

  public updateProduct(enterprise: string, product: any, id: string) {
    return this.http.put<Boolean>(this.urlProvider.product(enterprise) + '/' + id, product, this.urlProvider.httpOptions);
  }


  public validateProducts(enterprise: string, products: any[]) {
    return this.http.put<any>(this.urlProvider.product(enterprise) + '/validate', products ,  this.urlProvider.httpOptions);
  }
  public updateProductsMasive(enterprise: string, products: any[]) {
    return this.http.put<any>(this.urlProvider.product(enterprise) + '/masive/products', products ,  this.urlProvider.httpOptions);
  }
  public addProvidersAsigned(enterprise: string, providers: any) {
    return this.http.put<any>(this.urlProvider.product(enterprise) + '/providers/asigned', providers ,  this.urlProvider.httpOptions);
  }
  public getProvidersAsigned(enterprise: string,product_id:number) {
    return this.http.get<any>(this.urlProvider.product(enterprise) + '/providers/asigned?inv_product_id='+product_id ,  this.urlProvider.httpOptions);
  }
  public updateProductType(enterprise: string, product_type: any, id: string) {
    return this.http.put<Boolean>(this.urlProvider.product(enterprise) + '/producttype/' + id, product_type, this.urlProvider.httpOptions);
  }
  public deleteProduct(enterprise: string, id: string) {
    return this.http.delete<Boolean>(this.urlProvider.product(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public getLotProduct(enterprise: string, id_producto: string, id_warehouse:string, type : string) {
    return this.http.get<ProductLot[]>(this.urlProvider.product(enterprise) + '?lot=true&id_inv_product='+id_producto+'&id_warehouse='+id_warehouse+'&type='+type , this.urlProvider.httpOptions);
  }

  public getAllLotProduct(enterprise: string, params: {} = {}) {
    return this.http.get<ProductLot[]>(this.urlProvider.product(enterprise), {
      ...this.urlProvider.httpOptions,
      params,
    });
  }

  public getAllProducts(enterprise: string) {
    return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '/all' , this.urlProvider.httpOptions);
  }

  public allProductByWarehouse(enterprise: string, filter: string, id_warehouse:string, type : string) {
    return this.http.get<ViewPaginator>(this.urlProvider.product(enterprise) + '?view='+filter+'&id_warehouse='+id_warehouse+'&type='+type , this.urlProvider.httpOptions);
  }

  public lotUpload(enterprise: string, lotUpload: LotUpload) {
    return this.http.post<any>(this.urlProvider.product(enterprise) + '/' + 'lotupload', lotUpload, this.urlProvider.httpOptions);
  }

  public allCharacteristics(enterprise: string) {
    return this.http.get<any>(this.urlProvider.product(enterprise) + '/' + 'characteristics', this.urlProvider.httpOptions);
  }

  public saveCharacteristics(enterprise: string, characteristics) {
    return this.http.post<any>(this.urlProvider.product(enterprise) + '/' + 'characteristics', characteristics, this.urlProvider.httpOptions);
  }

  public saveDatasheet(enterprise: string, datasheet) {
    return this.http.post<any>(this.urlProvider.product(enterprise) + '/' + 'datasheet', datasheet, this.urlProvider.httpOptions);
  }
}

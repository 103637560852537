import { Component, Inject, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { LoginProvider } from "../../../providers/admin/login.provider";
import { EncryptProvider } from '../../../providers/utils/encrypt.provider';
import { UserRolePermisionProvider } from '../../../providers/client/user_role_permisions.provider';

/* END Redux */

@Component({
  selector: 'app-credential-modal',
  templateUrl: './credential.modal.html',
  styleUrls: ['./credential.modal.scss'],
})

export class CredentialModal implements AfterViewInit, OnDestroy {

  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  public selected_odp = []
  public searchText: string = '';
  public moduleLabel: string = '';
  public idPermision: number = 0;
  public user: string = '';
  public password: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();
  public new_billing = false;
  public loginForm: FormGroup;


    constructor(
      public dialogRef: MatDialogRef<CredentialModal>,
      private toastrService: ToastrService,
      private loginFormBuilder: FormBuilder,
      private loginService: LoginProvider,
      public encrypt: EncryptProvider,
      private userRolePermisionService: UserRolePermisionProvider,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      debugger;
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      );
      this.loginForm = this.createloginFormControl();

    }

    ngAfterViewInit(): void {
      this.moduleLabel= this.data.in_permission.label;
      this.idPermision =this.data.in_permission.id;
      console.log(this.data);
    }

    createloginFormControl() {
      return this.loginFormBuilder.group(
        {
          userFormControl: ['', Validators.compose([Validators.required,, Validators.email])],
          passwordFormControl: ['', Validators.compose([Validators.required])],
        }
      );
    }


    processCredentials(): void {
      debugger;
      if(
        (this.loginForm.controls['userFormControl'].value != '' && this.loginForm.controls['userFormControl'].value != undefined) &&
        (this.loginForm.controls['passwordFormControl'].value != '' && this.loginForm.controls['passwordFormControl'].value != undefined)
        ){
          if(!this.loginForm.controls['userFormControl'].valid){
            this.showError('Usuario Inválido');
            return;
          }
          this.showSpinner = true;
          this.loginService.getLoginUser('email',this.user).subscribe(
            (data) => {
              console.log('loginService', data);
              debugger;
              if (data) {
                if (data.status===true) {
                  //this.user = data;
                  debugger
                  data.user.password = this.encrypt.get(data.user.password);
                  if (this.password == data.user.password) {
                    //VERIFICAR QUE USUARIO ESTA AUTORZADO
                    this.processSupervisionPermission(this.idPermision.toString(),this.user);
                    //this.loadDialogRef.close();

                  } else {
                   this.showSpinner = false;
                    this.showError( 'Password es incorrecto');
                  }
                } else {
                  this.showSpinner = false;
                  this.showError(data.response);
                }
              } else {
                this.showSpinner = false;
                this.showError( 'Algo malo ha ocurrido, por favor, intente de nuevo');
              }
            },
            error => {
              this.showSpinner = false;
              console.log('error login', error);
            }
          )

      }else{

        this.showError('Campos son Obligatorios');
      }
    }

    processSupervisionPermission(id:string, email:string){
      debugger;
      this.userRolePermisionService.getSupervisionRolePermision(this.enterprise,id,email).pipe(takeUntil(this._onDestroy)).subscribe(
        (data) => {
          console.log('userRolePermisionService', data);
          debugger;
          if (data.status===true) {
            if(data.response[0].users_exist!=0){
              this.showSpinner = false;
              this.dialogRef.close(true);
            }else{
              this.showSpinner = false;
              this.showError('Usuario No tiene Permisos para Esta Operación');
            }

          }else{
            this.showSpinner = false;
            this.showError(data.response);
          }

        },
        error => {
          //this.loadDialogRef.close();
          this.showSpinner = false;
          this.showError('No se pudo Verificar al usuario');
          console.log('error login', error);
        }
      )
    }

    close(isInserting:boolean): void {

      this.dialogRef.close(isInserting);
    }

    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }


}

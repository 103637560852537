import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDatepicker,NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {ThemePalette} from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';



export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

const I18N_VALUES = {
  'Sp': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthFullName: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],

  }
};

@Injectable()
export class I18n {
  language = 'Sp';
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.modal.html',
  styleUrls: ['./datepicker.modal.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: DatepickerModal}],

})

@Injectable()
export class DatepickerModal extends NgbDatepickerI18n {
  @ViewChild('dp', { static: true}) datepicker: NgbDatepicker;

  type_DatePicker: any = localStorage.getItem('DatePickerBasic');



  hoveredDate: NgbDate | null = null;
  date1:NgbDate;
	fromDate: NgbDate;
	toDate: NgbDate | null = null;
  until:string= ""
	finishDate: NgbDate | null = null;
  model: NgbDateStruct;

  //dias:string[]=["lu","ma","mi","ju","vi","sa",]
  constructor(

    private calendar: NgbCalendar,
    private dialogRef: MatDialogRef<DatepickerModal>,
    private _i18n: I18n
    ) {
      super()
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
      this.finishDate=this.toDate;
      this.until=this.finishDate.day+"/"+this.finishDate.month+"/"+this.finishDate.year
     }
  getWeekdayShortName(weekday: number): string {
    return  I18N_VALUES[this._i18n.language].weekdays[weekday - 1] };
  getMonthShortName(month: number): string {
    return  I18N_VALUES[this._i18n.language].months[month - 1]};
  getMonthFullName(month: number): string {
    return I18N_VALUES[this._i18n.language].monthFullName[month-1]};
  getDayAriaLabel(date: NgbDateStruct): string {return "e"};

  

  selectToday() {
		this.model = this.calendar.getToday();
	}

  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Últimos 7 días', completed: false, color: 'primary'},
      {name: 'Este mes', completed: false, color: 'primary'},
      {name: 'Último Mes', completed: false, color: 'primary'},
      {name: 'Ultimo Semestre/Año', completed: false, color: 'primary'},
      {name: 'Último Año', completed: false, color: 'primary'},
    ],
  };

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
      this.finishDate=date;
      this.until=this.finishDate.day+"/"+this.finishDate.month+"/"+this.finishDate.year;

		} else {
			this.toDate = null;
			this.fromDate = date;
      this.finishDate=date;
      this.until=this.finishDate.day+"/"+this.finishDate.month+"/"+this.finishDate.year;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  dateFilter(filtro:string){
    this.toDate=this.calendar.getPrev(this.fromDate,"d",1);

    if(filtro=='Últimos 7 días'){
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(),"d",7);
    this.toDate = this.calendar.getNext(this.fromDate, 'd', 7);
    this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
    this.datepicker.navigateTo({year:(this.calendar.getToday()).year,month:(this.calendar.getToday()).month});
    }

    if(filtro=='Último Mes'){
      this.fromDate=this.calendar.getToday();
      this.date1=this.fromDate;
      this.date1.day=1;
      let lastmonthdate =this.calendar.getPrev(this.date1,"d",1)
      this.fromDate.day=1;
      this.fromDate.month=lastmonthdate.month;
      this.fromDate.year=lastmonthdate.year;
      this.toDate.day=lastmonthdate.day;
      this.toDate.month=this.fromDate.month;
      this.toDate.year=this.fromDate.year;
      this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
      this.datepicker.navigateTo({year:lastmonthdate.year , month: lastmonthdate.month});
    }

    if(filtro=='Último Año'){
      this.fromDate.day=1;
      this.fromDate.month=1;
      this.fromDate.year=(this.calendar.getPrev(this.calendar.getToday(),"y",1)).year;
      this.toDate.day=31;
      this.toDate.month=12;
      this.toDate.year=this.fromDate.year;
      this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
      this.datepicker.navigateTo({year:(this.calendar.getPrev(this.calendar.getToday(),"y",1)).year, month: 12});

    }
    if(filtro=="Este mes"){
      this.date1=this.calendar.getNext(this.calendar.getToday(),"m",1)
      this.date1.day=1
      this.fromDate.day=1
      this.fromDate.month=(this.calendar.getToday()).month;
      this.fromDate.year=(this.calendar.getToday()).year;
      this.toDate.day=this.calendar.getPrev(this.date1,"d",1).day;
      this.toDate.month=(this.calendar.getToday()).month;
      this.toDate.year=(this.calendar.getToday()).year ;
      this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
      this.datepicker.navigateTo({year:(this.calendar.getToday()).year,month:(this.calendar.getToday()).month});
    }
    if(filtro=="Ultimo Semestre/Año"){
      if(this.calendar.getToday().month<7){
      this.fromDate.day=1;
      this.toDate.day=31;
      this.fromDate.month=7;
      this.toDate.month=12
      this.fromDate.year=this.calendar.getPrev(this.calendar.getToday(),"y",1).year
      this.toDate.year=this.calendar.getPrev(this.calendar.getToday(),"y",1).year
      this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
      this.datepicker.navigateTo({year:(this.calendar.getPrev(this.calendar.getToday(),"y",1)).year,month:12});
      }else{
      this.fromDate.day=1;
      this.toDate.day=30;
      this.fromDate.month=1;
      this.toDate.month=6
      this.fromDate.year=this.calendar.getToday().year
      this.toDate.year=this.calendar.getToday().year
      this.until=this.toDate.day+"/"+this.toDate.month+"/"+this.toDate.year;
      this.datepicker.navigateTo({year:(this.calendar.getToday()).year,month:6});
      }
    }

  }

  save(){
    let todates:any
    if(this.toDate==null){
      todates=this.finishDate
    }
    else{
      todates=this.toDate
    }
    let result = {
      fromDate: this.fromDate,
      toDate:todates
    }

    console.log("Fecha que va",this.fromDate,this.toDate);
    this.dialogRef.close(result);

  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BuyInvReception01Main } from '../../models/client/BuyInvReception01Main';
import { BuyInvReception02Item } from '../../models/client/BuyInvReception02Item';
import { ViewPaginator } from '../../models/client/ViewPaginator';
import { URLProvider } from '../url.providers';

@Injectable()
export class BuyInvReceptionProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allBuyInvReceptions(enterprise: string,token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<ViewPaginator>(this.urlProvider.buyinvreception(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<ViewPaginator>(this.urlProvider.buyinvreception(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allBuyInvReceptionsView(enterprise: string,token: string = '', value: string = '') {
      return this.http.get<ViewPaginator>(this.urlProvider.buyinvreception(enterprise) + '/view' , this.urlProvider.httpOptions);
  }

  public allBuyInvReceptionsViewRange(enterprise: string,start_date: string, end_date: string) {
    //,token: string = '', value: string = ''
    return this.http.get<ViewPaginator>(this.urlProvider.buyinvreception(enterprise) + '/view?start_date='+start_date+'&end_date='+end_date , this.urlProvider.httpOptions);
}

public allBuyInvReceptionsViewFilter(enterprise: string,filter: string) {
  //,token: string = '', value: string = ''
  return this.http.get<ViewPaginator>(this.urlProvider.buyinvreception(enterprise) + '/view?'+filter , this.urlProvider.httpOptions);
}

  public getBuyInvReception(enterprise: string,id: string) {
    return this.http.get<any>(this.urlProvider.buyinvreception(enterprise)+'/'+id, this.urlProvider.httpOptions);
  }


  public deleteBuyInvReception(enterprise: string,  id: string, updated_by: receptionRegisterDelete) {
    return this.http.put<{any}>(this.urlProvider.buyinvreception(enterprise) +'/delete/'+ id,updated_by, this.urlProvider.httpOptions);
  }

  public nextPreviousPage(url: string, per_page_value: number , token: string = "", value: string = "", token2: string = "", value2: string = "") {
    return this.http.get<ViewPaginator>(url + '&row=' + per_page_value + '&' + token + '=' + value + '&' + token2 + '=' + value2, this.urlProvider.httpOptions);
  }

  public getSequential(enterprise: string, branch_office_id: string,  project_code: string) {

    return this.http.get<string>(this.urlProvider.buyinvreception(enterprise) + '/nextsequential/doc?branch_office_id=' + branch_office_id + '&project_code=' + project_code, this.urlProvider.httpOptions);
//    return this.http.get<string>(this.urlProvider.buyinvreception(enterprise) + '/sequential?branch_office_code=' + branch_office_code + '&stablishment_code=' + stablishment_code + '&project_name=' + project_name, this.urlProvider.httpOptions);
  }

  public getBuyByProvider(enterprise: string,provider_id: string) {
    //,token: string = '', value: string = ''
    return this.http.get<any[]>(this.urlProvider.buyinvreception(enterprise) + '/provider/'+provider_id , this.urlProvider.httpOptions);
  }

  public insertBuyInvReception(enterprise: string,reception: receptionRegister) {
    return this.http.post<any>(this.urlProvider.buyinvreception(enterprise), reception, this.urlProvider.httpOptions);
  }

  public updateBuyInvReception(enterprise: string,reception: receptionRegisterUpdate, id: string) {
    return this.http.put<Boolean>(this.urlProvider.buyinvreception(enterprise) + '/' + id, reception, this.urlProvider.httpOptions);
  }

  public pdfReception(enterprise: string, id: string) {
    return this.urlProvider.buyinvreceptionpdf(enterprise)+ '/' + id;
  }

  public pdfReceptionComprobante(enterprise: string, type:string ,id: string) {
    return this.urlProvider.buyinvreceptionpdf_accouting_voucher(enterprise,type,id);
  }

}


export interface receptionRegister {
  buy_inv_reception_01main : BuyInvReception01Main;
  buy_inv_reception_02item : BuyInvReception02Item[];
  delete?:number[];
}

export interface receptionRegisterUpdate {
  buy_inv_reception_01main : BuyInvReception01Main;
  delete?:number[];
}

export interface receptionRegisterDelete {
  updated_by : string;
  annulment_comment : string;
}


import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";

//import { BuyPurchaseOrder01Main } from '../../../models/client/BuyPurchaseOrder01Main';

@Component({
  templateUrl: './buy-production-order.modal.html',
  styleUrls: ['./buy-production-order.modal.scss']
})

export class BuyProductionOrderModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(
    public dialogRef: MatDialogRef<BuyProductionOrderModal>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, production_orders: ViewProductionOrders}
  ) {
    console.log('data modal', this.data);
  }

  close() {
    this.data.selected = this.dataGrid.selectedRowKeys;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

/*export interface ViewProductionOrders {
  ID?:number;
  production_order_01main_id:number,
  document_number:string,
  branch_office_id:number,
  status_intern:string,
  status_production:string,
  status_purchase:string,
  processor:string,
  type:string,
  invoice_01main_id:number,
  invoice_document_number:string,
  invoice_02item_id:number,
  invoice_item02_quantity:string,
  total_cost:string,
  material_cost:string,
  rate_name:string,
  rate_pct:string,
  production_order_02item_quantity:string,
  production_order_02item_id:number,
  buy_applied_amount:number,
  buy_balance_amount:number,
  pro_applied_amount:number,
  pro_balance_amount:number,
  inv_product_id:number,
  name:string,
  bar_code:string,
  aux_code:string,
  blocked_product_name:string,
  blocked_product_bar_code:string,
  blocked_product_id:number,
  block_stock:string,
  inv_block_stock_id:number,
  warehouse_id:number,
  inv_product_warehouse_detail_id:number,
  inv_lot_id:number,
  inv_lot_code: string,
}*/


export interface ViewProductionOrders {
  ID?:number;
  document_id?:number;
  item_id?:number;
  document_number: string;
  date_production_order: string;
  identification_client: string;
  name_client: string;
  deliver_date: string;
  bar_code: string;
  name_product: string;
  inv_product_id: number;
  quantity: number;
  rate_pct: number;
  subtotal: number;
  tax_base: number;
  vat: number;
  total: number;
  lot_code: string;
 inv_lot_id: number;
 unit_cost: number;
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLProvider } from '../url.providers';
import { ViewPaginator } from '../../models/client/ViewPaginator';
import { UserState } from 'src/app/states/user';
import { EnterpriseState } from 'src/app/states/enterprise';

@Injectable()
export class ChangeAppProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allChangeApps(enterprise: string, token: string = '') {
    return this.http.get<ChangeApp[]>(this.urlProvider.changeapp(enterprise) + '?' + token, this.urlProvider.httpOptions);
  }
  public allChangeAppView(enterprise: string, token: string = '') {
    return this.http.get<ViewPaginator>(this.urlProvider.changeapp(enterprise) + '/view?' + token, this.urlProvider.httpOptions);
  }
  public getNextPreviousPage(url) {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }
  public getChangeApp(enterprise: string, id: string) {
    return this.http.get<ChangeApp>(this.urlProvider.changeapp(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertChangeApp(enterprise: string, changeapp: any) {
    return this.http.post<number>(this.urlProvider.changeapp(enterprise), changeapp, this.urlProvider.httpOptions);
  }

  public updateChangeApp(enterprise: string, changeapp: any, id: string) {
    return this.http.put<Boolean>(this.urlProvider.changeapp(enterprise) + '/' + id, changeapp, this.urlProvider.httpOptions);
  }

}


export interface ChangeApp {
  'app_form',
  'redux_data': data,
  'datetime',
  'status',
  'db_name',
  'redirect_to',
  'medical_consultation_id'
}

export interface data {
  user: UserState
  enterprise: EnterpriseState
}


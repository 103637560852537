import { Component, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductionOrderMainProvider } from '../../../providers/client/production_order_main.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { YesNoConfirmationModal } from '../yes-no-confirmation/yes-no-confirmation.modal';
import { ProductionOrderMain } from '../../../models/client/ProductionOrderMain';



@Component({
  selector: 'app-production-order-inv-supplies-modal',
  templateUrl: './production-order-inv-supplies.modal.html',
  styleUrls: ['./production-order-inv-supplies.modal.scss'],
})

export class ProductionOrderSuppliesModal {
  public searchFilterText: string = '';
  public searchText: string = '';
  public filter = {
    document_number: '',
    date_production_order: '',
    name_client: '',
    status_intern: '',
    status_production: '',
    status_purchase: ''
  }
  filters: string;
  production_orders_view_paginator: ViewPaginator;
  productionOrders_view: ProductionOrderMain[];
  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dialogRef:MatDialogRef<ProductionOrderSuppliesModal>,
    private productionOrderMainService: ProductionOrderMainProvider,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.productionOrders_view = []
    this.getProductionOrders();
  }

  setFilters() {
    this.filters = 'filter=true&document_number='+ this.searchText +
    "&branch_offices_id=" ;

    if (this.data.format_id) {
      this.filters += '&format_id='+this.data.format_id+'&identification_client='+this.data.identification_client
    } else {
      if(this.data.finish) {
        this.filters += '&inv_supplies=true'+'&supplie=EN PROCESO'
      } else {
        this.filters += '&inv_supplies=true'+'&supplie=EMITIDO'
      }
    }
  }

  getProductionOrders(){
    this.setFilters()
    this.productionOrderMainService.allViewProductionOrders(this.data.enterprise, this.filters)
    .subscribe(
      (data: ViewPaginator) => {
        this.production_orders_view_paginator = data;
        this.productionOrders_view = data.data;
        if (this.data.format_id) {
          this.productionOrders_view.forEach(v=>  v.items = v.items.filter(x=> x.production_order_format_id == this.data.format_id))
          this.productionOrders_view = this.productionOrders_view.filter(v=>  v.items.length > 0)
        }

        
      }
    )
  }

  nextPreviousProductionOrders(url){
    this.setFilters
    this.productionOrderMainService.nextPreviousPageProductionOrders(url,10, this.filters)
    .subscribe(
      (data: ViewPaginator) => {
        this.production_orders_view_paginator = data;
        this.productionOrders_view = data.data;
        if (this.data.format_id) {
          this.productionOrders_view.forEach(v=>  v.items = v.items.filter(x=> x.production_order_format_id == this.data.format_id))
          this.productionOrders_view = this.productionOrders_view.filter(v=>  v.items.length > 0)
        }
      }
    )
  }

  yesNoConfirmation(productionOrders_view) {
    if (this.data.items_selected && this.data.items_selected.length == 0) {
      this.dialogRef.close(productionOrders_view);
      return
    }
    const dialogRef = this.dialog.open(YesNoConfirmationModal, {
      width: '400px',
      height: '260px',
      data: { title: '¿Esta Seguro de realizar esta operación?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.dialogRef.close(productionOrders_view);
      }
    }
    )
  }

  closeFormatID(index_order, index_item) {
    this.dialogRef.close(this.productionOrders_view[index_order].items[index_item]);

  }

  selectAll(){
    let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
    for (let i = 0; i < checkbox.length; i++) {
      checkbox[i].checked = true

    }
  }

  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 10000
    });
  }
}

import { Action } from '@ngrx/store';

import { Body } from '../states/body';

export enum BodyActionTypes {
  LOAD = '[BODY] LOAD',
  UPDATE = '[BODY] UPLOAD'
}

export class Load implements Action {
  readonly type = BodyActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = BodyActionTypes.UPDATE;
  constructor (public payload: Body) {}
}

export type BodyActionsUnion = Load | Update;

import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { PendingVouchers } from '../../../models/client/IncomePaymentProofMain';
import { IncomePaymentProofMainProvider } from 'src/app/providers/client/income_payment_proof_main.provider';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
import draggable from 'devextreme/ui/draggable';

@Component({
  templateUrl: './get-tickets.modal.html',
  styleUrls: ['./get-tickets.modal.scss']
})

export class getTicketsModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  public header_columns = ["check","client_name",
    "expiration",
    "remaining_balance",
    "register_date",
    "document",
    "total",
    "retention",
    "credits"];
    public filter = {
      date: '',
      document: ''
    }
  constructor(
    public dialogRef: MatDialogRef<getTicketsModal>,
    private incomePaymentProofMainService: IncomePaymentProofMainProvider,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('data modal', this.data);
    
  }
  public pending_vouchers = []
  public view_paginator: ViewPaginator;


  close() {
    
    this.dialogRef.close(this.data.tickets);
  }

  selectAll() {
    for (const item of this.data.tickets) {
      item.check = true;
    }
  }

  deselectAll() {
    for (const item of this.data.tickets) {
      item.check = false;
    }
  }

}

import { Action } from '@ngrx/store';
import { EnterpriseState } from '../states/enterprise';

export enum EnterpriseActionTypes {
  LOAD = '[ENTERPRISE] LOAD',
  UPDATE = '[ENTERPRISE] UPDATE',
  DELETE = '[ENTERPRISE] DELETE'
}

export class Load implements Action {
  readonly type = EnterpriseActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = EnterpriseActionTypes.UPDATE;
  constructor(public payload: EnterpriseState) {}
}

export class Delete implements Action {
  readonly type = EnterpriseActionTypes.DELETE;
}

export type EnterpriseActionsUnion = Load | Update | Delete ;

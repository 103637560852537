import { Action } from '@ngrx/store';
import { UserState } from '../states/user';

export enum UserActionTypes {
  LOAD = '[USER] LOAD',
  UPDATE = '[USER] UPDATE',
  DELETE = '[USER] DELETE'
}

export class Load implements Action {
  readonly type = UserActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = UserActionTypes.UPDATE;
  constructor(public payload: UserState) {}
}

export class Delete implements Action {
  readonly type = UserActionTypes.DELETE;
}

export type UserActionsUnion = Load | Update | Delete ;

import { Component, Inject, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../../../models/client/Product';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { OdpProvider } from '../../../providers/client/odp.provider';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ProductProvider } from '../../../providers/client/product.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { GetDeliver } from '../../../models/client/GetDeliver';
import { Purchase } from '../../../models/client/Purchase';
/* END Redux */

@Component({
  selector: 'app-odp-modal',
  templateUrl: './odp.modal.html',
  styleUrls: ['./odp.modal.scss'],
})

export class ODPModal implements AfterViewInit, OnDestroy {

  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  public selected_odp = []
  public purchase_view_paginator: ViewPaginator = null;
  public purchases: Purchase[] = null;
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();
  public new_billing = false;


    constructor(
      public dialogRef: MatDialogRef<ODPModal>,
      private odpService: OdpProvider,
      private toastrService: ToastrService,
      private productService: ProductProvider,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: { selected: any[], branch_offices_id: number, odp: boolean }
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
      if (this.data.selected.length > 0) {
        this.selected_odp = this.data.selected;
        this.new_billing =true
      } else {
        this.selected_odp = [];
      }
    }

    ngAfterViewInit(): void {
      console.log(this.data);
      this.purchase_view_paginator = null
      this.searchText = "";
      this.getODP();
    }

    getODP(){
      let filter = "search=" + this.searchText;
      if (this.data.branch_offices_id) {
        filter = filter + '&branch_offices_id='+this.data.branch_offices_id+ '&odp=true'
      }
      this.odpService.getViewOdp(this.enterprise, filter).pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: ViewPaginator) => {
          if (this.data.odp) {
            this.purchases = data.data;
          } else {
            this.purchases = data.data.filter(v=> v.status_intern != 'CERRADO');
          }
          this.purchase_view_paginator = data;
          console.log(data);
        }
      )
    }

    

    getProductionOrderNextPreviousPage(url) {
      debugger
      var token = this.searchText ? '&search='+ this.searchText:'';
      let filter = '';
      if (this.data.branch_offices_id) {
        filter = '&branch_offices_id='+this.data.branch_offices_id+ '&odp=true'
      }
      let url_new = url +  token + filter
      this.odpService.getOdpNextPreviousPage(url_new).pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: ViewPaginator) => {
          if (this.data.odp) {
            this.purchases = data.data;
          } else {
            this.purchases = data.data.filter(v=> v.status_intern != 'CERRADO');
          }
          this.purchase_view_paginator = data;
        }
      )
    }


    changeCheckbox(index: any, purchase: Purchase){
      debugger
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input-modal-'+purchase.id)[0];

      if (checkbox.checked) {
        let index_of_selected_product = this.selected_odp.findIndex(v=> v.id == purchase.id);
        if (index_of_selected_product < 0) {
          this.selected_odp.push(purchase)
          console.log(this.selected_odp);
        }
      }else {
        debugger
        let index_of_selected_product = this.selected_odp.findIndex(v=> v.id == purchase.id);
        if (index_of_selected_product >= 0) {
          this.selected_odp.splice(index_of_selected_product, 1)
        }
      }
    }

    checkedProduct( purchase: Purchase){
      let index_of_selected_product = this.selected_odp.findIndex( v=> v.id == purchase.id);
      if (index_of_selected_product >= 0) {
        return true;
      } else {
        return false;
      }
    }

    addProducts(){
      // let products_id = []
      // for (let i = 0; i < this.selected_odp.length; i++) {
      //   products_id.push(this.selected_odp[i].id)
      // }
      this.close(this.selected_odp)
    }

    close(products): void {
      
      this.dialogRef.close(products);
    }

    deleteProduct(index_product) {
      this.selected_odp.splice(index_product, 1);
    }


    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }
    getPaginatorData(event){
      console.log(event);
      if(event.pageIndex === this.pageIndex + 1){
         this.lowValue = this.lowValue + this.pageSize;
         this.highValue =  this.highValue + this.pageSize;
      }
      else if(event.pageIndex === this.pageIndex - 1){
        this.lowValue = this.lowValue - this.pageSize;
        this.highValue =  this.highValue - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  

}

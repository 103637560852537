import { Action } from '@ngrx/store';
import { SessionState } from '../states/session';

export enum SessionActionTypes {
  LOAD = '[SESSION] LOAD',
  UPDATE = '[SESSION] UPDATE',
}

export class Load implements Action {
  readonly type = SessionActionTypes.LOAD;
}

export class Update implements Action {
  readonly type = SessionActionTypes.UPDATE;
  constructor(public payload: SessionState) {}
}

export type SessionActionsUnion = Load | Update ;

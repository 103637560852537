import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ElectronicalVoucherInvoiceProvider } from "../../../providers/utils/electronical-voucher-invoice.provider";

import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
/* Redux */

@Component({
  selector: 'app-stock-warning-modal',
  templateUrl: './stock-warning.modal.html',
  styleUrls: ['./stock-warning.modal.scss'],
})

export class StockWarningModal {
    public doneCheckbox = false;
    private enterprise$: Observable<EnterpriseState>;
    public invoices= [];
    public xml_not_sign = []
    public enterprise = '';
    public showSpinner: boolean = false;

    constructor(
      private toastrService: ToastrService,
      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef:MatDialogRef<StockWarningModal>
    ) {
      console.log(data)
    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
      }
    }



    showError(error, factura) {
      this.toastrService.error(error, `Factura ${factura}`, {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success, factura) {
      this.toastrService.success(success, `Factura: ${factura}`, {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Upload } from '../../../states/upload';
import { InvoicePaymentMethod } from '../../../models/client/InvoicePaymentMethod';
import { UploadProvider } from '../../../providers/upload/upload.provider';
import { EnterpriseState } from '../../../states/enterprise';
import { Update as UpdateUpload, Delete as DeleteUpload } from '../../../actions/upload.actions';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-add-voucher-image-modal',
  templateUrl: './add-voucher-image.modal.html',
  styleUrls: ['./add-voucher-image.modal.scss']
})

export class AddVoucherImageModal implements OnInit {
  public newForm: FormGroup;


  value: any[] = [];
  public uploadUrl: string = '';
  private upload$: Observable<Upload[]>;
  public update_previewUrlImage: string = null;
  public image_name: string = '';
  public runUrl = true;

  constructor(
    private uploadService: UploadProvider,
    public dialogRef: MatDialogRef<AddVoucherImageModal>,
    @Inject(MAT_DIALOG_DATA) public data: {create: boolean, enterprise: string, enterprise_state: EnterpriseState, invoice_payment_method :InvoicePaymentMethod,
      uploadUrl, vouchers_images, type: string, client_professional_title: any},
    private formBuilder: FormBuilder,
    private uploadStore: Store<{ upload: Upload[] }>,

  ) {
    this.newForm = this.createFormControl();
    this.upload$ = this.uploadStore.pipe(select('upload'));

  }

  ngOnInit() {
    debugger
    this.update_previewUrlImage = null;
    this.uploadUrl = this.data.uploadUrl;
    this.update_previewUrlImage = null;
    this.vouchers_images = this.data.vouchers_images
  }

  createFormControl() {
    return this.formBuilder.group(
      {
        category: ['', Validators.compose([
          Validators.required
        ])]
      }
    )
  }

  public vouchers_images = [];
  public images = [];
  public delete_vouchers = true;
  getVoucherImage(image) {
    if (this.data.type == 'title') {
      return this.uploadService.uploadFileVouchersUrlTitle() + '/' + this.data.enterprise_state.entity.tax_identification + '/' + image
    } else {
      return this.uploadService.uploadFileVouchersUrl() + '/' + this.data.enterprise_state.entity.tax_identification + '/' + image
    }
  }


  updateUploaded(e: any) {
    if (this.data.type == 'title') {
      setTimeout(() => {
        debugger
        if(this.data.client_professional_title.img) {
          let index = this.vouchers_images.findIndex(v=> v.name == this.data.client_professional_title.img)
          if(index >= 0) {
            this.vouchers_images.splice(index,1)
            this.deleteVouchers(this.data.client_professional_title.img)
          }
        }
        if (this.value.length > 0) {
          this.data.client_professional_title.img = e.request.response.replaceAll('"', '')
          this.vouchers_images.push(
            {
              name: e.request.response.replaceAll('"', ''),
              size: null,
              type: null,
            });
            this.images.push({
              url: this.getVoucherImage(this.data.client_professional_title.img),
              href: null,
              caption: null,
              title: null,
              backgroundSize: null,
              backgroundPosition: null,
              backgroundRepeat: null,
              text: null,
            })
          let data = [];
          this.vouchers_images.forEach(element => {
            data.push(
              {
                name: element.name,
                size: null,
                type: null,
              });

          });
          this.uploadStore.dispatch(new UpdateUpload(data));

          // this.value = []
        }
      }, 1500);
    } else {
      setTimeout(() => {
        if(this.data.invoice_payment_method.voucher_img) {
          let index = this.vouchers_images.findIndex(v=> v.name == this.data.invoice_payment_method.voucher_img)
          if(index >= 0) {
            this.vouchers_images.splice(index,1)
            if(this.data.invoice_payment_method.id == 0) {
              this.deleteVouchers(this.data.invoice_payment_method.voucher_img)
            }
          }
        }
        if (this.value.length > 0) {
          this.data.invoice_payment_method.voucher_img = e.request.response.replaceAll('"', '')
          this.vouchers_images.push(
            {
              name: e.request.response.replaceAll('"', ''),
              size: null,
              type: null,
            });
            this.images.push({
              url: this.getVoucherImage(this.data.invoice_payment_method.voucher_img),
              href: null,
              caption: null,
              title: null,
              backgroundSize: null,
              backgroundPosition: null,
              backgroundRepeat: null,
              text: null,
            })
          let data = [];
          this.vouchers_images.forEach(element => {
            data.push(
              {
                name: element.name,
                size: null,
                type: null,
              });

          });
          this.uploadStore.dispatch(new UpdateUpload(data));

          // this.value = []
        }
      }, 1500);
    }
  }
  deleteVouchers(name, multiple:any = '', delete_redux = false) {
    let data = {
      name : name
    }
    if (multiple) {
      data = name
    }
    if (this.data.type == 'title') {
      this.uploadService.deleteFileTitles(this.data.enterprise, data, multiple).subscribe(
        (data: boolean) => {
          if(delete_redux) {

            this.uploadStore.dispatch(new DeleteUpload());
          }
        }
      )
    } else {
      this.uploadService.deleteFileVouchers(this.data.enterprise, data, multiple).subscribe(
        (data: boolean) => {

        }
      )
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnterprisePlan } from '../../models/client/EnterprisePlan';
import { URLProvider } from '../url.providers';

@Injectable()
export class EnterprisePlanProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allEnterprisePlans(enterprise: string, token: string = '', value: string = '', token2: string = '', value2: string = '') {
    if (token != '') {
      return this.http.get<EnterprisePlan[]>(this.urlProvider.enterprise_plan(enterprise) + '?' + token + '=' + value+ '&' + token2 + '=' + value2, this.urlProvider.httpOptions);
    } else {
      return this.http.get<EnterprisePlan[]>(this.urlProvider.enterprise_plan(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allEnterprisePlanBanks(enterprise: string, token: string = '', value: string = '') {
      return this.http.get<EnterprisePlan[]>(this.urlProvider.enterprise_plan(enterprise)+'/list?'+ token + '=' + value , this.urlProvider.httpOptions);
  }

  public getEnterprisePlan(enterprise: string, id: string) {
    return this.http.get<EnterprisePlan>(this.urlProvider.enterprise_plan(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertEnterprisePlan(enterprise: string, enterprise_plan: EnterprisePlan) {
    return this.http.post<Boolean>(this.urlProvider.enterprise_plan(enterprise), enterprise_plan, this.urlProvider.httpOptions);
  }

  public updateEnterprisePlan(enterprise: string, enterprise_plan: EnterprisePlan, id: string) {
    return this.http.put<Boolean>(this.urlProvider.enterprise_plan(enterprise) + '/' + id, enterprise_plan, this.urlProvider.httpOptions);
  }

  public getExistAccount(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.enterprise_plan(enterprise) + '/exist/' + id, this.urlProvider.httpOptions);
  }

  public getAccountACC(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.enterprise_plan(enterprise) + '/exist/acc/' + id, this.urlProvider.httpOptions);
  }
}

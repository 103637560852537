import { UserWebsocketActionTypes, UserWebsocketActionsUnion } from '../actions/user_websocket.action';
import { UserWebsocket } from '../states/UserWebsocket';


import * as CryptoJS from 'crypto-js';

const initialState: UserWebsocket =
{
  enterprise: null,
  user_account: null
};

export function userWebSocketReducer(state: UserWebsocket = initialState, action: UserWebsocketActionsUnion): UserWebsocket {
  let key, enterprise;
  switch (action.type) {
    case UserWebsocketActionTypes.LOAD:
      key = window.btoa('user_websocket');
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return initialState;
    case UserWebsocketActionTypes.UPDATE:
      key = window.btoa('user_websocket');
      console.log('UserWebsocketActionTypes.UPDATE', action.payload);
      debugger;
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return action.payload;
    case UserWebsocketActionTypes.DELETE:
        key = window.btoa('user_websocket');
        localStorage.removeItem(key);
        return initialState;
    default:
      key = window.btoa('user_websocket');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualEnterprise: UserWebsocket = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualEnterprise
        }
      } else {
        key = window.btoa('user_websocket');
        enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, enterprise);
        return state;
      }
  }
}

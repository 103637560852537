import {Injectable} from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {IServerErrorModel} from "../../models/http";
import {ToasterIndividualConfig} from "../../shared/config/toaster-individual.config";

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone()).pipe(
      catchError((err: IServerErrorModel, _: Observable<HttpEvent<any>>) => {
        // if ((err.status >= 400 || err.status <= 499) || (err.status >= 500 && err.status <= 599)) {
        //   this.toastrService.error(`${err.error.message.detail}`, `${err.error.message.summary}`, ToasterIndividualConfig);
        // }
        return throwError(err);
      }),
    );
  }
}


import { BodyActionTypes, BodyActionsUnion } from '../actions/body.action';
import { Body } from '../states/body';

import * as CryptoJS from 'crypto-js';

const initialState: Body = {
  class: 'nav-md'
};

export function bodyReducer (state: Body = initialState, action: BodyActionsUnion): Body {
  const key = window.btoa('gfsys_body_class');
  let body;
  switch (action.type) {
    case BodyActionTypes.LOAD:
      body = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, body);
      return state;
    case BodyActionTypes.UPDATE:
      body = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, body);
      return action.payload;
    default:
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          body = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return body;
        } else {
          return state;
        }
      } else {
        body = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, body);
        return state;
      }
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-retention-process-modal',
  templateUrl: './retention-process.modal.html',
  styleUrls: ['./retention-process.modal.scss']
})
export class RetentionProcessModal {
  public selectedIds: string[] = [];
  public allSelected = false; // Indica si todos los elementos visibles están seleccionados

  constructor(
    public dialogRef: MatDialogRef<RetentionProcessModal>,   
    @Inject(MAT_DIALOG_DATA) public data: any, 
  ) {
    console.log("data_modal", data);
  }

  filters = {
    nro_documento: '',
    fec_documento: '',
    emisor_ruc: '',
    name_emisor: ''

  };

  // Filtrar los datos basados en los filtros de N# y Monto
  filteredData() {
    return this.data.filter(retention => {
      const matchesNroDoc = this.filters.nro_documento
        ? retention.nro_documento.includes(this.filters.nro_documento)
        : true;
  
      const matchesFecDoc = this.filters.fec_documento
        ? retention.fec_documento == this.filters.fec_documento
        : true;
  
      const matchesEmisorRuc = this.filters.emisor_ruc
        ? retention.emisor_ruc.includes(this.filters.emisor_ruc)
        : true;
  
      const matchesNameEmisor = this.filters.name_emisor
        ? retention.name_emisor.toLowerCase().includes(this.filters.name_emisor.toLowerCase())
        : true;
  
      // Devuelve true solo si todos los filtros coinciden
      return matchesNroDoc && matchesFecDoc && matchesEmisorRuc && matchesNameEmisor;
    });
  }

  // Maneja el cambio en el checkbox
  onCheckboxChange(event: Event, id: any) {
    const checkbox = (event.target as HTMLInputElement);
    if (checkbox.checked) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id); // Agregar el ID si el checkbox está marcado
      }
    } else {
      const index = this.selectedIds.indexOf(id);
      if (index > -1) {
        this.selectedIds.splice(index, 1); // Eliminar el ID si el checkbox está desmarcado
      }
    }
  }

  // Verifica si un checkbox individual está seleccionado
  isSelected(id: string): boolean {
    return this.selectedIds.includes(id);
  }

  // Maneja el cambio de selección global solo en los datos filtrados
  toggleSelectAll() {
    const visibleIds = this.filteredData().map(retention => retention.id);
    if (this.allSelected) {
      // Deseleccionar todos los visibles
      this.selectedIds = this.selectedIds.filter(id => !visibleIds.includes(id));
    } else {
      // Seleccionar todos los visibles, evitando duplicados
      visibleIds.forEach(id => {
        if (!this.selectedIds.includes(id)) {
          this.selectedIds.push(id);
        }
      });
    }
    this.allSelected = !this.allSelected;
  }

  // Método para obtener las filas seleccionadas
  getSelectedIds(): any[] {
    return this.selectedIds;
  }

  close() {
    let dataReturned = this.getSelectedIds();
    this.dialogRef.close(dataReturned);
  }
}
import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-yes-no-confirmation-modal',
  templateUrl: './yes-no-confirmation.modal.html',
  styleUrls: ['./yes-no-confirmation.modal.scss'],
})

export class YesNoConfirmationModal {
  public doneCheckbox = false
  public motivo: string;
  showQuestion = false;
  showBody = false;
  withoutOptions = false;
    constructor(
      public dialogRef: MatDialogRef<YesNoConfirmationModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
      if(data.question != null){
        this.showQuestion = true;
      }
      if(data.body != null){
        this.showBody = true
        
      }

      if(data.withoutOptions != null){
        this.withoutOptions = true
        
      }

    }



}

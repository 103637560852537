import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { EnterpriseState } from '../../../states/enterprise';
import { UserState } from '../../../states/user';

@Component({
  selector: 'app-user-refresh-login-modal',
  templateUrl: './user-refresh-login.modal.html',
  styleUrls: ['./user-refresh-login.modal.scss'],
})

export class UserRefreshLogin {
  public doneCheckbox = false
  public motivo: string;
  login_refresh = {
    user_email: null,
    db_name: null,
    component: null,
    automatic: false,
  }
    constructor(
      public dialogRef: MatDialogRef<UserRefreshLogin>,
      // private socket: Socket,
      @Inject(MAT_DIALOG_DATA) public data: data,

    ) {
      this.login_refresh = {
        user_email: data.email,
        db_name: data.enterprise,
        component: null,
        automatic: false,
      }
    }

    emitRefresh() {
      console.log(this.login_refresh);

      this.emit('refresh-login-client', this.login_refresh);
    }

    emit( event: string, payload?: any, callback?: Function ) {

      console.log('Emitiendo', event);
      // emit('event', payload, callback?)
      // this.socket.emit( event, payload, callback );
      this.dialogRef.close();
    }



}

export interface data {
  email:string,
  enterprise: string;
  enterprise_state: EnterpriseState;
  user_state: UserState
}

export interface LoginRefresh {
  user_email: string;
  db_name: string;
  component: string;
  automatic: boolean;
}

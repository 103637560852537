import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add_decrease-modal',
  templateUrl: './add_decrease.modal.html',
  styleUrls: ['./add_decrease.modal.scss'],
})

export class AddDecrease {
  public doneCheckbox = false
  public motivo: string;
  decrease =  {
    code: null,
    name: null,
    quantity: null,
    id: null
  }

    constructor(
      public dialogRef: MatDialogRef<AddDecrease>,
      private toastrService: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {

    }

  changeIdCodeAccountant(data){
    let acc = this.data.decrease.find(v=> v.id == data.id)
    this.decrease.name = acc? acc.alias: null;
    this.decrease.code = acc? acc.id_code_accountant: null;
  }

  changeQuentity() {
    let quantity =Number(this.data.quantity) + Number(this.decrease.quantity);
    if (Number(quantity) > Number(this.data.quantity_max)) {
      this.decrease.quantity = null
      this.showError('Excede la cantidad maxima de ' +this.data.quantity_max, '')
    }
  }
  showError(error, mensaje) {
    this.toastrService.error(`${mensaje}`, `${error}`, {
      timeOut: 10000,
      positionClass: 'toast-bottom-center'
    });
  }

}

import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-get-sri-signature-modal',
  templateUrl: './get-sri-signature.modal.html',
  styleUrls: ['./get-sri-signature.modal.scss'],
})

export class GetSriSignatureModal {
  public doneCheckbox = false
  public motivo: string;

    constructor(
      public dialogRef: MatDialogRef<GetSriSignatureModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {}



}

import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";

import { PendingVouchers } from '../../../models/client/EgressPaymentProofMain';
import { EgressPaymentProofItem } from 'src/app/models/client/EgressPaymentProofItem';

@Component({
  templateUrl: './egress-advance-others.modal.html',
  styleUrls: ['./egress-advance-others.modal.scss']
})

export class EgressAdvanceOthersModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  public advanceForm: FormGroup;
  public advance_concept: string;
  public advance_amout: number;


  constructor(
    public dialogRef: MatDialogRef<EgressAdvanceOthersModal>,
    private advanceFormBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { advances_others: EgressPaymentProofItem[]}
  ) {
    console.log('data modal Anticipos', this.data);
    this.advanceForm = this.createAdvanceFormControl();

  }

  createAdvanceFormControl() {
    return this.advanceFormBuilder.group(
      {
        advanceConcepFormControl: [''],
        advanceAmountFormControl: ['']
      }
    );
  }

  close() {
    debugger;
    //this.data.selected = this.dataGrid.selectedRowKeys;
    if((this.advance_concept)&&(this.advance_amout>0)){
      this.data.advances_others[0].concept=this.advance_concept;
      this.data.advances_others[0].amount=this.advance_amout;
      this.dialogRef.close(this.data);  
    }else{
      alert('Error en Formulario')
    }
  }

  onNoClick(): void {
    this.data.advances_others.shift(); 
    this.dialogRef.close();
  }

}

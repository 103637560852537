import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";

import { PendingVouchers } from '../../../models/client/IncomePaymentProofMain';
import { IncomePaymentProofMainProvider } from 'src/app/providers/client/income_payment_proof_main.provider';

@Component({
  templateUrl: './income-payment-redeem.modal.html',
  styleUrls: ['./income-payment-redeem.modal.scss']
})

export class IncomePaymentRedeemModal {
  @ViewChild('redeemDataGrid', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('redeemDataGridRetention', { static: false }) dataGrid2: DxDataGridComponent;
  public dataReturned: any;
  constructor(
    public dialogRef: MatDialogRef<IncomePaymentRedeemModal>,
    private incomePaymentProofMainService: IncomePaymentProofMainProvider,
    @Inject(MAT_DIALOG_DATA) public data: { enterprise: any, client_selected:any, selected: any, pending_vouchers: PendingVouchers[] }
  ) {
    console.log('data modal', this.data);
    //this.getRetentionRedimir();
  }
  public advance_retention:any = {
    selected: [],
    pending_vouchers: []
  };

  public filter = {
    from: new Date,
    until: new Date,
  }
  getRetentionRedimir(){
    debugger
    const fromDate = this.formatDate(this.filter.from);
    const untilDate = this.formatDate(this.filter.until);
    this.incomePaymentProofMainService.getRedimirByClientsRetention(this.data.enterprise,this.data.client_selected.identification,fromDate,untilDate).subscribe(
      (data: any) => {
        console.log('pending redimir', data);
      this.advance_retention.pending_vouchers = data.response;
      console.log('assigned pending_vouchers', this.advance_retention.pending_vouchers);

        
      }
    )
  }

  private formatDate(date: Date): string {
    debugger
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Meses en JavaScript van de 0 a 11
    const day = ('0' + date.getDate()).slice(-2);
  
    return `${year}-${month}-${day}`;
  }

  
  

  close() {
    console.log("hola",this.data, this.advance_retention);
    this.dataReturned = {
          selected_retention: this.advance_retention.selected,
          retention: this.advance_retention.pending_vouchers,
          selected_pending_vouchers: this.data.selected,
          pending_vouchers: this.data.pending_vouchers
        };
    console.log(this.dataReturned);
    // this.data.selected = ;
    
    this.dialogRef.close(this.dataReturned);
  }

}
 
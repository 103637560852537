import { Component, Inject, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../../../models/client/Product';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { PurchaseProvider } from '../../../providers/client/purchase.provider';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ProductProvider } from '../../../providers/client/product.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { Purchase } from 'src/app/models/client/Purchase';
import { IvaPercentage } from 'src/app/models/catalog/IvaPercentage';

/* END Redux */

@Component({
  selector: 'app-refund_invoice-modal',
  templateUrl: './refund_invoice.modal.html',
  styleUrls: ['./refund_invoice.modal.scss'],
})

export class RefoundInvoiceModal implements AfterViewInit, OnDestroy {

  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  public selected_purchase = []
  public purchase_view_paginator: ViewPaginator = null;
  public purchases: Purchase[] = null;
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();
  public new_billing = false;

  public used = '';


    constructor(
      public dialogRef: MatDialogRef<RefoundInvoiceModal>,
      private toastrService: ToastrService,
      private productService: ProductProvider,
      private purchaseService: PurchaseProvider,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
      let used = []

      if (this.data.used) {
        for (const selected of this.data.used) {
          if (selected.buy_01main_id) {
            used.push(selected.buy_01main_id)
          }
        }
        this.used = used.join(',')
        console.log(this.used); 
      }
      if (this.data.selected.length > 0) {
        this.selected_purchase = this.data.selected;
        
        
        
        this.new_billing =true
      } else {
        this.selected_purchase = [];
      }
    }

    ngAfterViewInit(): void {
      console.log(this.data);
      let date: Date = new Date
      let format_date: string = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
      this.purchase_view_paginator = null
      this.searchText = null;
      this.getPurchaseRefound();
    }

    
  

    getPurchaseRefound(){
      var token = this.searchText ? '&search='+ this.searchText +'&list_invoice_refund=true&used='+this.used: ''+'&list_invoice_refund=true&used='+this.used;
      this.purchaseService.allViewPurchase(this.enterprise, token).pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: ViewPaginator) => {
          this.purchases = data.data;
          this.purchase_view_paginator = data;
          console.log(data);
        }
      )
    }

    getProductionOrderNextPreviousPage(url) {
      var token = this.searchText ? '&search='+ this.searchText +'&list_invoice_refund=true&used='+this.used: ''+'&list_invoice_refund=true&used='+this.used;
      let url_new = url +  token
      this.purchaseService.getPurchaseNextPreviousPage(url_new).pipe(takeUntil(this._onDestroy))
      .subscribe(
        (data: ViewPaginator) => {
          this.purchases = data.data;
          this.purchase_view_paginator = data;
          console.log(data);
        }
      )
    }


    changeCheckbox(index: any, purchase: Purchase){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input-modal-'+purchase.id)[0];

      if (checkbox.checked) {
        let index_of_selected_product = this.selected_purchase.findIndex(v=> v.id == purchase.id);
        if (index_of_selected_product < 0) {
          this.selected_purchase.push(purchase)
          console.log(this.selected_purchase);
        }
      }else {
        let index_of_selected_product = this.selected_purchase.findIndex(v=> v.id == purchase.id);
        if (index_of_selected_product >= 0) {
          this.selected_purchase.splice(index_of_selected_product, 1)
        }
      }
    }

    checkedProduct( purchase: Purchase){
      let index_of_selected_product = this.selected_purchase.findIndex( v=> v.id == purchase.id || v.buy_01main_id ==  purchase.id);
      if (index_of_selected_product >= 0) {
        return true;
      } else {
        return false;
      }
    }

    addProducts(){
      // let products_id = []
      // for (let i = 0; i < this.selected_purchase.length; i++) {
      //   products_id.push(this.selected_purchase[i].id)
      // }
      this.close(this.selected_purchase)
    }

    close(products): void {
      
      this.dialogRef.close(products);
    }

    deleteProduct(index_product) {
      this.selected_purchase.splice(index_product, 1);
    }


    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }
    getPaginatorData(event){
      console.log(event);
      if(event.pageIndex === this.pageIndex + 1){
         this.lowValue = this.lowValue + this.pageSize;
         this.highValue =  this.highValue + this.pageSize;
      }
      else if(event.pageIndex === this.pageIndex - 1){
        this.lowValue = this.lowValue - this.pageSize;
        this.highValue =  this.highValue - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  

}

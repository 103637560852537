import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";

import { PendingVouchers } from '../../../models/client/EgressPaymentProofMain';

@Component({
  templateUrl: './egress-payment-vouchers.modal.html',
  styleUrls: ['./egress-payment-vouchers.modal.scss']
})

export class EgressPaymentVouchersModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  public monto_total : number=0;
  constructor(
    public dialogRef: MatDialogRef<EgressPaymentVouchersModal>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, pending_vouchers: PendingVouchers[]}
  ) {
    console.log('data modal De Facturas', this.data);
  }

  close() {
    this.data.selected = this.dataGrid.selectedRowKeys;
    this.dialogRef.close(this.data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelected(e:any){
    console.log(e);
    let indis:number=0;
    this.monto_total=0;
      for (let index = 0; index < e.selectedRowKeys.length; index++) {
        indis = this.data.pending_vouchers.findIndex(
          cc => ((cc.ID == e.selectedRowKeys[index]) &&
          (cc.voucher_desc !='ANTICIPO' ) && (cc.voucher_desc.substr(0,2) !='04' ))
          );
          if(indis>=0){
            this.monto_total = Number(this.monto_total) +Number(this.data.pending_vouchers[indis].remaining_balance);
          }
      }

  }

}

import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdditionalDefaultInfo } from '../../../models/client/AdditionalDefaultInfo';

@Component({
  selector: 'app-more-information-modal',
  templateUrl: './more-information.modal.html',
  styleUrls: ['./more-information.modal.scss'],
})

export class MoreInformationModal {
  public more_informations: any = []
  public addtional_information: any = [];
  public form: FormGroup;
  public items: FormArray;
  public emission_point:any;

  constructor(
    public dialogRef: MatDialogRef<MoreInformationModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
  ) {
    debugger
    console.log('data', data);
    this.emission_point = data.emission_point;
    this.more_informations = data.more_informations;
    this.addtional_information = data.addtional_information;
    this.form = this.createForm();
    if (this.addtional_information.length > 0) {
      for (let i = 0; i < this.addtional_information.length; i++) {
        this.addItem(this.addtional_information[i]);
      }
    } else {
      for (let i = 0; i < this.more_informations.length; i++) {
        if(this.more_informations[i].info2 == 'SELECT'){
          this.addItem({info1: '', info2: '', type: 'SELECT'});
        }else{
          this.addItem({info1: '', info2: '', type: 'OTHER'});
        }
      }
    }
    this.filteredOptions = []; // Reiniciar el array antes de llenarlo

    for (let i = 0; i < this.more_informations.length; i++) {
      if (this.more_informations[i].option_items && this.more_informations[i].option_items.length > 0) {
        this.filteredOptions.push([...this.more_informations[i].option_items]); // Agrega el array con opciones
      } else {
        this.filteredOptions.push([]); // Agrega un array vacío si no hay opciones
      }
    }

    debugger;
    this.filteredOptions
  }
  
  get formData() { return <FormArray>this.form.get('Data'); }
  
  createForm(): FormGroup {
    return this.formBuilder.group(
      {
        items: this.formBuilder.array([])
      }
    )
  }
  
  createItem(information: any): FormGroup {
    console.log(information);
    let info1: any = '';
    let info2: any = '';
    if (information.info2 !== '' || moment(information.info1, moment.ISO_8601, true).isValid()) {
      if ((moment(information.info1, moment.ISO_8601, true).isValid()) && information.info1.includes('-')) {
        info1 = new Date(Number(information.info1.split('-')[0]), Number(information.info1.split('-')[1]), Number(information.info1.split('-')[2]));
        info2 = new Date(Number(information.info2.split('-')[0]), Number(information.info2.split('-')[1]), Number(information.info2.split('-')[2]));
      } else {
        info1 = information.info1;
      }
    } else {
      info1 = information.info1;
    }
    return this.formBuilder.group(
      {
        info1: info1,
        info2: info2,
        type: information.type
      }
    );
  }
  
  addItem(information: any) {
    this.items = this.form.get('items') as FormArray;
    this.items.push(this.createItem(information));
  }
  additional_information: any[] = []
  notification(masage: string) {
    this._snackBar.open(masage, null, {
      duration: 6000
    });
  }
  filteredOptions: any[] = [];


  filterOptionsFilters(searchText: string, index: number) {
    debugger
    if (!this.more_informations[index]) return;
  
    // Filtrar las opciones según el texto ingresado
    this.filteredOptions[index] = this.more_informations[index].option_items.filter(option =>
      option.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  
  acept(): void {
    for(let i = 0; i < this.more_informations.length; i++){
      if(this.more_informations[i].mandatory == true){
        for(let j = 0; j < this.more_informations[i].branch_offices.length; j++){
          if(this.more_informations[i].branch_offices[j].emision_point == this.emission_point ){
            if(this.items.controls[i].value.info1 == '' || this.items.controls[i].value.info1 == undefined){
              this.notification('El campo '+this.more_informations[i].info1+' es obligatorio');
              return;
            }
          }
        } 
      }
    }
    debugger
    for (let control of this.items.controls) {
      let info1 = '';
      let info2 = '';
      if (control.value.info2 !== '') {
        info2 = moment(control.value.info2).format('YYYY-MM-DD');
      }
      if (moment(control.value.info1, moment.ISO_8601, true).isValid()) {
        info1 = moment(control.value.info1).format('YYYY-MM-DD');
      } else {
        info1 = control.value.info1;
      }

      this.additional_information.push({info1: info1, info2: info2, type: 'other'});
    }
    this.addtional_information = this.additional_information;
    console.log('this.additional_information', this.additional_information);
    this.dialogRef.close(this.addtional_information);
  }

  
  close(): void {
    this.dialogRef.close([]);
  }
}

import { InvoiceActionTypes, InvoiceActionsUnion } from '../actions/invoice.action';
import { InvoiceState } from '../states/invoice';

import * as CryptoJS from 'crypto-js';

const initialState: InvoiceState = {};

export function invoiceReducer(state: InvoiceState = initialState, action: InvoiceActionsUnion): InvoiceState {
  let key = window.btoa('gfsys_invoice'), invoice;
  switch (action.type) {
    case InvoiceActionTypes.LOAD:
      invoice = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, invoice);
      return initialState;
    case InvoiceActionTypes.UPDATE:
      invoice = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, invoice);
      return action.payload;
    case InvoiceActionTypes.DELETE:
      localStorage.removeItem(key);
      return initialState;
    default:
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualInvoice: InvoiceState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualInvoice;
        }
      } else {
        invoice = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, invoice);
        return state;
      }
  }
}

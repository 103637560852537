import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-date-modal',
  templateUrl: './date-from-until.modal.html',
  styleUrls: ['./date-from-until.modal.scss'],
})

export class DateFromUntilModal {
    public date;
    public year ;
    public month ;
    public day ;
    public until;

    public maxDate;

    constructor(
      public dialogRef: MatDialogRef<DateFromUntilModal>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {

    }
    minDateChange() {
      this.year = this.date.getFullYear()
      this.month = this.date.getMonth()
      this.day = this.date.getDate()
      this.maxDate = new Date(this.year + 1, this.month, this.day +30)
      console.log(this.maxDate);

    }
}

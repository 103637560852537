import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { UserAccount } from '../../models/client/UserAccount';
import { UserAccountRole } from '../../models/client/UserAccountRole';
import { UserRolePermision } from '../../models/client/UserRolePermision';
import { UserMenu } from '../../models/client/UserMenu';
import { LoginAuthenticate } from '../../models/admin/LoginAuthenticate';
import { Role } from '../../models/client/Role';

import { EnterpriseState } from '../../states/enterprise';
import { UserWebsocket } from '../../states/UserWebsocket';
import { UserState } from '../../states/user';
import { SessionState } from '../../states/session';
import { Menu } from '../../states/menu';

import { UserProvider } from '../admin/user.provider';
import { UserMenuProvider } from "../client/user_menu.provider";
import { UserAccountProvider } from "../client/user_account.provider";
import { UserAccountRoleProvider } from "../client/user_account_role.provider";
import { UserRolePermisionProvider } from "../client/user_role_permisions.provider";
import { LoginProvider } from "../admin/login.provider";
import { LoginAuthenticateProvider } from "../admin/login-authenticate.provider";
import { CreateMenuProvider } from '../utils/create_menu.provider';
import { EncryptProvider } from '../utils/encrypt.provider';
import { RoleProvider } from '../client/role.provider';

/* Materials */
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
/* END Materials */

/* Redux */
import { Store, select } from '@ngrx/store';
import { Update as UpdateEnterprise } from '../../actions/enterprise.action';
import { Update as UpdateUser } from '../../actions/user.action';
import { Update as UpdateUserWebsocket } from '../../actions/user_websocket.action';
import { Update as UpdateMenu} from '../../actions/menu.action';
import { Update as UpdateSession} from '../../actions/session.action';
import { Delete as DeleteMenu } from '../../actions/menu.action';
import { Delete as DeleteEnterprise } from '../../actions/enterprise.action';
import { Delete as DeleteUser } from '../../actions/user.action';
import { Delete as DeleteUserWebsocket } from '../../actions/user_websocket.action';
/* END Redux */

/* Modals */
import { EnterprisesModal } from '../../components/modals/enterprises/enterprises.modal';

/* END Modals */

import { SupportModal } from '../../components/modals/support/support.modal';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})

export class LoginRefreshProvider  {
  public email: any;
  public password: any;
  public confirm_password: any;

  public date = new Date().getFullYear()
  public user: any = [];

  public user_account: any;

  public user_account_roles: any;
  public user_role_permisions: any = [];

  public menu: any = [];

  private sessions: LoginAuthenticate[];

  public session: LoginAuthenticate = {
    email:'',
    token: null,
    start: null,
  };
  private enterprise_state: EnterpriseState = {
    acc: null,
    configuration: null,
    entity: null,
    establishment: null,
    integration: null,
    inventory: null,
    invoice: null,
    representatives: null,
    taxes: null,
    branchofficesenterprise: null,
  };
  private user_state: UserState = {
    user_account: null,
    user_account_roles: null,
    user_role_permissions: null,
    user_role_selected: null,
    user_role_name_selected: ''
  };
  public session_state_token: string
  private session_state: SessionState = {
    token: null,
    email: '',
  };
  public session_state$: Observable<SessionState>;
  public showSpinner: boolean = false;

  private _onDestroy = new Subject<void>();

  constructor(
    private storeEnterprise: Store<{ enterprise: EnterpriseState[] }>,
    private storeMenu: Store<{ menu: Menu[] }>,
    private storeUser: Store<{ user: UserState[] }>,
    private storeUserWebsocket: Store<{ user_websocket: UserWebsocket[] }>,
    private loginService: LoginProvider,
    private loginSessionService: LoginAuthenticateProvider,
    private UserAccountService: UserAccountProvider,
    private UserMenuService: UserMenuProvider,
    private UserAccountRoleService: UserAccountRoleProvider,
    private UserRolePermisionService: UserRolePermisionProvider,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private createMenuService: CreateMenuProvider,
    public encrypt: EncryptProvider,
    private roleService: RoleProvider,
    private userService: UserProvider
  ) {
    // this.compiler.clearCache();
    // this.session_state$ = storeSession.pipe(select('session'));
    // storeMenu.dispatch(new DeleteMenu());
    // storeUserWebsocket.dispatch(new DeleteUserWebsocket());
    // // storeUser.dispatch(new DeleteUser());
    // storeEnterprise.dispatch(new DeleteEnterprise());
  }



  // ngAfterViewInit() {
  //   this.user_role_permisions = Object.assign([], this.user_role_permisions);
  // }

  getUserAccount(enterprise: string, token: string, value: string ) {
    this.UserAccountService.allUserAccounts(enterprise, token, value).subscribe(
      (data: UserAccount[]) => {
        this.user_account = data[0];
        if (!this.user_state.user_account) {
          this.user_state.user_account  = data;

        }
        // this.getUserIsSuperAdmin();
        this.getUserAccountRoles(this.enterprise_state.entity.db_name, 'users_account_id', this.user_account.id);
      },
      error => {
        this.showSpinner = false;
        console.log('error getUserAccount', error);
      }
    )
  }

  getUserIsSuperAdmin() {
    this.userService.isSuperAdmin(this.user.user.email).subscribe(
      (data: boolean) => {
        this.user_state.is_superadmin = data;
      },
      error => {
        this.user_state.is_superadmin = false;
      }
    )
  }

  getUserAccountRoles(enterprise: string , token: string, value: string ) {
    this.UserAccountRoleService.allUserAccountRole(enterprise, token, value).subscribe(
      (data: UserAccountRole[]) => {
        debugger
        if (data.length > 0) {

          this.user_account_roles = data;
          this.user_state.user_account_roles  = data;
          this.user_state.user_account_roles = this.user_account_roles;
          this.user_state.user_role_selected = 0;
          this.getUserRole();


        } else {
          this.showSpinner = false;
          this.notification('Algo ocurrió mal, por favor, intente de nuevo');
        }
      },
      error => {
        this.showSpinner = false;
        console.log('error getUserAccount', error);
      }
    )
  }

  getUserRole() {
    this.roleService.getRole(this.enterprise_state.entity.db_name, `${this.user_account_roles[0].users_roles_id}`).subscribe(
      (data: Role) => {
        this.user_state.user_role_name_selected = data.name;
        this.user_state.is_superadmin = data.super_user;
        this.getUserRolePermisions(this.enterprise_state.entity.db_name, 'users_roles_id', this.user_account_roles[0].users_roles_id);
      },
      error => {
        this.notification('Algo ocurrió mal, por favor, intente de nuevo');
        this.showSpinner = false;
      }
    );
  }

  cleanUserPermisionRole(user_menu: UserMenu[]) {
    let cantidad: number = 0;
    for (let i = (this.user_role_permisions.length - 1); i >= 0; i--) {
      cantidad = 0;
      for (let j = 0; j < this.user_role_permisions.length; j++) {
        if (this.user_role_permisions[j].users_menu_id === this.user_role_permisions[i].users_menu_id) {
          cantidad = 1 + cantidad;
        }
      }
      // if (cantidad > 1) {
      //   this.user_role_permisions.splice(i, 1);
      // }
    }
    this.user_state.user_role_permissions = this.user_role_permisions;
    const menu: Menu[] = this.createMenuService.create(user_menu, this.user_role_permisions);
    this.menu = menu;
    this.storeMenu.dispatch(new UpdateMenu(menu));
    let enterprise = {
      acc: null,
      configuration: null,
      entity: null,
      establishment: null,
      inventory: null,
      integration: null,
      invoice: null,
      representatives: null,
      taxes: null,
      branchofficesenterprise: null,
      stablishment_selected: null,
      company_groups: null,
      identity: null,
      enterprises: null,
      aloha_enterprise: false
    }
    enterprise.acc = this.enterprise_state.acc
    enterprise.configuration = this.enterprise_state.configuration
    enterprise.entity = this.enterprise_state.entity
    enterprise.establishment = this.enterprise_state.establishment
    enterprise.inventory = this.enterprise_state.inventory
    enterprise.integration = this.enterprise_state.integration;
    enterprise.invoice = this.enterprise_state.invoice
    enterprise.representatives = this.enterprise_state.representatives
    enterprise.taxes = this.enterprise_state.taxes;
    enterprise.branchofficesenterprise = this.enterprise_state.branchofficesenterprise;
    enterprise.stablishment_selected = this.enterprise_state.stablishment_selected;
    enterprise.company_groups = this.enterprise_state.company_groups;
    enterprise.identity = this.enterprise_state.identity
    enterprise.enterprises = this.user.enterprises
    this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise));
    let user_websocket:UserWebsocket = {
      enterprise: this.enterprise_state.entity,
      user_account: this.user_state.user_account[0]
    }
    this.storeUserWebsocket.dispatch(new UpdateUserWebsocket(user_websocket));
    let user_state = {
      user_account: this.user_state.user_account,
      user_account_roles: this.user_state.user_account_roles,
      user_role_permissions: this.user_state.user_role_permissions,
      user_role_selected: this.user_state.user_role_selected,
      user_role_name_selected: this.user_state.user_role_name_selected,
      refresh_redux: null,
      is_superadmin: this.user_state.is_superadmin
    }
    this.storeUser.dispatch(new UpdateUser(user_state));
    this.showSpinner = false;
    if (!this.automatic) {
      this.notification('Actualizado Correctamente')
    }

  }

  getUserRolePermisions(enterprise: string, token: string, value:string) {
    this.UserRolePermisionService.allUserRolePermisions(enterprise, token, value+'&app=ERP').subscribe(
      (data: UserRolePermision[]) => {
        this.user_role_permisions = data;
        this.getUserMenu(this.enterprise_state.entity.db_name);
      },
      error => {
        this.showSpinner = false;
        console.log('error getUserAccount', error);
      }
    )
  }

  getUserMenu(enterprise: string) {
    this.UserMenuService.allUserMenus(enterprise).subscribe(
      (data: UserMenu[]) => {
        this.cleanUserPermisionRole(data);
      },
      error => {
        this.showSpinner = false;
        console.log('error getUserMenus', error);
      }
    )
  }


  actionLogin(){
    this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.user.user.email);

    if (!this.session_state.token && !this.session_state.email) {
      if (this.sessions.length > 0) {
        this.session.start = Date();
        this.session.token = this.encrypt.set(Date());
        this.session.email = this.user.user.email;
        this.session_state.token = this.session.token;
        this.session_state.email = this.user.user.email;
        if (this.session_state_token !== this.sessions[0].token) {
          // this.storeSession.dispatch(new UpdateSession(this.session_state));
          // this.updateLoginSession(this.sessions[0].id, this.session);
        }
      } else {
        this.session.start = Date();
        this.session.token = this.encrypt.set(Date() + this.user.user.id)
        this.session.email = this.user.user.email
        this.session_state.token = this.session.token
        this.session_state.email = this.user.user.email
        // this.storeSession.dispatch(new UpdateSession(this.session_state));
        // console.log('no tiene sesion activa', this.session);
        // this.insertLoginSession(this.session)

      }
    }

  }

  insertLoginSession(session: any) {
    this.loginSessionService.insertLoginSession(session);
  }

  updateLoginSession(id: string, session: any) {
    this.loginSessionService.updateLoginSession(id, session);
  }
  public automatic = true;
  login(user_email,enterprise_state:EnterpriseState, db_name, automatic = true) {
    this.automatic = automatic;
    this.showSpinner = true;
    this.loginService.getLoginUser('email',user_email+'&product=ERP').subscribe(
      (data) => {
        console.log('loginService', data);
        debugger
        if (data) {
          if (data.status) {
            this.user = data;
            let enterprise: EnterpriseState = data.enterprises.find(v=> v.entity.db_name == db_name)
            this.enterprise_state.acc = enterprise.acc
            this.enterprise_state.configuration = enterprise.configuration
            this.enterprise_state.entity = enterprise.entity
            this.enterprise_state.establishment = enterprise.establishment
            this.enterprise_state.inventory = enterprise.inventory
            this.enterprise_state.integration = enterprise.integration;
            this.enterprise_state.invoice = enterprise.invoice
            this.enterprise_state.representatives = enterprise.representatives
            this.enterprise_state.taxes = enterprise.taxes;
            this.enterprise_state.branchofficesenterprise = enterprise.branchofficesenterprise;
            this.enterprise_state.stablishment_selected = enterprise_state.stablishment_selected;
            this.enterprise_state.company_groups = enterprise.company_groups;
            this.enterprise_state.identity = enterprise.identity
            this.enterprise_state.aloha_enterprise = enterprise.aloha_enterprise
            this.enterprise_state.enterprises = this.user.enterprises
            this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.user.user.email);
          } else {
            this.showSpinner = false;
            this.notification('Algo malo ha ocurrido, por favor, intente de nuevo');
          }
        } else {
          this.showSpinner = false;
          this.notification('Algo malo ha ocurrido, por favor, intente de nuevo');
        }
      },
      error => {
        console.log('error login', error);
        this.showSpinner = false;
      }
    )
  }

  async loginasync(user_email,enterprise_state:EnterpriseState, db_name, automatic = true) {
    this.automatic = automatic;
    this.showSpinner = true;
    await this.loginService.getLoginUser('email',user_email+'&product=ERP').subscribe(
      (data) => {
        console.log('loginService', data);
        debugger
        if (data) {
          if (data.status) {
            this.user = data;
            let enterprise: EnterpriseState = data.enterprises.find(v=> v.entity.db_name == db_name)
            this.enterprise_state.acc = enterprise.acc
            this.enterprise_state.configuration = enterprise.configuration
            this.enterprise_state.entity = enterprise.entity
            this.enterprise_state.establishment = enterprise.establishment
            this.enterprise_state.inventory = enterprise.inventory
            this.enterprise_state.integration = enterprise.integration;
            this.enterprise_state.invoice = enterprise.invoice
            this.enterprise_state.representatives = enterprise.representatives
            this.enterprise_state.taxes = enterprise.taxes;
            this.enterprise_state.branchofficesenterprise = enterprise.branchofficesenterprise;
            this.enterprise_state.stablishment_selected = enterprise_state.stablishment_selected;
            this.enterprise_state.company_groups = enterprise.company_groups;
            this.enterprise_state.identity = enterprise.identity
            this.enterprise_state.aloha_enterprise = enterprise.aloha_enterprise
            this.enterprise_state.enterprises = this.user.enterprises
            let enterprise_2 = {
              acc : this.enterprise_state.acc,
              configuration : this.enterprise_state.configuration,
              entity : this.enterprise_state.entity,
              establishment : this.enterprise_state.establishment,
              inventory : this.enterprise_state.inventory,
              integration : this.enterprise_state.integration,
              invoice : this.enterprise_state.invoice,
              representatives : this.enterprise_state.representatives,
              taxes : this.enterprise_state.taxes,
              branchofficesenterprise : this.enterprise_state.branchofficesenterprise,
              stablishment_selected : this.enterprise_state.stablishment_selected,
              company_groups : this.enterprise_state.company_groups,
              identity : this.enterprise_state.identity,
              enterprises : this.user.enterprises,
            };
         
            debugger
            this.storeEnterprise.dispatch(new UpdateEnterprise(enterprise_2));
            this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.user.user.email);
          } else {
            this.showSpinner = false;
            this.notification('Algo malo ha ocurrido, por favor, intente de nuevo');
          }
        } else {
          this.showSpinner = false;
          this.notification('Algo malo ha ocurrido, por favor, intente de nuevo');
        }
      },
      error => {
        console.log('error login', error);
        this.showSpinner = false;
      }
    )
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EnterprisesModal, {
      width: '250px',
      data: { enterprises: this.user.enterprises, email: this.user.user.email }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.enterprise_state.acc = result.acc
      this.enterprise_state.configuration = result.configuration
      this.enterprise_state.entity = result.entity
      this.enterprise_state.establishment = result.establishment
      this.enterprise_state.inventory = result.inventory
      this.enterprise_state.integration = result.integration;
      console.log(result.integration);

      this.enterprise_state.invoice = result.invoice
      this.enterprise_state.representatives = result.representatives
      this.enterprise_state.taxes = result.taxes;
      this.enterprise_state.branchofficesenterprise = result.branchofficesenterprise;
      this.enterprise_state.stablishment_selected = result.stablishment_selected;
      this.enterprise_state.company_groups = result.company_groups;
      debugger
      this.enterprise_state.identity = result.identity
      this.enterprise_state.enterprises = this.user.enterprises
      this.getUserAccount(this.enterprise_state.entity.db_name, 'email', this.user.user.email);
    });
  }



  notification(masage: string ) {
    this._snackBar.open(masage, null, {
      duration: 6000
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}


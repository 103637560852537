import { Component, Inject } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
import { InvoiceProvider } from 'src/app/providers/client/invoice.provider';



@Component({
  selector: 'app-invoice-list-modal',
  templateUrl: './invoice-list.modal.html',
  styleUrls: ['./invoice-list.modal.scss'],
})

export class InvoiceListDeliveryModal {
    public searchFilterText: string = '';
    public searchText: string = '';
    filters: any;
    invoices: any[];
    invoices_view_paginator: ViewPaginator;
    public invoiceInput: string = '';
    public balanceInput: string = '';
    public clientInput: string = '';
    public clientIdInput: string = '';
    public sriInput: string = '';
    public statusFinInput: string = '';
    public subtotalInput: string = '';
    public dateInput: string = '';
    public ivaInput: string = '';
    public totalInput: string = '';
    public paymentRetentionInput: string = '';
    public paymentsInput: string = '';
    public statusInternInput: string = '';
    constructor(
      private _snackBar: MatSnackBar,
      private invoiceService: InvoiceProvider,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if (this.data.enterprise) {
        this.invoices_view_paginator = this.data.invoices_view_paginator;
        this.invoices = this.data.invoices
      }
    
    }

    getViewInvoices(){
      // this.openDialogLoadModal()
      this.setFilters()
      this.invoiceService.getViewInvoice(this.data.enterprise, this.filters).subscribe(
        (data: ViewPaginator) => {
          this.invoices = data.data; 
          this.invoices_view_paginator = data;
        },
        error => {
          console.log('getListInvoices', error);
        }
      )
    }

    getInvoicesNextPreviousPage(url) {
      this.setFilters();
      var a = '';
     
      if (this.filters) {
         a = this.filters;
      }
      this.invoiceService.getInvoicesNextPreviousPage(url + a ).subscribe(
        (data: ViewPaginator) => {
          this.invoices_view_paginator = data;
          this.invoices = data.data; 

        },
        error => {
          console.log('error getInvoices', error);
        }
      )
    }

    setFilters() {
      let document = this.invoiceInput?this.invoiceInput: ''
      this.filters = '&filter=true'+'&status_intern=' + this.statusInternInput + '&document_number=' + document + '&balance=' + this.balanceInput
      + '&status_sri=' +this.sriInput
      + '&date_invoice=' +this.dateInput + '&subtotal=' +this.subtotalInput + '&tax=' + this.ivaInput
      + '&total=' + this.totalInput 
    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true

      }
    }

    notification(masage: string) {
      this._snackBar.open(masage, null, {
        duration: 10000
      });
    }
}

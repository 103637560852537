import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLProvider } from '../url.providers';




@Injectable()
export class UploadProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public previewFileDir() {
    return this.urlProvider.preview_file_dir();
  }

  public previewFileDirLogos() {
    return this.urlProvider.preview_file_dir_logos();
  }
  public previewFileDire(url) {
    return this.urlProvider.preview_file_dire(url);
  }

  public previewLogoFile() {
    return this.urlProvider.preview_logo_file();
  }

  public uploadFile(): string {
    return this.urlProvider.upload_file();
  }
  public uploadXML(db_name,filter):string{
    return this.urlProvider.upload_xml(db_name)+filter;
  }

  public uploadFileTXT(): string {
    return this.urlProvider.upload_file() + '/txt';
  }
  public uploadFileSignatures(db_name): string {
    return this.urlProvider.upload_file() +   '/' +db_name+ '/signatures';
  }
  public uploadFileVouchers(db_name): string {
    return this.urlProvider.upload_file() + '/' +db_name+ '/vouchers';
  }

  public uploadFileTitles(db_name): string {
    return this.urlProvider.upload_file() + '/' +db_name+ '/titles';
  }

  public deleteFileVouchers(db_name, data, multiple:any) {
    return this.http.post<Boolean>(this.urlProvider.upload_file() + '/' +db_name+ '/delete/vouchers?multiple='+multiple, data, this.urlProvider.httpOptions);
  }

  public deleteFileTitles(db_name, data, multiple:any) {
    return this.http.post<Boolean>(this.urlProvider.upload_file() + '/' +db_name+ '/delete/titles?multiple='+multiple, data, this.urlProvider.httpOptions);
  }

  public uploadFileVouchersUrl(): string {
    return this.urlProvider.preview_file_dire('vouchers') ;
  }

  public uploadFileVouchersUrlTitle(): string {
    return this.urlProvider.preview_file_dire('titles') ;
  }

  public deleteFile(file_name: string) {
    return this.http.delete<Boolean>(this.urlProvider.upload_file() + '/' + file_name, this.urlProvider.httpOptions);
  }

  public deleteVehicleFile(db_name:string,vehicle_id:any,file_name: string,type) {
    return this.http.delete<any>(this.urlProvider.upload_file() + '/' +db_name+'/' + file_name+'/' + vehicle_id+'?type='+type, this.urlProvider.httpOptions);
  }

}

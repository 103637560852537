import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserAccountRole } from '../../models/client/UserAccountRole';

import { URLProvider } from '../url.providers';

@Injectable()
export class UserAccountRoleProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allUserAccountRole(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<UserAccountRole[]>(this.urlProvider.user_account_role(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<UserAccountRole[]>(this.urlProvider.user_account_role(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getUserAccountRole(enterprise: string, id: string) {
    return this.http.get<UserAccountRole>(this.urlProvider.user_account_role(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertUserAccountRole(enterprise: string, user_account_roles: UserAccountRole[]) {
    return this.http.post<Boolean>(this.urlProvider.user_account_role(enterprise), user_account_roles, this.urlProvider.httpOptions);
  }

  public updateUserAccountRole(enterprise: string, UserAcount: UserAccountRole, id: string) {
    return this.http.put<Boolean>(this.urlProvider.user_account_role(enterprise) + '/' + id, UserAcount, this.urlProvider.httpOptions);
  }

  public deleteUserAccountRole(enterprise: string, UserAcount: UserAccountRole[]) {
    return this.http.put<Boolean>(this.urlProvider.user_account_role(enterprise), UserAcount, this.urlProvider.httpOptions);
  }


}

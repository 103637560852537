import { Utilities } from './../../../utils/utilities';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/models/client/Product';


import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ProductProvider } from 'src/app/providers/client/product.provider';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
/* END Redux */

@Component({
  selector: 'app-products-in-piece-modal',
  templateUrl: './products-in-piece.modal.html',
  styleUrls: ['./products-in-piece.modal.scss'],
})

export class ProductsInPiece implements OnInit {

  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  public selected_products: Product[] = []
  public products_view_paginator: ViewPaginator = null;
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  index_step: any;


    constructor(
      public dialogRef: MatDialogRef<ProductsInPiece>,
      private toastrService: ToastrService,
      private productService: ProductProvider,
      public utilities: Utilities,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
      this.index_step = data.index_step;
      if (this.data.products_selected) {
        this.selected_products = this.data.products_selected.length > 0?this.data.products_selected: [] ;
      } else {
        this.selected_products = [];
      }

    }

    ngOnInit(): void {
      console.log(this.data);
      this.products_view_paginator = null
      this.searchText = null;
    }

    getProductsNextPreviousPage(url) {
      debugger
      if (this.data.supplies) {
        var token = this.searchText ? '&search_supplies='+ this.searchText : '&raw_material=true';
        
      } else {
        var token = this.searchText ? '&search_raw_material='+ this.searchText : '&raw_material=true';

      }
      let url_new = url +  token
      this.productService.nextPreviousPageProducts(url_new, 10).subscribe(
        (data: ViewPaginator) => {
          this.products_view_paginator = data;
          // this.data.product.data = null
        },
        error => {
          console.log('error getClientProviders', error);
        }
      )
    }

    searchRawMaterialProducts() {
      this.showSpinner = true
      let token = '';
      this.data.supplies ? token = 'search_supplies' : token = 'search_raw_material'
      this.productService.viewRawMaterialProducts(this.enterprise, token, this.searchText).subscribe(
        (data: ViewPaginator) => {
          debugger;
          let tdata = data.data.map(element => {
            return { ...element, control_stock: false };
          });
          this.products_view_paginator = { ...data, data: tdata };
          this.showSpinner = false
          // this.data.product.data = null
        },
        error => {
          this.showSpinner = false
          this.utilities.showError('Error en búsqueda', error.error.response)
          console.log('error getClientProviders', error);
        }
      )
    }



    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      debugger
      
      for (let i = 0; i < checkbox.length; i++) {
        if (checkbox[i].checked) {
        
        } else {
          checkbox[i].checked = true;
          let a = checkbox[i].value
          debugger
          let product = null
          if (this.data.product != null) {
            product = this.data.product.data.find(v => v.id == checkbox[i].value)
          } else {
            product = this.products_view_paginator.data.find(v => v.id == checkbox[i].value)
          }
          console.log(product, i);
          this.changeCheckbox(i, product, true)
        }
        
        
      } 
    }

    changeCheckbox(index: any, product: Product, checked:Boolean){
      debugger
      
      
      
      if (checked) {
        let index_of_selected_product = this.selected_products.findIndex(v=> v.id == product.id);
        if (index_of_selected_product < 0) {
          this.selected_products.push(product)
      }
      }else {
        let index_of_selected_product = this.selected_products.findIndex(v=> v.id == product.id);
        if (index_of_selected_product >= 0) {
          this.selected_products.splice(index_of_selected_product, 1)
        }
      }
    }

    checkedProduct( product : Product){
      console.log("id_product_checkedProduct", product.id)
      let index_of_selected_product = this.selected_products.findIndex( v=> v.id == product.id);
      if (index_of_selected_product >= 0) {
        return true;
      } else {
        return false;
      }
    }

    
    disabledProduct(product: Product){
      
      if (!this.data.production_lot) {
        
        if (this.selected_products.length > 0) {
          let index_of_selected_product = this.selected_products.findIndex( v=> v.id == product.id);
          
          if (index_of_selected_product >= 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        } 
      } else {
        

        if (!product.control_stock) {
          return false;
        } else {
          let a = this.data.product_piece
          let quantity = 1;
          for (let i = 0; i < this.data.product_piece.steps.length; i++) {
            const steps = this.data.product_piece.steps[i];
            if (steps.filters.length > 0) {
              let index_of_size = steps.filters[0].items.filter(v=> v.selected);
              if(index_of_size.length > 1){
                quantity = index_of_size.length;
              }
            }
           
          }

          let index = product.invproductwarehousedetail.findIndex(v=> Number(v.stock) - Number(v.stock_reserved) >= (Number(this.data.product_piece.base_material) * quantity))
          if (index >= 0) {
            return false;
          } else {
            return true;
          }
        }
      }
    }

    addProducts(){
      debugger;
      let products_id = []
      for (let i = 0; i < this.selected_products.length; i++) {
        products_id.push(this.selected_products[i].id)
      }
      this.close(this.selected_products, products_id)
    }

    close(products, products_id): void {
      if (!this.data.production_lot) {
        var product: any = {
          product: products[0],
          index_step: this.index_step
        } 
      } else {
        var product: any = {products: products}
      }
       
      this.dialogRef.close(product);
      // this.dialogRef.close([products, products_id]);
    }

    deleteProduct(index_product) {
      this.selected_products.splice(index_product, 1);
    }


    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }
    getPaginatorData(event){
      console.log(event);
      if(event.pageIndex === this.pageIndex + 1){
         this.lowValue = this.lowValue + this.pageSize;
         this.highValue =  this.highValue + this.pageSize;
      }
      else if(event.pageIndex === this.pageIndex - 1){
        this.lowValue = this.lowValue - this.pageSize;
        this.highValue =  this.highValue - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }

}

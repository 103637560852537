import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CostCenter } from '../../models/client/CostCenter';
import { URLProvider } from '../url.providers';

@Injectable()
export class CostCenterProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allCostCenter(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<CostCenter[]>(this.urlProvider.cost_center(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<CostCenter[]>(this.urlProvider.cost_center(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getCostCenter(enterprise: string, id: string) {
    return this.http.get<CostCenter>(this.urlProvider.cost_center(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insertCostCenter(enterprise: string, cost_center: CostCenter) {
    return this.http.post<number>(this.urlProvider.cost_center(enterprise), cost_center, this.urlProvider.httpOptions);
  }

  public updateCostCenter(enterprise: string, cost_center: CostCenter, id: string) {
    return this.http.put<Boolean>(this.urlProvider.cost_center(enterprise) + '/' + id, cost_center, this.urlProvider.httpOptions);
  }

}

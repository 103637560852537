import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ElectronicalVoucherInvoiceProvider } from "../../../providers/utils/electronical-voucher-invoice.provider";

import { ToastrService } from 'ngx-toastr';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
/* END Redux */

@Component({
  selector: 'app-forward-by-batch-modal',
  templateUrl: './forward-by-batch.modal.html',
  styleUrls: ['./forward-by-batch.modal.scss'],
})

export class ForwardByBatchModal {
  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;

    constructor(
      public dialogRef: MatDialogRef<ForwardByBatchModal>,
      private electronicalVoucherInvoiceService: ElectronicalVoucherInvoiceProvider,
      private toastrService: ToastrService,
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
    }

    selectAll(){
      let checkbox: any = document.getElementsByClassName('mat-checkbox-input');
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
      }
    }

    forwardByBatch() {
      this.showSpinner = true;
      this.invoices = [];
      let checkboxes: any = document.getElementsByClassName('mat-checkbox-input')
      let checkboxesChecked = []
      this.doneCheckbox = false
      for (let i = 0; i < checkboxes.length; i++) {
        if (i+1 == checkboxes.length) {
          this.doneCheckbox = true
        }
        if (checkboxes[i].checked === true) {
          checkboxesChecked.push(checkboxes[i])
        }
      }
      if (this.doneCheckbox) {
        this.doneCheckbox = false
        for (let j = 0; j < checkboxesChecked.length; j++) {
          console.log(checkboxesChecked[j].value);
          this.invoices.push(
            {
              invoice_id: Number(checkboxesChecked[j].value)
            }
          )
        }
        this.electronicalVoucherInvoiceService.resendEmail(this.enterprise, this.invoices).subscribe(
          (data: any) => {
            this.showSpinner = false;
            if (data.status) {
              this.showSuccess(data.response);
            } else {
              this.showError(data.response);
            }
            this.dialogRef.close();
          },
          error => {
            this.showError(error);
            this.dialogRef.close();
            this.showSpinner = false;
          }
        );
      } else {
        this.dialogRef.close();
        this.showSpinner = false;
      }
    }

    showError(error: string) {
      this.toastrService.error(error, 'ERROR', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

    showSuccess(success: string) {
      this.toastrService.success(success, 'PROCESADO', {
        timeOut: 40000,
        positionClass: 'toast-bottom-center'
      });
    }

}

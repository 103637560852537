
import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.modal.html',
  styleUrls: ['./loading-modal.modal.scss'],
})

export class LoadingModal {

  constructor() {}

}




import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Invoice } from '../../models/client/Invoice';
import { InvoiceItem } from '../../models/client/InvoiceItem';
import { InvoiceTaxBase } from '../../models/client/InvoiceTaxBase';
import { InvoicePaymentMethod } from '../../models/client/InvoicePaymentMethod';
import { InvoiceAdditionalInfo } from '../../models/client/InvoiceAdditionalInfo';
import { URLProvider } from '../url.providers';
import { AdditionalDefaultInfo } from 'src/app/models/client/AdditionalDefaultInfo';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';

@Injectable()
export class InvoiceProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allInvoices(enterprise: string, token: string = '', value: string = '', end_date: string = '') {
    if (token !== '') {
      return this.http.get<Invoice[]>(this.urlProvider.invoice(enterprise) + '?' + token + '=' + value + '&' + 'end_date' + '=' + end_date, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Invoice[]>(this.urlProvider.invoice(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allInvoices3Tokens(enterprise: string, token: string = '', value: string = '',  token2: string = '', value2: string = '', token3: string, value3: string = '') {
    if (token !== '') {
      return this.http.get<Invoice[]>(this.urlProvider.invoice(enterprise) + '?' + token + '=' + value + '&' + token2 + '=' + value2+ '&' + token3 + '=' + value3, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Invoice[]>(this.urlProvider.invoice(enterprise), this.urlProvider.httpOptions);
    }
  }


  public getInvoice(enterprise: string, id: string) {
    return this.http.get<any[]>(this.urlProvider.invoice(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public sendBuyFromInvoice(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.invoice(enterprise) + '/create-buy/' + id, this.urlProvider.httpOptions);
  }

  public pdfInvoice(enterprise: string, type: string, access_key: string, sri = '') {
    return this.urlProvider.invoice_pdf(enterprise, type, access_key, sri);
  }

  public getPdfInvoiceAccoutingVoucher(enterprise: string, type:string, id: string) {
    return this.urlProvider.pdf_accouting_voucher(enterprise, type,id);
  }

  public getListInvoice(enterprise: string, start_date, end_date: string = '', branch_offices: string= '', row = 10) {
    return this.http.get<ViewPaginator>(this.urlProvider.invoice(enterprise) + '/list' + '?' + 'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date
    +'&branch_offices_id='+ branch_offices+ '&row='+ row, this.urlProvider.httpOptions);
  }

  public getViewInvoice(enterprise: string, filter: string = '', branch_offices: string= '', row = 10) {
    return this.http.get<ViewPaginator>(this.urlProvider.invoice(enterprise) + '/view?' + `${filter}&branch_offices_id= ${branch_offices}` + '&row='+row, this.urlProvider.httpOptions);
  }

  public getInvoicesNextPreviousPage(url, per_page='') {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }

  public getListInvoiceBalance(enterprise: string) {
    return this.http.get<any>(this.urlProvider.invoice_balance(enterprise) , this.urlProvider.httpOptions);
  }

  public geInvoiceByDocument(enterprise: string, document: string) {
    return this.http.get<any>(this.urlProvider.invoice_bydocument(enterprise)+'/' + document , this.urlProvider.httpOptions);
  }

  public findByDocument(enterprise: string, document: string) {
    return this.http.get<any>(this.urlProvider.invoice_findbydocument(enterprise)+'/' + document , this.urlProvider.httpOptions);
  }
  // Invoice
  public insertInvoice(enterprise: string, invoice: UpdateInvoice) {
    return this.http.post<number>(this.urlProvider.invoice(enterprise), invoice, this.urlProvider.httpOptions);
  }

  public insertInvoiceConfiguration(enterprise: string, invoice: any) {
    return this.http.post<number>(this.urlProvider.invoice(enterprise) + '/newconfiguration', invoice, this.urlProvider.httpOptions);
  }

  public getInvoiceConfiguration(enterprise: string) {
    return this.http.get<any>(this.urlProvider.invoice(enterprise) + '/newconfiguration/get', this.urlProvider.httpOptions);
  }

  public getProductCatalog(enterprise: string,categories:any) {
    return this.http.put<any>(this.urlProvider.invoice(enterprise) + '/productcatalog/get',categories, this.urlProvider.httpOptions);
  }

  public updateInvoice(enterprise: string, invoice: Invoice, id: string) {
    return this.http.put<Boolean>(this.urlProvider.invoice(enterprise) + '/' + id, invoice, this.urlProvider.httpOptions);
  }

  public editInvoice(enterprise: string, invoice: UpdateInvoice) {
    return this.http.put<Boolean>(this.urlProvider.invoice(enterprise), invoice, this.urlProvider.httpOptions);
  }

  public editPayment(enterprise: string, invoice, id) {
    return this.http.put<Boolean>(this.urlProvider.invoice(enterprise) + '/payment-method/' + id, invoice, this.urlProvider.httpOptions);
  }

  public deleteInvoice(enterprise: string, id: string, updated_by: any) {
    return this.http.delete<any>(this.urlProvider.invoice(enterprise) + '/' + id + '?updated_by=' + updated_by, this.urlProvider.httpOptions);
  }

  public allInvoicesByRange(enterprise: string, branch_offices_id: string = '',  start_date: string = '', end_date: string = '') {
    if (branch_offices_id === '0') {
      branch_offices_id='';
    }
    return this.http.get<Invoice[]>(this.urlProvider.invoice(enterprise) + '?branch_offices_id=' + branch_offices_id + '&start_date=' + start_date+ '&end_date=' + end_date, this.urlProvider.httpOptions);

  }

  public getAlohainvoices(enterprise: string, date: string) {
    return this.http.post<any[]>(this.urlProvider.alohaenterprise() + '/sales/reprocessupdatesale/' + enterprise + '?date='+ date,null, this.urlProvider.httpOptions);
  }

  public getProductionOrderByInvoice(enterprise: string, id: string) {
    return this.http.get<any[]>(this.urlProvider.invoice(enterprise) + '/searchOrderProductionInvoice/' + id, this.urlProvider.httpOptions);
  }

}


export interface UpdateInvoice {
  Invoice01Main: any;
  Invoice02Item: any[],
  delete_Invoice02Item?: any[],
  Invoice04TaxBase: any[],
  Invoice05PaymentMethod: any[],
  delete_Invoice06AdditionalInfo?: any[],
  Invoice06AdditionalInfo: any[]
};

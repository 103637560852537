import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class InvoiceListDeliveryModalFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    if (!filterText) return list;
    return list.filter(
      (v) =>
        v.document_number.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        v.tax.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        v.balance.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        v.status_sri.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        v.date_invoice.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        v.total.toLowerCase().indexOf(filterText.toLowerCase()) > -1
    );
  }

}

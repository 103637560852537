import { Utilities } from './../../../utils/utilities';
import { Component, Inject, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Product } from '../../../models/client/Product';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
// import { ProductionOrderMainProvider } from '../../../providers/client/production_order_main.provider';

/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
import { ProductProvider } from '../../../providers/client/product.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { GetDeliver } from '../../../models/client/GetDeliver';
import { ProductLot } from '../../../models/client/ProductLot';
import { InventoryTransferItem } from 'src/app/models/client/InventoryTransferItem';
import { log } from 'console';
/* END Redux */

@Component({
  selector: 'app-product-warehouse-detail-modal',
  templateUrl: './product-warehouse-detail.modal.html',
  styleUrls: ['./product-warehouse-detail.modal.scss'],
})

export class ProductWarehouseDetail implements AfterViewInit, OnDestroy {

  public showSpinner: boolean = false;
  private invoices: any[] = [];
  public doneCheckbox = false;
  private enterprise: string = '';
  private enterprise$: Observable<EnterpriseState>;
  public block_stock:InventoryTransferItem;
  public warehouse_details_selected :ProductLot[] = [];
  public products_view_paginator: ViewPaginator = null;
  public searchText: string = '';
  public pageIndex:number = 0;
  public pageSize:number = 5;
  public lowValue:number = 0;
  public highValue:number = 5;
  public pageEvent;
  protected _onDestroy = new Subject<void>();


    constructor(
      public dialogRef: MatDialogRef<ProductWarehouseDetail>,
      private toastrService: ToastrService,
      private productService: ProductProvider,
      public utilities: Utilities, 
      private store: Store<{enterprise: EnterpriseState}>,
      @Inject(MAT_DIALOG_DATA) public data: {data: any, warehouse_id: number, type?:string, production_lot?: boolean, warehouses_id: any,
        base_material: any, product_piece:any, supplie?:boolean, internally_made?: boolean, quantity?:number, 
        negative_stock?: boolean, warehouses: any[], din? : boolean}
    ) {
      this.enterprise$ = store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        },
        error => {
          console.log('enterprise redux', error);
        }
      )
      this.block_stock = this.data.data;
        console.log(this.data);
      if (this.data.supplie || this.data.type == 'supplie') {
        this.base_material = this.data.base_material
      }
      console.log(this.data);

    }

    ngAfterViewInit(): void {
      console.log(this.data);
      this.products_view_paginator = null
      this.searchText = null;
      // this.getProductionOrders('');
      if (!this.data.type) {
        this.getProductAllWarehouse(this.block_stock.inv_product_id)
      }
    }
    public warehouse_details: ProductLot[];
    public production_order_view_paginator: ViewPaginator;

    public base_material = 0;
    getProductAllWarehouse(id) {
      this.showSpinner = true;
      let type = 'OUT'
      if (this.data.internally_made) {
        type = 'IN'
      }
      let warehouse = this.data.warehouses_id?this.data.warehouses_id:'all';
      console.log("inv_product_id", id)
      console.log("warehouses", warehouse)
      console.log("type, basematerial", type+'&base_material='+this.data.base_material)
      this.productService.getLotProduct(this.enterprise, id, warehouse, type+'&base_material='+this.data.base_material).subscribe(
        (data: ProductLot[]) => {
          if (this.data.supplie) {
            
          }
          debugger
          this.warehouse_details = data
          if (this.data.supplie && this.data.base_material) {
            this.warehouse_details = data.filter(v => Number(v.stock) >= Number(this.data.base_material) && v.id_warehouse != 22)
          }
          for (let i = 0; i < this.warehouse_details.length; i++) {
            if (this.data.production_lot) {
              let quantity = 1;
              for (let i = 0; i < this.data.product_piece.steps.length; i++) {
                const steps = this.data.product_piece.steps[i];
                if (steps.filters.length > 0) {
                  let index_of_size = steps.filters[0].items.filter(v=> v.selected);
                  if(index_of_size.length > 1){
                    quantity = index_of_size.length;
                  }
                }
                
              }
              if (this.base_material == 0)  {
                this.base_material = (Number(this.data.data.base_material)* quantity);
              }
              this.warehouse_details[i].stock = Number((Number(this.warehouse_details[i].stock) - Number(this.warehouse_details[i].stock_reserved)).toFixed(2))
              if (this.warehouse_details[i].stock >= (Number(this.data.data.base_material)* quantity)) {
              } else {
                this.warehouse_details[i].stock = 0;
              }
            } else {
              this.warehouse_details[i].stock = Number((Number(this.warehouse_details[i].stock) - Number(this.warehouse_details[i].stock_reserved)).toFixed(2))
            }
          }
          this.showSpinner = false


        }, error => {
          this.showSpinner = false
          console.log(error, 'error')
        }
      )
      

    }

    ableStock(warehouse_detail: ProductLot) {
      if (this.data.internally_made) {
        if (this.data.negative_stock) {
          return true
        } else {
          if (warehouse_detail.stock >= this.data.quantity) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (warehouse_detail.stock > 0) {
          return true;
        } else {
          return false;
        }

      }

    }

    getProductsNextPreviousPage(url) {
      let warehouse = this.data.warehouses_id?this.data.warehouses_id:'all';

      if (this.data.type == 'supplies') {
        var token = this.searchText ? '&search_supplies='+ this.searchText +'&id_warehouse='+warehouse : '&raw_material=true&id_warehouse='+warehouse;
      } else {
        var token = this.searchText ? '&search_raw_material='+ this.searchText +'&id_warehouse='+warehouse : '&raw_material=true&id_warehouse='+warehouse;
      }

      let url_new = url +  token
      this.productService.nextPreviousPageProducts(url_new, 10).subscribe(
        (data: ViewPaginator) => {
          if (this.data.type == 'supplies') {
            data.data.forEach(product=> {
              product.invproductwarehousedetail = product.invproductwarehousedetail.filter(v=> Number(v.stock) - Number(v.stock_reserved) >= Number(this.data.base_material))
            })
          }
          
          this.products_view_paginator = data;
          // this.data.product.data = null
          this.hasValue()
          this.hasValue()
        },
        error => {
          console.log('error getClientProviders', error);
        }
      )
    }

    searchRawMaterialProducts() {
      this.showSpinner = true
      if (this.data.type == 'supplies') {
        var token = 'search_supplies';
      } else {
        var token = 'search_raw_material';
      }
      let warehouse = this.data.warehouses_id?this.data.warehouses_id:'all';

      this.productService.viewRawMaterialProducts(this.enterprise, token, this.searchText+'&id_warehouse='+warehouse).subscribe(
        (data: ViewPaginator) => {
          
          if (this.data.type == 'supplies') {
            data.data.forEach(product=> {
              product.invproductwarehousedetail = product.invproductwarehousedetail.filter(v=> Number(v.stock) - Number(v.stock_reserved) >= Number(this.data.base_material))
            })
          }

          this.products_view_paginator = data;
          this.hasValue()
          if(data.data.length == 0) {
            this.utilities.showInfo('Ha finalizado la busqueda del producto', 'No se ha encontrado informacion de stock en ninguna bodega, lote o pieza')
          }
          this.showSpinner = false
          
          // this.data.product.data = null
        },
        error => {
          this.showSpinner = false
          this.utilities.showError('Error es busqueda de Productos', error.error.response)
          console.log('error getClientProviders', error);
        }
      )
    }

    getWarehouse(id) {
      if (this.data.warehouses) {
        let warehouse = this.data.warehouses.find(v=> v.id == id)
        if (warehouse) {
          if (warehouse.name != '91 Producto Terminado') {
            return true;

          } else {
            return false;

          }
        } else {
          return false;
        }
      } else {
        return  true
      }
    }


    haveWareheouse(id){
      if (this.data.warehouses_id) {
        let includes = this.data.warehouses_id.includes(`${id}`);
        return includes;
      } else {
        return true;
      }

    }

    disabledInput(warehouse_detail: ProductLot) {
      if (!this.data.production_lot) {
        if (this.data.supplie) {
          let index_of_selected_product = this.warehouse_details.findIndex( v=> Number(v.quantity_selected) > 0 &&
          v.id_warehouse_detail != warehouse_detail.id_warehouse_detail);
          if (index_of_selected_product >= 0) {
            return true;
          } else {
            return false
          }
        } else {
          return false;

        }
      } else {
        let index_of_selected_product = this.warehouse_details.findIndex( v=> Number(v.quantity_selected) > 0 &&
        v.id_warehouse_detail != warehouse_detail.id_warehouse_detail);
        if (index_of_selected_product >= 0) {
          return true;
        } else {
          return false
        }

      }
    }

    disabledProduct(warehouse_detail: ProductLot){
      if (this.warehouse_details_selected.length > 0) {
        let index_of_selected_product = this.warehouse_details_selected.findIndex( v=> v.id_warehouse_detail == warehouse_detail.id_warehouse_detail);
        if (index_of_selected_product >= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    disabledProductSelected(warehouse_detail) {
      let index = this.products_selected.findIndex(v=>v.warehouse.id == warehouse_detail)
      if (this.products_selected.length > 0 ) {
        if (index >= 0) {
          return false;
        } else {
          return true;

        }
      } else {
        return false
      }

    }

    products_selected: any[]=[];
    changeQuantity(warehouse_detail: any, product: Product = null, event = false){
      debugger
      if (this.data.production_lot) {
        if (warehouse_detail.selected) {
          let index_warehouse_details = this.warehouse_details.findIndex(v=> v.id_warehouse_detail == warehouse_detail.id_warehouse_detail)
          this.warehouse_details[index_warehouse_details].quantity_selected = this.data.base_material?this.data.base_material:1
        } else {
          let index_warehouse_details = this.warehouse_details.findIndex(v=> v.id_warehouse_detail == warehouse_detail.id_warehouse_detail)
          this.warehouse_details[index_warehouse_details].quantity_selected = 0
        }

      }

      if (this.data.type == 'supplies' || this.data.supplie) {
        if (event) {
          warehouse_detail.quantity_selected = this.data.base_material > 0?this.data.base_material:1;
        } else {
          warehouse_detail.quantity_selected = 0
        }
      } 




      
      if (Number(warehouse_detail.quantity_selected) > Number(warehouse_detail.stock)) {
        warehouse_detail.quantity_selected = warehouse_detail.stock
      } else {
        if (product) {
         let index = this.products_selected.findIndex(v=> v.warehouse.id == warehouse_detail.id)
          if (index >= 0) {
            if (Number(warehouse_detail.quantity_selected) == 0) {
              this.products_selected.splice(index);
            } else {
              this.products_selected[index].product = product;
              this.products_selected[index].warehouse = warehouse_detail;
            }
          } else {
            this.products_selected.push({
              product: product,
              warehouse: warehouse_detail
            });
          }
          console.log(this.products_selected);
        }
      }

    }

    hasValue(){
      let index = this.products_view_paginator.data.findIndex(v=> v.id == 60522)
      if (index >= 0) {

      }
      for (let i = 0; i < this.products_selected.length; i++) {
        let index_product = this.products_view_paginator.data.findIndex(v=> v.id == this.products_selected[i].product.id)
        if (index_product >= 0) {
          let warehouses = this.products_view_paginator.data[index_product].invproductwarehousedetail
          let index_warehouse = warehouses.findIndex(v=> v.id == this.products_selected[i].warehouse.id)
          if (index_warehouse >= 0) {
            console.error(true);

            warehouses[index_warehouse].quantity_selected = this.products_selected[i].warehouse.quantity_selected
          }
        }

      }


    }

    changeCheckbox(index: any, warehouse_detail: ProductLot){

      let checkbox: any = document.getElementsByClassName('mat-checkbox-input-modal-'+warehouse_detail.id_warehouse_detail)[0];

      if (checkbox.checked) {
        let index_of_selected_product = this.warehouse_details_selected.findIndex(v=> v.id_warehouse_detail == warehouse_detail.id_warehouse_detail);
        if (index_of_selected_product < 0) {
          this.warehouse_details_selected.push(warehouse_detail)
          console.log(this.warehouse_details_selected);
        }
      }else {

        let index_of_selected_product = this.warehouse_details_selected.findIndex(v=> v.id_warehouse_detail == warehouse_detail.id_warehouse_detail);
        if (index_of_selected_product >= 0) {
          this.warehouse_details_selected.splice(index_of_selected_product, 1)
        }
      }
    }

    checkedProduct( production_order: GetDeliver){
      // let index_of_selected_product = this.warehouse_details_selected.findIndex( v=> v.production_order_02item_id == production_order.production_order_02item_id);
      // if (index_of_selected_product >= 0) {
      //   return true;
      // } else {
      //   return false;
      // }
    }

    addProducts(){
      if (!this.data.production_lot) {
        if (this.data.supplie) {
          // cuando sea supplie para forros y cambiar el this.warehouse_detail
          this.addSupplies(true);

        } else {
          if (!this.data.type) {
            this.close()
          } else if (this.data.type == 'supplies') {
            this.addSupplies();
          } else {
            this.addOtherProducts()
          }
        }

      } else {
        if (this.warehouse_details.length > 0) {
          let index_selected = this.warehouse_details.findIndex(v=> Number(v.quantity_selected) > 0)
          if (index_selected >= 0) {
            this.dialogRef.close(this.warehouse_details[index_selected]);
          }
        }

      }

    }

    close(): void {
      let block_stocks = [];
      let warehouse_details = []
      for (let i = 0; i < this.warehouse_details.length; i++) {
        if (this.warehouse_details[i].quantity_selected > 0) {
          if(!this.data.din) {
            block_stocks.push({
              production_order_02item_id: this.data.data.production_order_02item_id,
              inv_transfer_01main_id: 0,
              inv_product_warehouse_detail_id : this.warehouse_details[i].id_warehouse_detail,
              inv_product_id: this.warehouse_details[i].id_inv_product,
              warehouse_id: this.warehouse_details[i].id_warehouse,
              initial_stock : 0,
              final_stock : 0,
              inv_lot_id: this.warehouse_details[i].code,
              quantity: this.warehouse_details[i].quantity_selected,
              received_quantity: this.warehouse_details[i].quantity_selected,
              rejected_quantity: this.warehouse_details[i].quantity_selected,
              // se utilizara rejecte quantity para hacer la base  de catidad
              reason: '',
              value_sell: 0,
              tax_base: 0,
              rate_pct: 0,
              vat: 0,
              total: 0,
              bar_code: this.warehouse_details[i].bar_code,
              name_product: this.warehouse_details[i].product,
              delete: false,
              selected: false,
              inv_block_stock_id: 0,
              inv_piece: this.warehouse_details[i].inv_piece,
              inv_transfer_03item_detail: {
                product_name: this.data.data.inv_transfer_03item_detail.product_name,
                production_order_number: this.data.data.inv_transfer_03item_detail.production_order_number,
                inv_transfer_02item_id: 0,
                production_order_01main_id: this.data.data.inv_transfer_03item_detail.production_order_01main_id,
                production_order_02item_id: this.data.data.inv_transfer_03item_detail.production_order_02item_id,
                inv_product_id: this.data.data.inv_transfer_03item_detail.inv_product_id,
                bar_code: this.data.data.inv_transfer_03item_detail.bar_code,
                aux_code: this.data.data.inv_transfer_03item_detail.aux_code,
                inv_lot_code: this.data.data.inv_transfer_03item_detail.inv_lot_code,
                initial_quantity: this.warehouse_details[i].quantity_selected,
                delivered_quantity: this.warehouse_details[i].quantity_selected,
                difference_delivered: 0,
                residue: false,
                additional_quantity: true,
              }
            })
          }
          warehouse_details.push(this.warehouse_details[i])
        }
      }
      let object = {
        block_stocks: block_stocks,
        warehouse_details: warehouse_details
      }
      this.dialogRef.close(object);
    }

    addOtherProducts() {
      let block_stocks = [];
      let warehouse_details = []
      for (let i = 0; i < this.products_selected.length; i++) {
        if (this.products_selected[i].warehouse.quantity_selected > 0) {

          block_stocks.push({
            production_order_02item_id: this.data.data.production_order_02item_id,
            inv_transfer_01main_id: 0,
            inv_product_warehouse_detail_id : this.products_selected[i].warehouse.id,
            inv_product_id: this.products_selected[i].product.id,
            warehouse_id: this.products_selected[i].warehouse.warehouse_id,
            initial_stock : 0,
            final_stock : 0,
            inv_lot_id: this.products_selected[i].warehouse.productlot.code,
            quantity: this.products_selected[i].warehouse.quantity_selected,
            received_quantity: this.products_selected[i].warehouse.quantity_selected,
            rejected_quantity: this.products_selected[i].warehouse.quantity_selected,
            // se utilizara rejecte quantity para hacer la base  de catidad
            reason: '',
            value_sell: 0,
            tax_base: 0,
            rate_pct: 0,
            vat: 0,
            total: 0,
            bar_code: this.products_selected[i].product.bar_code,
            name_product: this.products_selected[i].product.name,
            delete: false,
            selected: false,
            inv_block_stock_id: 0,
            ticket: true,
            logic: 'OUT',
            inv_piece: this.products_selected[i].warehouse.inv_piece,
            inv_transfer_03item_detail: {
              product_name: this.data.data.inv_transfer_03item_detail.product_name,
              production_order_number: this.data.data.inv_transfer_03item_detail.production_order_number,
              inv_transfer_02item_id: 0,
              production_order_01main_id: this.data.data.inv_transfer_03item_detail.production_order_01main_id,
              production_order_02item_id: this.data.data.inv_transfer_03item_detail.production_order_02item_id,
              inv_product_id: this.data.data.inv_transfer_03item_detail.inv_product_id,
              bar_code: this.data.data.inv_transfer_03item_detail.bar_code,
              aux_code: this.data.data.inv_transfer_03item_detail.aux_code,
              inv_lot_code: this.data.data.inv_transfer_03item_detail.inv_lot_code,
              initial_quantity: this.products_selected[i].warehouse.quantity_selected,
              delivered_quantity: this.products_selected[i].warehouse.quantity_selected,
              difference_delivered: 0,
              residue: false,
              additional_quantity: true,
            }
          })
          warehouse_details.push(this.products_selected[i].warehouse)
        }
      }
      let object = {
        block_stocks: block_stocks,
        warehouse_details: warehouse_details
      }
      this.dialogRef.close(object);

    }

    addSupplies(supplie = false) {
      if (supplie) {
        let warehouse_details = []
        for (let i = 0; i < this.warehouse_details.length; i++) {
          debugger
          if (this.warehouse_details[i].quantity_selected > 0) {
            warehouse_details.push(this.warehouse_details[i])
          }
        }
        this.dialogRef.close(warehouse_details);

      } else {
        let product = []
        for (let i = 0; i < this.products_selected.length; i++) {
          if (this.products_selected[i].warehouse.quantity_selected > 0) {
            product.push(this.products_selected[i])
          }
        }
        this.dialogRef.close(product);

      }

    }


    deleteProduct(index_product) {
      this.warehouse_details.splice(index_product, 1);
    }


  

    getPaginatorData(event){
      console.log(event);
      if(event.pageIndex === this.pageIndex + 1){
         this.lowValue = this.lowValue + this.pageSize;
         this.highValue =  this.highValue + this.pageSize;
      }
      else if(event.pageIndex === this.pageIndex - 1){
        this.lowValue = this.lowValue - this.pageSize;
        this.highValue =  this.highValue - this.pageSize;
      }
      this.pageIndex = event.pageIndex;
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }


}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-purchase-modal',
  templateUrl: './filter-purchase.modal.html',
  styleUrls: ['./filter-purchase.modal.scss']
})

export class FilterPurchaseModal implements OnInit {
  public newForm: FormGroup;


  value: any[] = [];
  public uploadUrl: string = '';
  public update_previewUrlImage: string = null;
  public image_name: string = '';
  public doneCheckbox : boolean=true;
  public runUrl = true;
  public type_filter = {
    inventory: false,
    accounting_record : false,
    invoice_refund : false
  };
  constructor(
    public dialogRef: MatDialogRef<FilterPurchaseModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    //this.newForm = this.createFormControl();
  }

  ngOnInit() {
    debugger;
    console.log (this.data);
  }

  executeFilter(){
    debugger;
    let inventory: any = document.getElementById('inventory');
    let invoice_refund: any = document.getElementById('invoice_refund');
    let accounting_record: any = document.getElementById('accounting_record');
    debugger;
    //checkboxes = document.getElementById('inventory')
    this.type_filter = {
      inventory: inventory.checked,
      invoice_refund: invoice_refund.checked,
      accounting_record: accounting_record.checked,
    }
    this.data.inventory = this.type_filter.inventory;
    this.data.invoice_refund = this.type_filter.invoice_refund;
    this.data.accounting_record = this.type_filter.accounting_record;
    console.log (this.data);
    this.dialogRef.close();

  }
}

import { EnterpriseUserCreateActionTypes, EnterpriseUserCreateActionsUnion } from '../actions/enterprise_user_create.action';
import { EnterpriseUserCreate } from '../states/EnterpriseUserCreate';


import * as CryptoJS from 'crypto-js';

const initialState: EnterpriseUserCreate =
{
  taxpayer_type_id: null,
  retention_agent: null,
  retention_agent_resolution: null,
  tax_regime_id: null,
  tax_regime_resolution: null,
};

export function enterpriseCreateReducer(state: EnterpriseUserCreate = initialState, action: EnterpriseUserCreateActionsUnion): EnterpriseUserCreate {
  let key, enterprise;
  switch (action.type) {
    case EnterpriseUserCreateActionTypes.LOAD:
      key = window.btoa('gfsys_enterprise_create');
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return initialState;
    case EnterpriseUserCreateActionTypes.UPDATE:
      key = window.btoa('gfsys_enterprise_create');
      console.log('EnterpriseUserCreateActionTypes.UPDATE', action.payload);
      debugger;
      enterprise = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, enterprise);
      return action.payload;
    case EnterpriseUserCreateActionTypes.DELETE:
        key = window.btoa('gfsys_enterprise_create');
        localStorage.removeItem(key);
        return initialState;
    default:
      key = window.btoa('gfsys_enterprise_create');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualEnterprise: EnterpriseUserCreate = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualEnterprise
        }
      } else {
        key = window.btoa('gfsys_enterprise_create');
        enterprise = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, enterprise);
        return state;
      }
  }
}

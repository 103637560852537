import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-enterprises-modal',
  templateUrl: './enterprises.modal.html',
  styleUrls: ['./enterprises.modal.scss'],
})

export class EnterprisesModal {
    public search_filter = ''
    constructor(
      public dialogRef: MatDialogRef<EnterprisesModal>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}



}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // wsUrl: 'https://globalfinances.cloud',
  wsUrl: 'http://localhost:5000',
  firebase: {
      apiKey: 'AIzaSyCdSy-jy33bUL-ttgb8fTSFZsyAniO1BWA',
      authDomain: 'gfsys-a8108.firebaseapp.com',
      databaseURL: 'https://gfsys-a8108.firebaseio.com/',
      projectId: 'gfsys-a8108',
      storageBucket: 'gfsys-a8108.appspot.com',
      messagingSenderId: '868060471540',
      appId: "1:868060471540:web:e99eea58dfb5287b187ea1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

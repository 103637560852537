import { MenuActionTypes, MenuActionsUnion } from '../actions/menu.action';
import { Menu } from '../states/menu';

import * as CryptoJS from 'crypto-js';

const initialState: Menu[] = [];

export function menuReducer(state: Menu[] = initialState, action: MenuActionsUnion): Menu[] {
  let key, menu;
  switch (action.type) {
    case MenuActionTypes.LOAD:
      key = window.btoa('gfsys_menu');
      menu = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, menu);
      return initialState;
    case MenuActionTypes.UPDATE:
      key = window.btoa('gfsys_menu');
      menu = CryptoJS.AES.encrypt(JSON.stringify(action.payload), '*!$GfSys@ByPeñaCampos.2019!*').toString();
      localStorage.setItem(key, menu);
      return action.payload;
    case MenuActionTypes.DELETE:
      key = window.btoa('gfsys_menu');
      localStorage.removeItem(key);
      return initialState;
    default:
      key = window.btoa('gfsys_menu');
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== '') {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), '*!$GfSys@ByPeñaCampos.2019!*');
        if (bytes.toString()) {
          const actualMenu: Menu[] = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return actualMenu
        }
      } else {
        key = window.btoa('gfsys_menu');
        menu = CryptoJS.AES.encrypt(JSON.stringify(state), '*!$GfSys@ByPeñaCampos.2019!*').toString();
        localStorage.setItem(key, menu);
        return state;
      }
  }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { URLProvider } from '../url.providers';
import { Observable } from 'rxjs';
import { IServerResponseModel } from '../../models/http';
import { ICreatedResponseModel } from '../../parameters/stores/models/http';

@Injectable()
export class AjeGroupProvider {

    constructor(
        private readonly http: HttpClient,
        private readonly urlProvider: URLProvider
    ) {
    }

    public insertStore(enterprise: string, body) {
        return this.http.post<IServerResponseModel<ICreatedResponseModel>>(
            this.urlProvider.integrationAjeGroup() + 'insertstore/' + enterprise,
            body,
            this.urlProvider.httpOptions
        );
    }

    public updateStore(enterprise: string, idBranchOffice: number, body):Observable<any> {
        return this.http.put<IServerResponseModel<ICreatedResponseModel>>(
            this.urlProvider.integrationAjeGroup() + `updatestore/${enterprise}/${idBranchOffice}`,
            body,
            this.urlProvider.httpOptions
        );
    }

    public createMovementsToMDW(enterprise: string, body) {
        return this.http.post<number>(
            this.urlProvider.integrationAjeGroup() + 'dwrmovements/' + enterprise, body,
            this.urlProvider.httpOptions
        );
    }

    public sendMovementsToMDW(enterprise: string, params): Observable<any> {
        return this.http.post<number>(
            this.urlProvider.integrationAjeGroup() + 'sendmovement/' + enterprise + '?' + params,
            null, this.urlProvider.httpOptions
        );
    }
}

import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ElectronicalVoucherInvoiceProvider } from "../../../providers/utils/electronical-voucher-invoice.provider";
import { ElectronicalVoucherProvider } from "../../../providers/electronical-voucher/electronical-voucher.provider";
import { ElectronicalVoucherInvoice } from '../../../models/electronical-voucher/Invoice';
import { BuyRetentionElectronicalProvider } from '../../../providers/client/buy_retention_electronical.provider';


/* Redux */
import { EnterpriseState } from '../../../states/enterprise';
import { Store, select } from '@ngrx/store';
/* END Redux */

@Component({
  selector: 'app-retention-mail-send-modal',
  templateUrl: './retention-mail-send.modal.html',
  styleUrls: ['./retention-mail-send.modal.scss'],
})

export class RetentionMailSendModal {
  public emails: string;
  public doneCheckbox = false;
  public retentions= [];
  public enterprise = '$2y$10$RxD8EGdBitaDuBOE16UbO.MsfLSlIqW.hKVec2dwfPj10tpgdFiTu';
  public xml_not_sign = []
  public enterprise$: Observable<EnterpriseState>;
  public showSpinner: boolean = false;

    constructor(
      private _snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<RetentionMailSendModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private buyRetentionElectronicalService: BuyRetentionElectronicalProvider,
      private store: Store<{enterprise: EnterpriseState}>
    ) {
      this.enterprise$ = this.store.pipe(select('enterprise'));
      this.enterprise$.subscribe(
        (data: EnterpriseState) => {
          this.enterprise = data.entity.db_name;
        }, error => {
          this.notification(error);
          console.log('Error enviando Mail',error)
        }
      );
    }

    ngOnInit(): void {
      this.checkInput('value')
    }


    checkInput(value) {
      let inputemail: any = document.getElementsByClassName('mat-input')
      let sendButton: any = document.getElementsByClassName('send')
      if (`${inputemail[0].value}` == "") {
        for (let x = 0; x < sendButton.length; x++) {
          sendButton[x].disabled = true
        }
      } else {
        for (let x = 0; x < sendButton.length; x++) {
          sendButton[x].disabled = false
        }
      }
    }

    sendMail(retention: any){
      this.showSpinner = true;
      this.retentions = [];
      let checkboxes: any = document.getElementById('mat-input-email')
      console.log(checkboxes);
      this.retentions.push(
        {
          document_number : retention.document_number,
          access_key    : retention.access_key,
          email    : retention.email_client
        }
      );
      this.buyRetentionElectronicalService.mailRetentions(this.enterprise, this.retentions).subscribe(
        (data: any) => {
          if (data) {
            this.notification('Se ha Reenviado Correctamente la Retención');
            this.onNoClick();
            //console.log('Resultado enviando Mail',data.response)
          }
          this.showSpinner = false;
        },
        error => this.showSpinner = false
      );
    }

    notification(masage: string) {
      this._snackBar.open(masage, null, {
        duration: 6000
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
}

export interface RetentionEmail
{
  document_number : string;
  access_key    : string;
  email    : string;
}

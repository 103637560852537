import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptProvider {

  public set(value: any) {
    return CryptoJS.AES.encrypt(value, '*!$GfSys@ByPeñaCampos.2019!*').toString();
  }

  public get(value: any) {
    const bytes = CryptoJS.AES.decrypt(value, '*!$GfSys@ByPeñaCampos.2019!*');
    if (bytes.toString()) {
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return value;
  }

}

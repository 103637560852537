import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { GridPermission, GridPermissions } from '../../models/client/GridPermissions';

@Injectable()
export class GridPermissionsUtilProvider {
  grid_permissions: GridPermissions = null;

  verifyGard(type: string, grid_permissions: GridPermissions, not_view) {
    if (!grid_permissions) {
      return
    }
    let grid_permission = grid_permissions.grid_permissions.find(v=> v.not_view == not_view)

    switch (type) {
      case 'create':
        return this.hasCreate(grid_permission);
      break;
      case 'edit':
        return this.hasEdit(grid_permission);
      break;

      case 'list':
        return this.hasList(grid_permission);
      break;

      case 'cancel':
        return this.hasCancel(grid_permission);
      break;

    }
  }

  private hasCreate(grid_permissions: GridPermission) {
    if (grid_permissions) {
      let index = grid_permissions.gards.findIndex(v=> v == 1)
      return index >= 0? true: false;
    } else {
      return  false;
    }

  }

  private hasEdit(grid_permissions: GridPermission) {
    if (grid_permissions) {
      let index = grid_permissions.gards.findIndex(v=> v == 2)
      return index >= 0? true: false;
    } else {
      return false;
    }

  }

  private hasList(grid_permissions: GridPermission) {
    if (grid_permissions) {
      let index = grid_permissions.gards.findIndex(v=> v == 3)
      return index >= 0? true: false;
    } else {
      return false;
    }


  }

  private hasCancel(grid_permissions: GridPermission) {
    if (grid_permissions) {
      let index = grid_permissions.gards.findIndex(v=> v == 4)
      return index >= 0? true: false;
    } else {
      return false;
    }

  }

}

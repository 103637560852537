import { Component, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { EnterpriseState } from '../../states/enterprise';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WebsocketProvider } from "../../providers/utils/websocket.provider";
import { LoginRefreshProvider } from "../../providers/utils/login-refresh.provider";
import { Update as UpdateUser } from '../../actions/user.action';
import { LoginRefresh } from '../../components/modals/user-refresh-login/user-refresh-login.modal';
import { UserState } from '../../states/user';
import { ChangeAppProvider, ChangeApp } from "../../providers/client/change_app.provider";
import { DomainsProvider } from '../../providers/domains.provider';
import { take } from 'rxjs/operators';
@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
  public enterprise$: Observable<EnterpriseState>;
  public user$: Observable<UserState>;
  public enterprise_state: EnterpriseState;
  public user_state: UserState;
  private payload:LoginRefresh = null;
  mode: FormControl;

  constructor(
    // // private socket: Socket,
    private renderer: Renderer2,
    private storeEnterprise: Store<{enterprise: EnterpriseState}>,
    private storeUser: Store<{user: UserState}>,
    private websocketService: WebsocketProvider,
    private loginRefreshService: LoginRefreshProvider,
    private changeAppService: ChangeAppProvider,
    private domainService: DomainsProvider,
  ) {
    this.enterprise$ = this.storeEnterprise.pipe(select('enterprise'));
    this.user$ = this.storeUser.pipe(select('user'));
    this.getEnterpriseRedux();
    this.getUserRedux();
  }

  getEnterpriseRedux(){
    // this.enterprise$.pipe(take(1)).subscribe(
    //   (data: EnterpriseState) => {
    //     this.enterprise_state = data;
    //   },
    //   error => {
    //     console.log('error session_state', error);
    //   }
    // )
  }

  

  getUserRedux(){
    this.user$.pipe(take(1)).subscribe(
      (data: UserState) => {
        this.user_state = data;
      },
      error => {
        console.log('error session_state', error);
      }
    )
  }


  ngOnInit() {
    this.renderer.removeClass(document.body, 'auth');
    this.renderer.addClass(document.body, 'nav-md');

    
    this.refreshLogin()
    this.mode = new FormControl('over');
    setTimeout(() => {
      this.getEnterpriseRedux();
    }, 3000);
  }
  public data = null;
  changeApp(url){
    let data = {
        enterprise : this.enterprise_state,
        user: this.user_state
    }
    this.data= {
        app_form: 'GFSYS',
        redux_data: data,
        datetime: new Date,
        status: true,
        db_name: this.enterprise_state.entity.db_name,
        redirect_to: null
    }
    this.changeAppService.insertChangeApp(this.enterprise_state.entity.db_name, this.data).subscribe(
        (data: any) => { 
            window.location.href = url+ '/auth/login/'+data;
        }
    )
}
  refreshLogin() {
    // this.socket.on('refresh-login', (payload: LoginRefresh) => {

    //   if (this.user_state.user_account[0].email == payload.user_email && payload.db_name == this.enterprise_state.entity.db_name) {
    //     if (payload.automatic) {
    //       this.loginRefreshService.login(payload.user_email,this.enterprise_state, payload.db_name);
    //     } else {
    //       this.payload = payload;
    //       let user_state: UserState = {
    //         user_account: this.user_state.user_account,
    //         user_account_roles: this.user_state.user_account_roles,
    //         user_role_permissions: this.user_state.user_role_permissions,
    //         user_role_selected: this.user_state.user_role_selected,
    //         user_role_name_selected: this.user_state.user_role_name_selected,
    //         refresh_redux: payload,
    //         is_superadmin: this.user_state.is_superadmin,
    //       }
    //       this.storeUser.dispatch(new UpdateUser(user_state));
    //     }

    //   }
    //   console.log('Conectado al servidor');
    // });
  }

  refreshLoginNoAutomatic() {
    this.loginRefreshService.login(this.payload.user_email,this.enterprise_state, this.payload.db_name, false);
    this.payload = null;
  }
}

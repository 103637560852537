import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { PurchaseProvider } from '../../../providers/client/purchase.provider';
import { RetentionRecivedProvider, RetentionRecived } from '../../../providers/client/retention_recived.provider';
import { InvoicePaymentMethodProvider } from '../../../providers/client/invoice_payment_method.provider';
import { ViewPaginator } from '../../../models/client/ViewPaginator';
import { DxDataGridComponent } from "devextreme-angular";
import {data} from 'jquery';

@Component({
  selector: 'app-vouchers-retentions-comissions-modal',
  templateUrl: './vouchers-retentions-comissions.modal.html',
  styleUrls: ['./vouchers-retentions-comissions.modal.scss'],
})

export class VouchersRetentionsComissionsModal implements AfterViewInit {

  @ViewChild('gridboucher2', { static: false }) gridboucher2: DxDataGridComponent;
  @ViewChild('gridretencion', { static: false }) gridretencion: DxDataGridComponent;
  @ViewChild('gridcomission', { static: false }) gridcomission: DxDataGridComponent;



  public searchFilterText: string = '';
  public searchText: string = '';
  public advance = {
    value: null,
    motive: null,
  }
  public name_table:any="";

  // VARIABLE QUE SE DEBE BORRAR PORQUE YA NO SE USA PARA CAMBIAR FILTROS
  public vouchers_report=1;
  public vouchers: any;
  public vouchers2: any;
  public showSpinner:boolean= false;
  public vouchers_selected: any = [];
  public third_selected: any = [];
  public vouchers_view_paginator: ViewPaginator;
  public retentions: any;
  public retentions2: any;
  public retentions_selected: any = [];
  public retentions_view_paginator: ViewPaginator;
  public comissions: any;
  public comissions2: any;

  public comissions_selected: any = [];
  public comissions_view_paginator: ViewPaginator;
  public header_columns: string[] = ['bank_client_provider','reference', 'date','document_number', 'transaction', 'lot', 'pay', 'total', 'balance', 'check'];
  public header_columns_comissions: string[] = ['bank_client_provider','date','document_number', 'pay', 'total', 'balance', 'check'];

  public filter = {
    from: new Date,
    until: new Date,
    bank_client_provider: '',
    date: '', 
    document_number: '',
    total: '',
    transaction: '',
    lot: '',
    reference: ''

  }

  public filters = ''

  constructor(
    public dialogRef: MatDialogRef<VouchersRetentionsComissionsModal>,
    private purchaseService: PurchaseProvider,
    private invoicePaymentMethodsService: InvoicePaymentMethodProvider,
    private retentionService: RetentionRecivedProvider,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    debugger;
    console.log('data', JSON.stringify(data));
    console.log(this.filter,data.fecha);
    this.filter.from=data.fecha
    this.filter.until=data.fecha
    if (this.data.type == 'comissions') {
      this.comissions_selected = this.data.elements
    }else if(this.data.type == 'thrid') {
      this.third_selected = this.data.elements
      this.name_table="Registros";
    }
    else if (this.data.type == 'vouchers') {
      this.vouchers_selected = this.data.elements
      this.name_table="Vouchers";

    }
    else if (this.data.type == 'retentions') {
      this.retentions_selected = this.data.elements
    }

    // se requiere mostrar la tabla dx-data-grid para éstas opciones
    if (
        this.data.type == 'comissions' ||
        this.data.type == 'retentions'
    ) {
        this.vouchers_report=0;
    }
  }

  public ngAfterViewInit(): void {
    this.markSelected();
  }

  markSelected() {
    debugger;
    if(this.data.type == 'thrid' && this.gridboucher2) {
      this.gridboucher2.selectedRowKeys = this.data.elements
    } else if (this.data.type == 'vouchers' && this.gridboucher2) {
      this.gridboucher2.selectedRowKeys = this.data.elements
    }
  }

  search(){
    debugger;
    let from = `${this.filter.from.getDate()}-${this.filter.from.getMonth() +1}-${this.filter.from.getFullYear()}`;
    let until = `${this.filter.until.getDate()}-${this.filter.until.getMonth() +1}-${this.filter.until.getFullYear()}`;
    
    let filter = 'range_date=true&' + 'start_date='+ from + '&end_date=' + until + 
    '&registration_date='+ this.filter.date +'&document_number='+ this.filter.document_number + 
    '&total=' +  this.filter.total+'&new_report='+this.vouchers_report;

    if (this.data.type == 'vouchers') {
    this.showSpinner=true
      filter +=  '&financial_institution_name='+ this.filter.bank_client_provider +
      '&date_invoice='+ this.filter.date + '&lot='+ this.filter.lot + '&transaction=' +this.filter.transaction

      this.allPaymentMethods(filter);
    } else if(this.data.type == 'thrid') {
    this.showSpinner=true

      filter +=  '&financial_institution_name='+ this.filter.bank_client_provider +
      '&date_invoice='+ this.filter.date + '&lot='+ this.filter.lot + '&transaction=' +this.filter.transaction 
      + '&thrid=true&reference='+this.filter.reference

      this.allPaymentMethods(filter);
    }
    else if (this.data.type == 'comissions') {
      filter +=  '&name_provider='+ this.filter.bank_client_provider + '&commission=true'
      this.allPurchase(filter);
    }
    else if (this.data.type == 'retentions') {
      filter +=  '&filter=true';
      this.allRetentions(filter);
      this.showSpinner=true;
    }

    this.filters = filter;
  }

  changeReport(){
    if (this.vouchers_report==1) {
      this.vouchers_report=0
    }else{
      this.vouchers_report=1
    }
  }

  onSelected2(e:any){
    debugger
   
    console.log(e.selectedRowKeys);
    
  }

  allPurchase(filters = '') {
    debugger;

    this.purchaseService.allViewPurchase(this.data.enterprise, filters).subscribe(
      (data: ViewPaginator) => {
        debugger;
        this.comissions_view_paginator = data;
        let comissions = []
        this.comissions2=[];
        if (this.vouchers_report!=0) {
          for (let i = 0; i < data.data.length; i++) {
            comissions.push({
              item_id:0,
              id: data.data[i].id,
              bank_client_provider: data.data[i].identification +'-'+ data.data[i].name_provider,
              identification: data.data[i].identification,
              date: data.data[i].document_date ,
              document_number: data.data[i].document_number , 
              pay: data.data[i].payments ,
              total: data.data[i].total ,
              balance: Number(data.data[i].balance),
              selected: false
            });
          }
          this.comissions = new MatTableDataSource(comissions);
        }else{
          if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
              this.comissions2.push({
                item_id:0,
                id: data[i].id,
                bank_client_provider: data[i].identification +'-'+ data[i].name_provider,
                identification: data[i].identification,
                date: data[i].document_date ,
                document_number: data[i].document_number , 
                pay: data[i].payments ,
                total: data[i].total ,
                balance: Number(data[i].balance),
                selected: false
              });
            }
          }
          
        }

        

      }
    )
  }
  nextPagePurchase(url){
    this.purchaseService.getPurchaseNextPreviousPage(url, this.filters).subscribe(
      (data: ViewPaginator) => {
        this.comissions_view_paginator = data;
        let comissions = []
        for (let i = 0; i < data.data.length; i++) {
          comissions.push({
            item_id:0,
            id: data.data[i].id,
            identification: data.data[i].identification,
            bank_client_provider: data.data[i].identification +'-'+ data.data[i].name_provider,
            date: data.data[i].document_date ,
            document_number: data.data[i].document_number , 
            pay: data.data[i].payments ,
            total: data.data[i].total ,
            balance: Number(data.data[i].balance),
            selected: false
          })
        }
        this.comissions = new MatTableDataSource(comissions);

      }
    )
  }

  allPaymentMethods(filters = '') {
    this.invoicePaymentMethodsService.allViewInvoicePaymentMethod(this.data.enterprise, filters).subscribe(
      (data: ViewPaginator) => {
        debugger;        
        let vouchers = []
        this.vouchers2=[];
        if (this.vouchers_report!=0) {
          this.vouchers_view_paginator = data;
          for (let i = 0; i < data.data.length; i++) {
            vouchers.push({
              item_id:0,
              id: data.data[i].id,
              invoice_id: data.data[i].invoice_01main_id,
              bank_client_provider: data.data[i].financial_institution_name,
              reference: data.data[i].reference,
              date: data.data[i].date_invoice ,
              document_number: data.data[i].document_number , 
              type: data.data[i].name_payment_method,
              pay: data.data[i].payments ,
              total: data.data[i].total?data.data[i].total: data.data[i].amount ,
              amount: data.data[i].total?data.data[i].total: data.data[i].amount ,
              balance: Number(data.data[i].balance),
              lot: data.data[i].lot,
              transaction: data.data[i].transaction,
              type_register: data.data[i].type_register,
            })
          }
          debugger
          this.vouchers = new MatTableDataSource(vouchers);
        }else{
          if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
              this.vouchers2.push({
                item_id:0,
                id: data[i].id,
                invoice_id: data[i].invoice_01main_id,
                bank_client_provider: data[i].financial_institution_name,
                reference: data[i].reference,
                date: data[i].date_invoice ,
                document_number: data[i].document_number , 
                type: data[i].name_payment_method,
                pay: data[i].payments ,
                total: data[i].total?data[i].total: data[i].amount ,
                amount: data[i].total?data[i].total: data[i].amount ,
                balance: Number(data[i].balance),
                lot: data[i].lot,
                transaction: data[i].transaction,
                type_register: data[i].type_register,
              })
            }
          }
          
        }
        console.log(this.vouchers,this.vouchers2,vouchers);
        this.showSpinner=false;
        this.markSelected();

      }
    )
  }
  nextPagePaymentMethod(url){
    this.purchaseService.getPurchaseNextPreviousPage(url, this.filters).subscribe(
      (data: ViewPaginator) => {
        this.vouchers_view_paginator = data;
        let vouchers = []
        for (let i = 0; i < data.data.length; i++) {
          vouchers.push({
            item_id:0,
            id: data.data[i].id,
            invoice_id: data.data[i].invoice_01main_id,
            bank_client_provider: data.data[i].financial_institution_name,
            reference: data.data[i].reference,
            date: data.data[i].date_invoice ,
            document_number: data.data[i].document_number , 
            type: data.data[i].name_payment_method,
            pay: data.data[i].payments ,
            total: data.data[i].total ,
            balance: Number(data.data[i].balance),
            lot: data.data[i].lot,
            transaction: data.data[i].transaction,
          })
        }
        this.vouchers = new MatTableDataSource(vouchers);

      }
    )
  }

  allRetentions(filters = '') {
    debugger
    this.retentionService.allViewRetentionRecived(this.data.enterprise,filters,'').subscribe(
      (data: ViewPaginator) => {
        debugger
        this.showSpinner=false;

        if (this.vouchers_report!=0) {
          this.retentions_view_paginator = data;
          let retentions = []
          for (let i = 0; i < data.data.length; i++) {
            retentions.push({
              item_id:0,
              id: data.data[i].id,
              bank_client_provider: data.data[i].identification_client +'-'+ data.data[i].name_client,
              date: data.data[i].date_retention ,
              document_number: data.data[i].document_number , 
              pay: data.data[i].payments ,
              total: data.data[i].total ,
              balance: Number(data.data[i].balance),
              apply: Number(data.data[i].balance),
              selected: false
            })
          }
          this.retentions = new MatTableDataSource(retentions);
        }else{
          if (Array.isArray(data)) {
          this.retentions2=[];
            for (let i = 0; i < data.length; i++) {
              this.retentions2.push({
                item_id:0,
                id: data[i].id,
                bank_client_provider: data[i].identification_client +'-'+ data[i].name_client,
                date: data[i].date_retention ,
                document_number: data[i].document_number , 
                pay: data[i].payments ,
                total: data[i].total ,
                balance: Number(data[i].balance),
                apply: Number(data[i].balance),
                selected: false
              })
            }
          }
        }


      }
    )
  }
  
  nextPageRetentions(url){
    this.retentionService.nextPreviousPageRetentionRecived(url,10, this.filters).subscribe(
      (data: ViewPaginator) => {
        this.retentions_view_paginator = data;
        let retentions = []
        for (let i = 0; i < data.data.length; i++) {
          retentions.push({
            item_id:0,
            id: data.data[i].id,
            bank_client_provider: data.data[i].identification_client +'-'+ data.data[i].name_client,
            date: data.data[i].date_retention ,
            document_number: data.data[i].document_number , 
            pay: data.data[i].payments ,
            total: data.data[i].total ,
            balance: Number(data.data[i].balance),
            selected: false
          })
        }
        this.retentions = new MatTableDataSource(retentions);

      }
    )
  }

  changeCheckbox(element: any){
    debugger
    let checkbox: any = document.getElementById(element.id);

    if (checkbox.checked) {
      

      if (this.data.type == 'vouchers') {
        var index = this.vouchers_selected.findIndex(v=> v.id == element.id);
        if (index < 0) {
          this.vouchers_selected.push(element)
        }
      }
      else if (this.data.type == 'thrid') {
        var index = this.third_selected.findIndex(v=> v.id == element.id);
        if (index < 0) {
          this.third_selected.push(element)
        }
      }
      else if (this.data.type == 'comissions') {
        var index = this.comissions_selected.findIndex(v=> v.id == element.id);
        if (index < 0) {
          this.comissions_selected.push(element)
        }
      }
      else if (this.data.type == 'retentions') {
        var index = this.retentions_selected.findIndex(v=> v.id == element.id);
        if (index < 0) {
          this.retentions_selected.push(element)
        }
      }
      
    }else {
      if (this.data.type == 'vouchers') {
        let index_of_selected_voucher = this.vouchers_selected.findIndex(v=> v.id == element.id);
        if (index_of_selected_voucher >= 0) {
          this.vouchers_selected.splice(index_of_selected_voucher, 1)
        }
      }
      else if (this.data.type == 'thrid') {
        let index_of_selected_voucher = this.third_selected.findIndex(v=> v.id == element.id);
        if (index_of_selected_voucher >= 0) {
          this.third_selected.splice(index_of_selected_voucher, 1)
        }
      }
      else if (this.data.type == 'comissions') {
        let index_of_selected_comission = this.comissions_selected.findIndex(v=> v.id == element.id);
        if (index_of_selected_comission >= 0) {
          this.comissions_selected.splice(index_of_selected_comission, 1)
        }
      }
      else if (this.data.type == 'retentions') {
        let index_of_selected_retention = this.retentions_selected.findIndex(v=> v.id == element.id);
        if (index_of_selected_retention >= 0) {
          this.retentions_selected.splice(index_of_selected_retention, 1)
        }
      }
      
    }
  }

  checkedElement(element : any){
    if (this.data.type == 'comissions') {
      var index = this.comissions_selected.findIndex( v=> v.id == element.id);
    } else if (this.data.type == 'thrid') {
      var index = this.third_selected.findIndex( v=> v.id == element.id);
    }  else if (this.data.type == 'vouchers') {
      var index = this.vouchers_selected.findIndex( v=> v.id == element.id);
    }
    else if (this.data.type == 'retentions') {
      var index = this.retentions_selected.findIndex( v=> v.id == element.id);
    }
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  public selectAll(): void {
    debugger;
    if (this.data.type == 'vouchers') {
      if (this.vouchers_selected.length < this.vouchers.data.length) {
        this.vouchers_selected = [];
        this.vouchers_selected = JSON.parse(JSON.stringify(this.vouchers.data));
      } else {
        this.vouchers_selected = [];
      }
    }
    if (this.data.type == 'thrid') {
      if (this.third_selected.length < this.vouchers.data.length) {
        this.third_selected = [];
        this.third_selected = JSON.parse(JSON.stringify(this.vouchers.data));
      } else {
        this.third_selected = [];
      }
    }
  }


 
  close(): void {
    debugger;
    let result = {
      type: this.data.type,
      elements: null
    }
    if (this.data.type == 'comissions') {
      if (this.vouchers_report!=0) {
        result.elements = this.comissions_selected;
      }else{
        result.elements=this.gridcomission.selectedRowKeys;
      }
    }
    else if (this.data.type == 'thrid') {
      if (this.vouchers_report!=0) {
        result.elements = this.third_selected;        
      }else{
        result.elements=this.gridboucher2.selectedRowKeys;
      }

    }
    else if (this.data.type == 'vouchers') {
      if (this.vouchers_report!=0) {
        result.elements = this.vouchers_selected;        
      }else{
        result.elements=this.gridboucher2.selectedRowKeys;
        this.vouchers_selected.forEach(element => {
          let filter=result.elements.filter(filt=>filt.id==element.id);
          if (filter.length==0) {
            result.elements.push(element);
          }
        });
      }
      
    }
    else if (this.data.type == 'retentions') {
      if (this.vouchers_report!=0) {
        result.elements = this.retentions_selected;        
      }else{
        result.elements=this.gridretencion.selectedRowKeys;
      }
    }
     this.dialogRef.close(result);
  }
}

export interface Vouchers {
  no: number,
  banco: string,
  fecha: string,
  transacción: number,
  lote: number,
  valor : number,
  pagos_efectuados: number,
  saldo: number,
  select: boolean,
}

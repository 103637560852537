import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserMenu } from '../../models/client/UserMenu';
import { UserRolePermision } from '../../models/client/UserRolePermision';
import { Menu } from '../../states/menu';
import { UserMenuGards } from '../../states/UserMenuGards';
import { Store, select } from '@ngrx/store';
import { Update  } from '../../actions/user_menu_gards.action';

@Injectable()
export class CreateMenuProvider {
  private menu: Menu[] = [];
  private user_menu_gard: UserMenuGards[] = [];
  constructor(
    private storeUserMenuGards: Store<{ user_menu_gards: UserMenuGards[] }>,
  ) {}

  public create(user_menu: UserMenu[], user_rol_permission: UserRolePermision[]): Menu [] {
    this.menu = [];
    for (let i = 0; i < user_rol_permission.length; i++) {
      this.howIs(user_menu, user_rol_permission[i]);
    }

    for (let j = 0; j < this.menu.length; j++) {
      this.menu[j].children.sort((a, b)=>{return a.id - b.id});
    }
    this.storeUserMenuGards.dispatch(new Update(this.user_menu_gard));

    return this.menu;
  }

  protected howIs(user_menu: UserMenu[], user_rol_permission: UserRolePermision) {
    for (let i = 0; i < user_menu.length; i++) {
      if (user_menu[i].id === user_rol_permission.users_menu_id) {
        let index_href = this.user_menu_gard.findIndex(v=> v.href == user_menu[i].href);
        if (index_href < 0) {
          this.user_menu_gard.push({
            id: user_menu[i].id,
            href: user_menu[i].href,
            gards: [user_rol_permission.users_guard_id],
            gards_object: [user_rol_permission]
          })
        } else {
          let index_gard = this.user_menu_gard[index_href].gards.findIndex(v=> v == user_rol_permission.users_guard_id)
          if (index_gard < 0) {
            this.user_menu_gard[index_href].gards.push(user_rol_permission.users_guard_id)
            this.user_menu_gard[index_href].gards_object.push(user_rol_permission)
          }
        }
        switch (user_menu[i].level) {
          case '1':
            this.getLevelOne(user_menu[i]);
            break;
          case '2':
            this.getLevelTwo(user_menu, user_menu[i]);
            break;
          case '3':
            this.getLevelThree(user_menu, user_menu[i]);
            break;
        }
      }
    }
  }

  protected getLevelOne(menu_asigned: UserMenu): number {
    let index = 0;
    let exists: Boolean = false;
    if (this.menu.length > 0) {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].label === menu_asigned.label) {
          index = i;
          exists = true;
        }
      }
      if (!exists) {
        index = this.menu.length;
        this.menu.push(
          {
            id: menu_asigned.id,
            label: menu_asigned.label,
            icon: menu_asigned.icon,
            span: menu_asigned.span,
            href: menu_asigned.href,
            active: false,
            children: []
          }
        );
      }
    } else {
      this.menu.push(
        {
          id: menu_asigned.id,
          label: menu_asigned.label,
          icon: menu_asigned.icon,
          span: menu_asigned.span,
          href: menu_asigned.href,
          active: false,
          children: []
        }
      );
    }
    return index;
  }

  protected getLevelTwo(user_menu: UserMenu[], menu_asigned: UserMenu): { level_one: number, level_tow: number } {
    let exists: Boolean = false;
    let position = 0;
    let response: any = {
      level_one: 0,
      level_tow: position
    };
    for (let i = 0; i < this.menu.length; i++) {
      if (this.menu[i].children.length > 0) {
        for (let j = 0; j < this.menu[i].children.length; j++) {

          if (this.menu[i].children[j].label === menu_asigned.label) {
            exists = true;
            response = {
              level_one: i,
              level_tow: j
            }
          }
        }
      }
    }
    if (!exists) {
      for (let i = 0; i < user_menu.length; i++) {
        if (user_menu[i].id === menu_asigned.father) {
          const index = this.getLevelOne(user_menu[i]);
          position = this.menu[index].children.length;
          this.menu[index].children.push(
            {
              id: menu_asigned.id,
              label: menu_asigned.label,
              icon: menu_asigned.icon,
              span: menu_asigned.span,
              href: menu_asigned.href,
              active: false,
              children: []
            }
          );
          response = {
            level_one: index,
            level_tow: position
          }
        }
      }
    }

    return response;
  }

  protected getLevelThree(user_menu: UserMenu[], menu_asigned: UserMenu): void {
    for (let i = 0; i < user_menu.length; i++) {
      if (user_menu[i].id === menu_asigned.father) {
        const positions: any = this.getLevelTwo(user_menu, user_menu[i]);

        let index_href = this.menu[positions.level_one].children[positions.level_tow].children.findIndex(v=> v.href == menu_asigned.href)
        if (index_href < 0) {
          this.menu[positions.level_one].children[positions.level_tow].children.push(
            {
              id: menu_asigned.id,
              label: menu_asigned.label,
              icon: menu_asigned.icon,
              span: menu_asigned.span,
              href: menu_asigned.href,
              active: false,
              children: []
            }
          )
        }
        //this.menu[positions.level_one].children[positions.level_tow].children.sort((a, b)=>{return a.id - b.id})
      }
    }
  }

}

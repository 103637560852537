import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class LoginAuthenticateProvider {
  constructor(
    private db: AngularFirestore
  ) {

  }

  public getLoginSession(email: string) {
    return this.db.collection('sessions', ref => ref.where('email', '==', email)).snapshotChanges()
  }

  public getLoginSessionNoObservable(email: string) {
    return this.db.collection('sessions', ref => ref.where('email', '==', email)).get();
  }

  public insertLoginSession(session:any) {
    return this.db.collection('sessions').add(session)
  }

  public updateLoginSession(id: string, session:any) {
    return this.db.doc('sessions/'+ id).update(session)
  }


}

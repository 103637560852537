import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-yes-no-confirmation-bo-modal',
  templateUrl: './yes-no-confirmation-bo.modal.html',
  styleUrls: ['./yes-no-confirmation-bo.modal.scss'],
})

export class YesNoConfirmationBOModal {
  public doneCheckbox = false
  public motivo: string;

    constructor(
      public dialogRef: MatDialogRef<YesNoConfirmationBOModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,

    ) {}



}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLProvider } from '../url.providers';

@Injectable()
export class PieceReservationRuleProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allPieceReservationRules(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<any[]>(this.urlProvider.piecereservationrule(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<any[]>(this.urlProvider.piecereservationrule(enterprise), this.urlProvider.httpOptions);
    }
  }

  public get(enterprise: string, id: string) {
    return this.http.get<any>(this.urlProvider.piecereservationrule(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }

  public insert(enterprise: string, piecereservationrule: any) {
    return this.http.post<number>(this.urlProvider.piecereservationrule(enterprise), piecereservationrule, this.urlProvider.httpOptions);
  }

  public update(enterprise: string, piecereservationrule: any, id: string) {
    return this.http.put<Boolean>(this.urlProvider.piecereservationrule(enterprise) + '/' + id, piecereservationrule, this.urlProvider.httpOptions);
  }

}
